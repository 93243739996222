import React, { ReactElement, useEffect, createRef } from "react";
import L from "leaflet";
import { ActionIcon, useMantineTheme, Stack } from "@mantine/core";
import useMapControlsStyles from "./MapControls.styles";
import useApplicationStore from "../../stores/application.store";
import useMapStore from "../../stores/map.store";
import useFacilityStore from "../../stores/facility.store";
import useGeolocationStore from "../../stores/geolocation.store";
import {
    IconStopNavigationLine as IconStopNavigation,
    IconTargetLine as IconTarget,
} from "../Icons/Icons";

type ControlButtons = {
    display: boolean | (() => boolean);
    disabled?: boolean | undefined | (() => boolean);
    label: string;
    method: () => void | undefined | null;
    icon: JSX.Element;
};

const ICON_SIZE = 34;
function MapControls(): ReactElement {
    const mapControlsRef = createRef<HTMLDivElement>();
    const theme = useMantineTheme();

    const { classes } = useMapControlsStyles();
    const coords = useGeolocationStore((state) => state.coords);

    const selectedFacility = useFacilityStore(
        (state) => state.selectedFacility
    );

    const hasMoved = useMapStore((state) => state.hasMoved);
    const reCenterMap = useMapStore((state) => state.reCenterMap);

    const isInNavigationMode = useApplicationStore(
        (state) => state.isInNavigationMode
    );
    const toggleNavigationMode = useApplicationStore(
        (state) => state.toggleNavigationMode
    );
    const selectedFacilityCollapsed = useFacilityStore(
        (state) => state.selectedFacilityCollapsed
    );
    const setSelectedFacilityCollapsed = useFacilityStore(
        (state) => state.setSelectedFacilityCollapsed
    );

    const reCenter = () => {
        reCenterMap(selectedFacility, coords);
    };

    const permission = useGeolocationStore((state) => state.permission);
    const openMissingLocationAccessDialog = useGeolocationStore(
        (state) => state.openMissingLocationAccessDialog
    );

    const controlButtons: ControlButtons[] = [
        {
            label: "recenter",
            method: permission ? reCenter : openMissingLocationAccessDialog,
            icon:
                theme.colorScheme === "dark" ? (
                    <IconTarget
                        size={ICON_SIZE}
                        color={
                            hasMoved
                                ? theme.colors.gray[3]
                                : theme.colors.gray[6]
                        }
                    />
                ) : (
                    <IconTarget
                        size={ICON_SIZE}
                        color={hasMoved ? "" : theme.colors.gray[4]}
                    />
                ),
            display: true,
            disabled: !hasMoved,
        },
        {
            label: "stop navigation",
            icon: (
                <IconStopNavigation
                    size={ICON_SIZE}
                    color={theme.colors.red[9]}
                />
            ),
            display: isInNavigationMode && selectedFacilityCollapsed,
            method: permission
                ? () => {
                      toggleNavigationMode(false);
                      setSelectedFacilityCollapsed(true);
                      reCenter();
                  }
                : openMissingLocationAccessDialog,
        },
    ];

    function renderControlButtons() {
        const render = controlButtons.map((controlButton: ControlButtons) => {
            if (
                typeof controlButton.display === "function"
                    ? !controlButton.display()
                    : !controlButton.display
            ) {
                return null;
            }

            const button = (
                <ActionIcon
                    key={controlButton.label}
                    color="dark"
                    size={54}
                    disabled={
                        typeof controlButton.disabled === "function"
                            ? controlButton.disabled()
                            : controlButton.disabled
                    }
                    className={classes.button}
                    onClick={controlButton.method}
                    type="button"
                >
                    <div className={classes.icon}>{controlButton.icon}</div>
                </ActionIcon>
            );

            return button;
        });
        return render;
    }

    useEffect(() => {
        if (mapControlsRef?.current) {
            L.DomEvent.disableClickPropagation(mapControlsRef.current);
        }
    });

    return (
        <div ref={mapControlsRef} className={classes.root}>
            <Stack>{renderControlButtons()}</Stack>
        </div>
    );
}

export default MapControls;
