import { WcAppContext } from "./wcAppContext";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "";

export type ErrorWrapper<TError> =
    | TError
    | { status: "unknown"; payload: string };

export type WcAppFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
    url: string;
    method: string;
    body?: TBody;
    headers?: THeaders;
    queryParams?: TQueryParams;
    pathParams?: TPathParams;
    signal?: AbortSignal;
} & WcAppContext["fetcherOptions"];

export async function wcAppFetch<
    TData,
    TError,
    TBody extends {} | FormData | undefined | null,
    THeaders extends {},
    TQueryParams extends {},
    TPathParams extends {}
>({
    url,
    method,
    body,
    headers,
    pathParams,
    queryParams,
    signal,
}: WcAppFetcherOptions<
    TBody,
    THeaders,
    TQueryParams,
    TPathParams
>): Promise<TData> {
    try {
        const requestHeaders: HeadersInit = {
            "Content-Type": "application/json",
            ...headers,
        };

        /**
         * As the fetch API is being used, when multipart/form-data is specified
         * the Content-Type header must be deleted so that the browser can set
         * the correct boundary.
         * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
         */
        if (
            requestHeaders["Content-Type"]
                .toLowerCase()
                .includes("multipart/form-data")
        ) {
            delete requestHeaders["Content-Type"];
        }

        const response = await window.fetch(
            `${baseUrl}${resolveUrl(url, queryParams, pathParams)}`,
            {
                signal,
                method: method.toUpperCase(),
                body: body
                    ? body instanceof FormData
                        ? body
                        : JSON.stringify(body)
                    : undefined,
                headers: requestHeaders,
            }
        );
        if (!response.ok) {
            let error: ErrorWrapper<TError>;
            try {
                error = await response.json();
            } catch (e) {
                error = {
                    status: "unknown" as const,
                    payload:
                        e instanceof Error
                            ? `Unexpected error (${e.message})`
                            : "Unexpected error",
                };
            }

            throw error;
        }

        if (response.status === 204) {
            // no content, i.e., we cannot parse json
            return undefined as TData;
        }
        return await response.json();
    } catch (e) {
        let errorObject: Error = {
            name: "unknown" as const,
            message:
                e instanceof Error
                    ? `Network error (${e.message})`
                    : "Network error",
            stack: e as string,
        };
        throw errorObject;
    }
}

const resolveUrl = (
    url: string,
    queryParams: Record<string, string> = {},
    pathParams: Record<string, string> = {}
) => {
    let query = new URLSearchParams(queryParams).toString();
    if (query) query = `?${query}`;
    return (
        url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query
    );
};
