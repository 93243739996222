import React, { ReactElement } from "react";
import {
    Header as MantineHeader,
    useMantineTheme,
    SimpleGrid,
    ActionIcon,
    Indicator,
} from "@mantine/core";
import { IconListDetails } from "@tabler/icons-react";
import { useId } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

import {
    IconMenuLine as IconMenu,
    IconMapLine as IconMapView,
} from "../Icons/Icons";

import useHeaderStyles from "./MapHeader.styles";
import useApplicationStore from "../../stores/application.store";
import useFacilityStore from "../../stores/facility.store";
import useUserIssueReportsStore from "../../stores/userIssueReports.store";

const ICON_SIZE = 34;

type MapHeaderProps = {
    visible?: boolean;
};

function MapHeader({ visible }: MapHeaderProps): ReactElement {
    const { classes } = useHeaderStyles();
    const theme = useMantineTheme();
    const id = useId();
    const { t } = useTranslation();
    const isFacilityListActive = useApplicationStore(
        (state) => state.isFacilityListActive
    );
    const toggleIsFacilityListActive = useApplicationStore(
        (state) => state.toggleIsFacilityListActive
    );
    const toggleDrawerActive = useApplicationStore(
        (state) => state.toggleDrawerActive
    );
    const setSelectedFacility = useFacilityStore(
        (state) => state.setSelectedFacility
    );

    const hasUnreadUserIssueUpdates = useUserIssueReportsStore(
        (state) => state.hasUnreadUserIssueUpdates
    );
    // use state.userIssueUpdates to trigger updates
    useUserIssueReportsStore((state) => state.userIssueUpdates);

    return (
        <MantineHeader
            className={!visible ? classes.fadeOut : classes.fadeIn}
            classNames={{ root: classes.root }}
            height={theme.other.appShellHeaderHeight}
            id={`wc-app-header-${id}`}
        >
            <SimpleGrid cols={2}>
                <ActionIcon
                    className={classes.button}
                    onClick={() => toggleDrawerActive()}
                    type="button"
                    title={t("header.menu")}
                    aria-label={t("header.menu")}
                >
                    <Indicator
                        disabled={!hasUnreadUserIssueUpdates()}
                        offset={8}
                        color="flamingo.6"
                        withBorder
                    >
                        <IconMenu
                            size={ICON_SIZE}
                            color={
                                theme.colorScheme === "dark"
                                    ? theme.colors.dark[0]
                                    : theme.colors.dark[9]
                            }
                        />
                    </Indicator>
                </ActionIcon>
                <div className={classes.mapListToggle}>
                    <ActionIcon
                        onClick={() => toggleIsFacilityListActive()}
                        className={classes.button}
                        type="button"
                        title={t("header.toggle")}
                        aria-label={t("header.toggle")}
                    >
                        {isFacilityListActive ? (
                            <IconMapView
                                size={ICON_SIZE}
                                onClick={() => setSelectedFacility?.(null)}
                                color={
                                    theme.colorScheme === "dark"
                                        ? theme.colors.dark[0]
                                        : theme.colors.dark[9]
                                }
                            />
                        ) : (
                            <IconListDetails
                                size={ICON_SIZE}
                                strokeWidth={1.5}
                                color={
                                    theme.colorScheme === "dark"
                                        ? theme.colors.dark[0]
                                        : theme.colors.dark[9]
                                }
                            />
                        )}
                    </ActionIcon>
                </div>
            </SimpleGrid>
        </MantineHeader>
    );
}

export default MapHeader;
