import { useEffect, useRef } from "react";
import useGeolocationStore from "../../../stores/geolocation.store";
import useLocationPermission from "./useLocationPermission";

enum GeolocationPositionErrorCodes {
    PERMISSION_DENIED = 1,
    POSITION_UNAVAILABLE = 2,
    TIMEOUT = 3,
}

const positionOptionsDefault: PositionOptions = {
    enableHighAccuracy: true,
    timeout: 20000,
    maximumAge: 30000,
};

const useGeolocationWatcher = (options = positionOptionsDefault) => {
    const setGeolocation = useGeolocationStore((state) => state.setGeolocation);
    const invalidatePosition = useGeolocationStore(
        (state) => state.invalidatePosition
    );
    const setPermission = useGeolocationStore((state) => state.setPermission);
    const setError = useGeolocationStore((state) => state.setError);
    const setWatcher = useGeolocationStore((state) => state.setWatcher);
    const watcher = useGeolocationStore((state) => state.watcher);
    const locationPermission = useLocationPermission();
    const geolocationWatcher = useRef(watcher);
    useEffect(() => {
        if (
            locationPermission &&
            navigator.geolocation &&
            geolocationWatcher.current === null
        ) {
            const onGeolocationUpdate = (position: GeolocationPosition) => {
                setGeolocation(position);
            };
            const onGelocationError = ({
                code,
                message,
            }: GeolocationPositionError) => {
                switch (code) {
                    case GeolocationPositionErrorCodes.POSITION_UNAVAILABLE:
                        invalidatePosition();
                        break;
                    case GeolocationPositionErrorCodes.PERMISSION_DENIED:
                        setPermission(false);
                        break;
                    default:
                        break;
                }
                setError({
                    code,
                    message,
                });
            };
            geolocationWatcher.current = navigator.geolocation.watchPosition(
                onGeolocationUpdate,
                onGelocationError,
                options
            );
            setWatcher(geolocationWatcher.current);
        }
    }, [
        options,
        geolocationWatcher,
        locationPermission,
        setWatcher,
        setGeolocation,
        setError,
        invalidatePosition,
        setPermission,
    ]);

    return geolocationWatcher !== null;
};

export default useGeolocationWatcher;
