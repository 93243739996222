import {
    IconAccessible,
    IconSettings,
    IconMessages,
} from "@tabler/icons-react";

import {
    IconInfoLine as IconInfoCircle,
    IconMapLine as IconMap,
    IconBellLine as IconBell,
    IconPrivacyPolicyLine as IconPrivacy,
    IconImprintLine as IconImprint,
    IconFeedbackLine as IconFeedback,
} from "../../components/Icons/Icons";

import { NavBarLinkProps } from "../../components/Navbar/NavbarLinkList/NavBarLink/NavBarLink";

import { t } from "../i18n";
import {
    FEEDBACK_ROUTE,
    ACCESSIBILITY_ROUTE,
    FAQ_ROUTE,
    USER_REPORTS_ROUTE,
} from "./routes";
import { SETTINGS_ROUTE } from "../routes";

const FF_ISSUE_REPORT = process.env.REACT_APP_FF_ISSUE_REPORT === "true";

const navbarLinks: (hasUserIssuesNews: boolean) => NavBarLinkProps[] = (
    hasUserIssuesNews
) => [
    ...(FF_ISSUE_REPORT
        ? [
              {
                  label: t("navbar.bookmarkedIssues"),
                  href: USER_REPORTS_ROUTE,
                  icon: IconBell,
                  rightSection: false,
                  showNewsIndicator: hasUserIssuesNews,
              },
          ]
        : []),
    {
        label: t("navbar.map"),
        href: "/",
        icon: IconMap,
        rightSection: true,
    },
    {
        label: t("navbar.settings"),
        rightSection: false,
        href: SETTINGS_ROUTE,
        icon: IconSettings,
    },
    {
        label: t("navbar.information"),
        rightSection: false,
        children: [
            {
                label: t("navbar.privacyPolicy"),
                method: () => {
                    window.open(
                        t("links.privacyPolicy"),
                        "_blank",
                        "toolbar=0,location=0,menubar=0"
                    );
                },
                icon: IconPrivacy,
                rightSection: true,
            },
            {
                label: t("navbar.accessibility"),
                href: ACCESSIBILITY_ROUTE,
                icon: IconAccessible,
                rightSection: true,
            },
            {
                label: t("navbar.imPrint"),
                method: () => {
                    window.open(
                        t("links.imPrint"),
                        "_blank",
                        "toolbar=0,location=0,menubar=0"
                    );
                },
                icon: IconImprint,
                rightSection: true,
            },
        ],
        icon: IconInfoCircle,
    },
    {
        label: t("navbar.feedback"),
        href: FEEDBACK_ROUTE,
        rightSection: false,
        icon: IconFeedback,
    },
    {
        label: t("navbar.faq"),
        href: FAQ_ROUTE,
        rightSection: false,
        icon: IconMessages,
    },
];

export default navbarLinks;
