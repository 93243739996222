import { Divider, SimpleGrid, Title } from "@mantine/core";
import React, { ReactElement, useEffect, useState } from "react";
import Sheet, { SheetRef } from "react-modal-sheet";
import {
    useMotionValue,
    useMotionValueEvent,
    motion,
    transform,
} from "framer-motion";
import useBottomSheetStyles from "./BottomSheet.styles";

const ANIMATION_OPTIONS = { duration: 0.2 };

type ResizeObservedDivProps = {
    children: React.ReactNode;
};

// this helper component might need to be moved!
function ResizeObservedDiv({ children }: ResizeObservedDivProps): ReactElement {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const observedHeight = entries[0].contentRect.height;
            setHeight(observedHeight);
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <motion.div
            style={{ height, overflow: "auto" }}
            animate={{ height }}
            transition={ANIMATION_OPTIONS}
        >
            <div ref={containerRef}>{children}</div>
        </motion.div>
    );
}

type BottomSheetProps = {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement | null;
    headerTitle?: string;
    showBackdrop?: boolean;
    closeOnBackdropClick?: boolean;
    closeOnDrag?: boolean;
    bringToFront?: boolean;
};

function BottomSheet({
    isOpen,
    children,
    onClose,
    headerTitle = "",
    showBackdrop = true,
    closeOnBackdropClick = true,
    closeOnDrag = true,
    bringToFront = false,
}: BottomSheetProps): ReactElement {
    const { classes } = useBottomSheetStyles();

    const sheetRef = React.useRef<SheetRef>();
    const containerRef = React.useRef<HTMLDivElement>();
    const opacity = useMotionValue(0);

    useMotionValueEvent(sheetRef.current?.y ?? opacity, "change", (x) => {
        if (containerRef.current) {
            const height = containerRef.current.offsetHeight;
            const opacityTransform = transform(
                [0, 0.3 * height, 0.7 * height],
                [1, 1, 0]
            );
            opacity.set(opacityTransform(x));
        }
    });

    return (
        <Sheet
            ref={sheetRef}
            isOpen={isOpen}
            onClose={onClose}
            onOpenEnd={() => opacity.set(1)}
            detent="content-height"
            style={{
                zIndex: bringToFront ? 1010 : 1001,
            }}
        >
            <Sheet.Container ref={containerRef} className={classes.sheet}>
                <ResizeObservedDiv>
                    <Sheet.Header disableDrag={!closeOnDrag}>
                        <SimpleGrid spacing={8} className={classes.header}>
                            {closeOnDrag && <hr className={classes.handle} />}
                            {headerTitle !== "" && (
                                <motion.div style={{ opacity }}>
                                    <Title order={3} align="center">
                                        {headerTitle}
                                    </Title>
                                    <Divider size="sm" />
                                </motion.div>
                            )}
                        </SimpleGrid>
                    </Sheet.Header>
                    <Sheet.Content disableDrag style={{ opacity }}>
                        <div className={classes.container}>{children}</div>
                    </Sheet.Content>
                </ResizeObservedDiv>
            </Sheet.Container>
            <Sheet.Backdrop
                className={showBackdrop ? classes.backdrop : classes.noBackdrop}
                onTap={() => {
                    if (closeOnBackdropClick) {
                        // calling onClose is necessary, because the onClose prop of the Sheet component only applies when the user closes the sheet, not when open is set to false programmatically
                        onClose();
                    }
                }}
            />
        </Sheet>
    );
}

export default BottomSheet;
