import React, { ReactElement } from "react";
import { Title, Divider, SimpleGrid } from "@mantine/core";

type BottomSheetHeaderProps = {
    title: string;
};

function BottomSheetHeader({ title }: BottomSheetHeaderProps): ReactElement {
    return (
        <SimpleGrid spacing={8} w="100%">
            <Title order={3} align="center">
                {title}
            </Title>
            <Divider size="sm" />
        </SimpleGrid>
    );
}

export default BottomSheetHeader;
