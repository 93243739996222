import { createStyles } from "@mantine/core";

const useDisplayModeStyles = createStyles(() => ({
    label: {
        display: "inline-block",
        width: "110px",
    },
    body: {
        marginLeft: "16px",
    },
}));

export default useDisplayModeStyles;
