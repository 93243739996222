import { fetchCreateLog } from "../api/wcAppComponents";
import { LogLevelType } from "../api/wcAppSchemas";

const type = "FRONTEND";

export default function sendLog(
    accessToken: string,
    level: LogLevelType,
    context: string,
    message: string
) {
    if (accessToken) {
        fetchCreateLog({
            headers: { Authorization: `Bearer ${accessToken}` },
            body: { type, level, context, message },
        });
    }
}
