import { Container, Group, Table } from "@mantine/core";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
    IconMaleLine as IconMale,
    IconFemaleLine as IconFemale,
    IconDiversLine as IconDivers,
    IconAccessibilityLine as IconAccessibility,
} from "../../../Icons/Icons";
import useFloorToiletsListStyles from "./FloorToiletsList.styles";
import { PropertyToilet } from "../../../../utilities/api/wcAppSchemas";

const ICON_SIZE = 28;

function FloorToiletsList({
    propertyToilets,
}: {
    propertyToilets: PropertyToilet[];
}): ReactElement {
    const { t } = useTranslation();
    const { classes } = useFloorToiletsListStyles();

    const rows = propertyToilets.map((toilet) => {
        let sexIcon = <IconDivers size={ICON_SIZE} />;
        if (toilet.sex === "MEN") {
            sexIcon = <IconMale size={ICON_SIZE} />;
        }
        if (toilet.sex === "WOMEN") {
            sexIcon = <IconFemale size={ICON_SIZE} />;
        }

        const wcTypeIcon = (
            <Group noWrap spacing={0} position="center">
                {toilet.accessible && <IconAccessibility size={ICON_SIZE} />}
                {sexIcon}
            </Group>
        );

        return (
            <tr key={toilet.id}>
                <td className={classes.tableRow}>{toilet.building}</td>
                <td className={classes.tableRow}>{toilet.room}</td>
                <td className={classes.tableRow}>{wcTypeIcon}</td>
            </tr>
        );
    });

    return (
        <Container fluid={false} size="xs" p={0}>
            <Table>
                <thead>
                    <tr>
                        <th
                            className={classes.tableRow}
                            style={{ textAlign: "center" }}
                        >
                            {t("propertyToiletsListDialog.building")}
                        </th>
                        <th
                            className={classes.tableRow}
                            style={{ textAlign: "center" }}
                        >
                            {t("propertyToiletsListDialog.roomNumber")}
                        </th>
                        <th
                            className={classes.tableRow}
                            style={{ textAlign: "center" }}
                        >
                            {t("propertyToiletsListDialog.type")}
                        </th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </Container>
    );
}

export default FloorToiletsList;
