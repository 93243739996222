import React from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../components/Pages/DefaultPage";
import Feedback from "../components/Pages/Feedback/Feedback";

function FeedbackPage() {
    const { t } = useTranslation();
    return (
        <DefaultPage title={t("pages.feedback.header")}>
            <Feedback />
        </DefaultPage>
    );
}

export default FeedbackPage;
