import { LatLngExpression } from "leaflet";

export const COORDINATES_UNTER_DEN_LINDEN: LatLngExpression = {
    lat: 52.517103,
    lng: 13.388072,
};

export const swapCoordinates = (coordinates: L.LatLngTuple[]) =>
    coordinates.map(([a, b]): L.LatLngTuple => [b, a]);

export const currentPositionToLeaflet = (
    coords: GeolocationCoordinates | null
) => {
    if (coords) {
        return {
            lat: coords.latitude,
            lng: coords.longitude,
        };
    }
    return coords;
};
