import React, { ReactElement } from "react";
import { Notification } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ErrorNotificationIcon } from "./utilities/client/notifications";

function GlobalErrorNotification(): ReactElement {
    const { t } = useTranslation();

    return (
        <Notification icon={ErrorNotificationIcon} withCloseButton={false}>
            {t("notifications.general.message")}
        </Notification>
    );
}

export default GlobalErrorNotification;
