import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Issue } from "../utilities/api/wcAppSchemas";

export type UserIssue = Issue & { lastUpdated: string };

export type UserIssueReportsStore = {
    userIssues: UserIssue[];
    userIssueUpdates: string[];
    getUserIssues: () => UserIssue[];
    hasUnreadUserIssueUpdates: () => boolean;
    setUserIssues: (userIssues: UserIssue[]) => void;
    addUserIssue: (issue: Issue) => void;
    removeUserIssue: (id: string) => void;
    addUserIssueUpdates: (issueIds: string[]) => void;
    clearUserIssueUpdates: () => void;
};

const sortByLastUpdate = (
    { lastUpdated: lastUpdatedA, dateCreated: dateCreatedA }: UserIssue,
    { lastUpdated: lastUpdatedB, dateCreated: dateCreatedB }: UserIssue
) => {
    return (
        lastUpdatedB.localeCompare(lastUpdatedA) ||
        dateCreatedB.localeCompare(dateCreatedA)
    );
};

const useUserIssueReportsStore = create<UserIssueReportsStore>()(
    persist(
        (set, get) => ({
            userIssues: [],
            userIssueUpdates: [],

            getUserIssues: () => {
                return get().userIssues;
            },

            hasUnreadUserIssueUpdates: () => {
                const { userIssueUpdates } = get();
                return userIssueUpdates.length > 0;
            },

            setUserIssues: (userIssues: UserIssue[]) => {
                set({ userIssues: [...userIssues.sort(sortByLastUpdate)] });
            },

            addUserIssue: (issue: Issue) => {
                const { userIssues, setUserIssues } = get();
                if (
                    !userIssues.some((userIssue) => userIssue.id === issue.id)
                ) {
                    userIssues.push({
                        ...issue,
                        lastUpdated: new Date(0).toISOString(),
                    });
                    setUserIssues(userIssues);
                }
            },

            removeUserIssue: (id: string) => {
                const { userIssues, setUserIssues } = get();
                setUserIssues(userIssues.filter((issue) => issue.id !== id));
            },

            addUserIssueUpdates: (issueIds: string[]) => {
                const { userIssueUpdates } = get();
                const newUserIssueUpdates: string[] = Array.from(
                    new Set([...userIssueUpdates, ...issueIds]).keys()
                );
                set({ userIssueUpdates: newUserIssueUpdates });
            },

            clearUserIssueUpdates: () => {
                set({ userIssueUpdates: [] });
            },
        }),
        { name: "userIssueReports" }
    )
);

export default useUserIssueReportsStore;
