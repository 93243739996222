import React, { Component, ErrorInfo, ReactNode } from "react";
import sendLog from "./utilities/client/logHandler";
import GlobalErrorNotification from "./GlobalErrorNotification";

type Props = {
    accessToken: string | null;
    children?: ReactNode;
};

type State = {
    hasError: boolean;
    error?: Error;
};

class GlobalErrorBoundaryAfterAuthentication extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    public static getDerivedStateFromError(error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    // pre-defined lifecycle function
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (process.env.NODE_ENV === "development") {
            /* eslint-disable */
            console.log("Uncaught error:", error.message, error.stack);
            console.log("Error info:", errorInfo.componentStack);
            /* eslint-enable */
        }
        const { accessToken } = this.props;
        if (accessToken) {
            sendLog(accessToken, "ERROR", error.message, error.stack || "");
        }
    }

    public render() {
        const { hasError } = this.state;
        if (hasError) {
            return <GlobalErrorNotification />;
        }

        const { children } = this.props;
        return children;
    }
}

export default GlobalErrorBoundaryAfterAuthentication;
