import React, { ReactElement } from "react";
import { NotificationProps } from "@mantine/notifications";
import {
    IconAlertCircle,
    IconAlertTriangle,
    IconCircleX,
} from "@tabler/icons-react";
import { ThemeIcon } from "@mantine/core";
import { t } from "../../../config/i18n";

const NOTIFICATION_ICON_SIZE = 32;

const NOTIFICATION_TIMEOUT = 5000; // in ms
export const LONG_NOTIFICATION_TIMEOUT = 10000; // in ms

function NotificationIconWrapper(
    color: string,
    icon: ReactElement
): ReactElement {
    return (
        <ThemeIcon
            variant="outline"
            color={color}
            style={{ border: "none" }}
            size="xl"
        >
            {icon}
        </ThemeIcon>
    );
}

const InfoNotificationIcon = NotificationIconWrapper(
    "blue",
    <IconAlertCircle size={NOTIFICATION_ICON_SIZE} />
);

const WarningNotificationIcon = NotificationIconWrapper(
    "yellow.6",
    <IconAlertTriangle size={NOTIFICATION_ICON_SIZE} />
);

export const ErrorNotificationIcon = NotificationIconWrapper(
    "red",
    <IconCircleX size={NOTIFICATION_ICON_SIZE} />
);

export const NO_GPS_NOTIFICATION: NotificationProps & { id: string } = {
    id: "no-gps-notification",
    title: t("notifications.noGps.title"),
    message: t("notifications.noGps.message"),
    icon: InfoNotificationIcon,
    autoClose: false,
};

export const NO_NETWORK_NOTIFICATION: NotificationProps & { id: string } = {
    id: "no-network-notification",
    title: t("notifications.noNetwork.title"),
    message: t("notifications.noNetwork.message"),
    icon: WarningNotificationIcon,
    autoClose: false,
};

export const NO_TOILETS_ON_TRACK_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "no-toilets-on-track-notification",
    title: t("notifications.noToiletsOnTrack.title"),
    message: t("notifications.noToiletsOnTrack.message"),
    icon: ErrorNotificationIcon,
    autoClose: false,
};

export const NO_PROPERTY_TOILETS_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "no-property-toilets-notification",
    title: t("notifications.noPropertyToilets.title"),
    message: t("notifications.noPropertyToilets.message"),
    icon: ErrorNotificationIcon,
    autoClose: false,
};

export const NO_ISSUES_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "no-issues-notification",
    title: t("notifications.noIssues.title"),
    message: t("notifications.noIssues.message"),
    icon: ErrorNotificationIcon,
    autoClose: false,
};

export const NO_ISSUE_REPORT_OPTIONS_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "no-issue-report-options-notification",
    title: t("notifications.noIssueReportOptions.title"),
    message: t("notifications.noIssueReportOptions.message"),
    icon: ErrorNotificationIcon,
};

export const SEND_ISSUE_ERROR_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "send-issue-error-notification",
    title: t("notifications.sendIssueError.title"),
    message: t("notifications.sendIssueError.message"),
    icon: ErrorNotificationIcon,
    autoClose: NOTIFICATION_TIMEOUT,
};

export const MAP_ERROR_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "map-error-notification",
    title: t("notifications.mapError.title"),
    message: t("notifications.mapError.message"),
    icon: ErrorNotificationIcon,
    autoClose: false,
};

export const NAVIGATION_ERROR_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "navigation-error-notification",
    title: t("notifications.navigationError.title"),
    message: t("notifications.navigationError.message"),
    icon: ErrorNotificationIcon,
    autoClose: false,
};

export const INVALID_IMAGE_TYPE_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "invalid-image-type-notification",
    title: t("notifications.invalidImageType.title"),
    message: t("notifications.invalidImageType.message"),
    icon: InfoNotificationIcon,
    autoClose: NOTIFICATION_TIMEOUT,
};

export const TOO_MANY_IMAGES_SELECTED_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "too-many-images-selected-notification",
    title: t("notifications.tooManyImagesSelected.title"),
    message: t("notifications.tooManyImagesSelected.message"),
    icon: InfoNotificationIcon,
    autoClose: NOTIFICATION_TIMEOUT,
};

export const IMAGE_COMPRESSION_ERROR_NOTIFICATION: NotificationProps & {
    id: string;
} = {
    id: "image-compression-error-notification",
    title: t("notifications.imageCompressionError.title"),
    message: t("notifications.imageCompressionError.message"),
    icon: ErrorNotificationIcon,
    autoClose: NOTIFICATION_TIMEOUT,
};
