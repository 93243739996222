import { createStyles } from "@mantine/core";

const useFacilityListStyles = createStyles(() => ({
    main: {
        boxSizing: "border-box",
        padding: "0 10px",
        paddingBottom: 0,
    },
}));

export default useFacilityListStyles;
