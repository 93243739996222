import { Anchor, Button, Stack, Text } from "@mantine/core";
import React, { ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import mailtoLink from "mailto-link";
import Lottie from "lottie-react";
import { IssueReportStylesClasses } from "../IssueReport.styles";
import {
    EMAIL_ISSUE_REPORT,
    TEL_ISSUE_REPORT,
} from "../../../../constants/map";
import reportAnimation from "../../../../assets/animations/report.json";
import BottomSheetHeader from "../../../BottomSheetHeader/BottomSheetHeader";
import BottomSheetFooter from "../../../BottomSheetFooter/BottomSheetFooter";
import { BvgProperty } from "../../../../utilities/api/wcAppSchemas";

type BvgPropertyReportProps = {
    classes: IssueReportStylesClasses;
    property: BvgProperty;
    closeBottomSheet: () => void;
};
function BvgPropertyReport({
    classes,
    property,
    closeBottomSheet,
}: BvgPropertyReportProps): ReactElement {
    const { t } = useTranslation();
    const subject = t("issueReport.property.mail.subject");
    const body = t("issueReport.property.mail.body", {
        address: property.address,
    });
    const mailtoHref = mailtoLink({
        to: EMAIL_ISSUE_REPORT,
        subject,
        body,
    });

    const telHref = `tel:${TEL_ISSUE_REPORT}`;

    return (
        <>
            <BottomSheetHeader title={t("bottomSheet.reportDialog.title")} />
            <Lottie
                style={{ maxWidth: 200 }}
                animationData={reportAnimation}
                loop={false}
            />
            <Stack align="stretch" maw={800} px={8}>
                <Text className={classes.text}>
                    <Trans
                        i18nKey="issueReport.property.instructions"
                        values={{
                            email: EMAIL_ISSUE_REPORT,
                            phoneNumber: TEL_ISSUE_REPORT,
                        }}
                        components={{
                            Email: <Anchor href={mailtoHref} />,
                            PhoneNumber: <Anchor href={telHref} />,
                        }}
                    />
                </Text>

                <Text className={classes.text}>
                    {t("issueReport.relevantInformation")}
                </Text>

                <Text className={classes.importantInformation}>
                    <Text weight="bold">
                        {t("issueReport.property.address")}
                    </Text>
                    {property.address}
                    <Text weight="bold">
                        {t("issueReport.property.locationDetail")}
                    </Text>
                    {t("issueReport.property.locationDetailDescription")}
                    <Text weight="bold">
                        {t("issueReport.disturbanceType")}
                    </Text>
                    {t("issueReport.describeIssue")}
                </Text>

                <Text className={classes.text}>
                    {t("issueReport.acknowledgement")}
                </Text>
            </Stack>
            <BottomSheetFooter>
                <Button fullWidth onClick={() => closeBottomSheet()}>
                    {t("bottomSheet.reportDialog.confirm")}
                </Button>
            </BottomSheetFooter>
        </>
    );
}

export default BvgPropertyReport;
