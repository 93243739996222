import React, { MouseEventHandler, ReactElement } from "react";
import { Collapse, Group, Divider, UnstyledButton, Stack } from "@mantine/core";

import {
    IconChevronDownLine as IconChevronDown,
    IconChevronUpLine as IconChevronUp,
} from "../Icons/Icons";

import useCollapsibleStyles from "./Collapsible.styles";

type CollapsibleProps = {
    header?: JSX.Element;
    content?: JSX.Element;
    isSelected?: boolean;
    isCollapsible?: boolean;
    isOpen?: boolean;
    onCollapseChanged?: (isCollapsed: boolean) => void;
    onTransitionEnd?: () => void;
    onClick?: MouseEventHandler;
};

const CHEVRON_ICON_SIZE = 28;

function Collapsible({
    header,
    content,
    isSelected = false,
    isCollapsible = true,
    isOpen = false,
    onCollapseChanged,
    onClick = () => ({}),
    onTransitionEnd = () => ({}),
}: CollapsibleProps): ReactElement {
    const { classes } = useCollapsibleStyles();

    const collapseHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        if (onCollapseChanged) onCollapseChanged(!isOpen);
    };
    return (
        <UnstyledButton
            className={
                isSelected ? classes.selectedWrapperCard : classes.wrapperCard
            }
            style={{ width: "100%" }}
            color="dark"
            onClick={onClick}
        >
            <Group noWrap spacing="xs" grow>
                {header}
                {isCollapsible && (
                    <div className={classes.collapseButton}>
                        {isOpen ? (
                            <IconChevronUp
                                size={CHEVRON_ICON_SIZE}
                                onClick={collapseHandler}
                            />
                        ) : (
                            <IconChevronDown
                                size={CHEVRON_ICON_SIZE}
                                onClick={collapseHandler}
                            />
                        )}
                    </div>
                )}
            </Group>
            <Collapse in={isOpen} onTransitionEnd={onTransitionEnd}>
                <Stack>
                    <Divider className={classes.divider} />
                    {content}
                </Stack>
            </Collapse>
        </UnstyledButton>
    );
}

export default React.memo(Collapsible);
