import { createStyles } from "@mantine/core";

type SplitViewHandleStyleParams = {
    handleHeight: number;
    top: number;
};

const useSplitViewHandleStyles = createStyles(
    (theme, { handleHeight, top }: SplitViewHandleStyleParams) => ({
        main: {
            height: `${handleHeight}px`,
        },
        splitHandle: {
            alignItems: "center",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            border: "solid 1px #d5d7e0",
            borderBottom: "hidden",
            borderLeft: "hidden",
            borderRight: "hidden",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: `${handleHeight}px`,
            position: "absolute",
            top: `${top}px`,
            width: "100%",
        },
        splitHandleIndicator: {
            border: "none",
            borderRadius: "5px",
            margin: "0 0 2px 0",
            width: "50px",
            height: "4px",
            backgroundColor: theme.colors.dark[0],
        },
    })
);

export default useSplitViewHandleStyles;
