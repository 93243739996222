import { createStyles } from "@mantine/core";

type SplitViewStyleParams = {
    handleHeight: number;
    isDragging: boolean;
};

const useSplitViewStyles = createStyles(
    (_theme, { handleHeight, isDragging }: SplitViewStyleParams) => ({
        bottom: {
            overflow: "auto",
            position: "relative",
            top: `${handleHeight}px`,
            transition: !isDragging ? "height 0.2s" : "",
        },
        handle: {
            position: "absolute",
            transition: !isDragging ? "transform 0.2s" : "",
            width: "100%",
        },
        main: {
            height: "100%",
        },
        top: {
            transition: !isDragging ? "height 0.2s" : "",
        },
    })
);

export default useSplitViewStyles;
