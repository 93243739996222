import {
    OPENSTREET_MAP_LINK,
    OPEN_DATA_COMMONS_LINK,
    EMAIL_FEEDBACK,
    EMAIL_SUPPORT,
    LINE_BREAK,
} from "../constants/map";

const { REACT_APP_MAX_LIMIT_ISSUE_REPORTS } = process.env;

export const en = {
    navigate: "Navigate",
    stop: "Stop",
    close: "Close",
    sendEmail: "Send email",
    feedbackMail: {
        subject: "Feedback for the WC-App",
        body: `With kind regards,${LINE_BREAK}${LINE_BREAK}{{customerName}}`,
    },
    navbar: {
        login: "Login",
        version: "Version",
        bookmarkedIssues: "Bookmarked issues",
        map: "Map",
        settings: "Settings",
        information: "Information",
        feedback: "Give feedback",
        userProfile: "Profile",
        termsAndConditions: "Terms and conditions",
        privacyPolicy: "Privacy policy",
        imPrint: "Imprint",
        accessibility: "Accessibility",
        faq: "Frequently asked questions",
    },
    pages: {
        feedback: {
            header: "Give Feedback on the WC-App",
            acknowledgement:
                "Thank you for your interest in giving us feedback on the WC-App.",
            instructions:
                "Please send us an email by clicking on the following button.",
            privacyHint:
                "We use your feedback confidentially and only within the web development team in order to improve the WC-App.",
            finalAcknowledgement: "Thank you for your contribution!",
        },
        settings: {
            header: "Settings",
            keyAuthorization: "Key authorization",
            darkMode: "Dark mode",
        },
        userProfile: { header: "Profile" },
        termsAndConditions: { header: "Terms and conditions" },
        privacyPolicy: { header: "Privacy policy" },
        imPrint: { header: "Imprint" },
        accessibility: {
            header: "Accessibility",
            title: "Declaration on digital accessibility",
            introduction:
                "The Berliner Verkehrsbetriebe (BVG) are endeavouring to make the web application https://wc-app.bvg.de/ accessible without barriers.",
            legalBasis:
                "The legal basis is the Disability Equality Act (BGG) and the Barrier-Free Information Technology Ordinance (BITV) 2.0 and BIKTG as amended.",
            contactInstructions:
                "Who can you contact with comments or questions about digital accessibility (feedback option)?",
            notFullyAccessible:
                "This web application is only partially accessible. It only partially meets the requirements of the current BITV.",
            notSupportedFeatures:
                "The areas listed below are not or only partially accessible:",

            contactAddress: [
                "Webentwicklungsteam VI-VF34",
                `${EMAIL_FEEDBACK}`,
            ],
            arbitrationBoard: "Contact the conciliation body",
            arbitrationBoardContactInstructions:
                "If no satisfactory solution has been found even after your feedback to the above mentioned contact, you can turn to the conciliation body according to § 16 BGG by email: info@schlichtungsstelle-bgg.de",
            finalContact:
                "Contact the State Commissioner for Digital Accessibility",
            finalContactInstructions:
                "If your contact with the public body was not successful, you can contact the State Digital Accessibility Officer.",
            changeNote:
                "The changes to fix these known barriers will be published on this page.",
            barrier: "Barrier:",
            barriers: [
                {
                    title: "Alternative texts for control elements",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-1-1a-alternativtexte-fuer-bedienelemente",
                    bulletPoints: [
                        "GPS controls do not have alternative text",
                        "Disable navigation without alternative text menu arrows",
                        "Navigation arrows do not have alternative text",
                        "Map and pins have no alternative text",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Alternative texts for graphics and objects",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-1-1b-alternativtexte-fuer-grafiken-und-objekte",
                    bulletPoints: [
                        "Alternative text for background images, icon fonts and SVGs must be added if they are not linked",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "HTML structural elements for headings",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-3-1a-html-strukturelemente-fuer-ueberschriften",
                    bulletPoints: [
                        "Headings must be provided with the HTML semantics h1-h6",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "HTML structural elements for lists",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-3-1b-html-strukturelemente-fuer-listen",
                    bulletPoints: [
                        "Lists must contain the ul, ol HTML elements",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Contrasts of texts sufficient",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-4-3-kontraste-von-texten-ausreichend",
                    bulletPoints: [
                        "Map must be adjusted",
                        "For each element for which a foreground colour is set, a background colour must also be set and vice versa",
                        "When selecting a WC at 200% zoom, the centring is wrong",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Content wraps",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-4-10-inhalte-brechen-um",
                    bulletPoints: [
                        "Use of the app not possible at the width of 1280 pixels and 400% zoom",
                        "There are horizontal scroll bars in the menu at the width of 320 pixels",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Contrasts of graphics and graphic controls sufficient",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-4-11-kontraste-von-grafiken-und-grafischen-bedienelementen-ausreichend",
                    bulletPoints: [
                        "Graphics and controls must have a contrast of 3:1 to the background",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Page usable without mouse",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-1-1-ohne-maus-nutzbar",
                    bulletPoints: ["Page is not operable without mouse"],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "No keyboard traps",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-1-2-keine-tastaturfalle",
                    bulletPoints: ["Keyboard navigation not available"],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Conclusive order in keyboard operation",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-4-3-schluessige-reihenfolge-bei-der-tastaturbedienung",
                    bulletPoints: ["Keyboard navigation not available"],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Current position of the focus clear",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-4-7-aktuelle-position-des-fokus-deutlich",
                    bulletPoints: ["Keyboard navigation not available"],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Visible inscription part of the accessible name",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-5-3-sichtbare-beschriftung-teil-des-zugaenglichen-namens",
                    bulletPoints: [
                        "Alternative texts of control elements such as links, text field labels, buttons or checkboxes should contain their visible labels",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "No unexpected context change with focus",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-3-2-1-keine-unerwartete-kontextaenderung-bei-fokus",
                    bulletPoints: [
                        "Due to the fact that there is no keyboard navigation, this cannot be tested",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Consistent naming",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-3-2-4-konsistente-bezeichnung",
                    bulletPoints: [
                        "Uniform naming of multiple HTML elements such as buttons",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Labels of form elements available",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-3-3-2-beschriftungen-von-formularelementen-vorhanden",
                    bulletPoints: ["Currently not available"],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Custom settings",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/11-7-benutzerdefinierte-einstellungen",
                    bulletPoints: ["We cannot cover everything here"],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Name Role Value available",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-4-1-2-name-rolle-wert-verfuegbar",
                    bulletPoints: [
                        "WC markers must be labelled with name, role and properties",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
                {
                    title: "Structured content",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-3-1d-inhalt-gegliedert",
                    bulletPoints: [
                        "Bold fonts must be marked with the HTML element strong",
                    ],
                    timeline:
                        "The timeline for removing the barriers is planned until the 2nd quarter of 2024.",
                },
            ],
        },
        frequentlyAskedQuestions: {
            header: "FAQ",
            generalQuestions: {
                headline: "General information about the WC app",
                faq: [
                    {
                        question: "Who is the WC app for?",
                        answers: [
                            "In the long term, all BVG employees should be able to use the WC app. Currently, the app is being piloted for above-ground transport drivers. Those who perform activities on the routes of above-ground modes of transport are also part of the target group.",
                        ],
                    },
                    {
                        question: "Who can and is allowed to use the WC app?",
                        answers: [
                            "All BVGers may use the WC app. However, the functions of the first version are primarily useful for above-ground driving personnel. ",
                        ],
                    },
                    {
                        question: "On which devices can I use the WC app?",
                        answers: [
                            "The WC app may only be used on BVG-owned devices. Use on private devices has not been approved and is therefore not permitted. ",
                            "The presentation of content in the app is currently tailored for smartphone and tablet use. In the future, the presentation will be revised for desktop use.",
                        ],
                    },
                    {
                        question:
                            "Who is allowed to use the WCs displayed in the WC app?",
                        answers: [
                            "A key is required for the above-ground driving staff restrooms, this can be obtained through or instructed by the responsible manager.",
                        ],
                    },
                    {
                        question: "Who developed the WC app?",
                        answers: [
                            "The WC app was and is developed by BVG's own web development team VI-VF34.",
                        ],
                    },
                    {
                        question:
                            "Can I give feedback on the WC app and if so, how?",
                        answers: [
                            `Your feedback is very valuable to the web development team. Use the ‘Give Feedback’ feature in the WC app menu or send us an email at ${EMAIL_FEEDBACK}.`,
                        ],
                    },
                ],
            },
            newUser: {
                headline: "Notes for (new) users",
                faq: [
                    {
                        question: "What do I have to do to use the WC app?",
                        answers: [
                            "To use the WC app, you must log in at wc-app.bvg.de with your Windows user credentials. If you belong to the BO department, you will also find WC-App in the work area of your smartphone.",
                        ],
                    },
                    {
                        question: "What data do I use to log into the WC app?",
                        answers: [
                            "You need to log in to the WC app with your Windows user credentials:",
                            " - Company account: BVG email address",
                            " - Password: Windows password",
                            "Attention: if you enter the wrong password several times, you will have to reset your Windows password. Please refer to the intranet under IT & Service - Password Self Service.",
                        ],
                    },
                    {
                        question:
                            "Why do I have to state whether I have a WC key and if so, which one?",
                        answers: [
                            "Every BVGer may use a WC, provided that they are authorized to do so. Some WC facilities are only accessible with a key. We need to know whether you have a key so that we can show you the right WCs. You can change your selection later in the settings. In the first version of the WC app, only the WCs on the overground routes will be displayed. Gradually, we will update the WC data in the app and be able to display other WCs accordingly. ",
                        ],
                    },
                    {
                        question:
                            "What happens if I don't want to or can't answer the key authorization question?",
                        answers: [
                            "If you do not want to answer the question, then the WCs for which you are authorized cannot be displayed in the app. It will then not be possible to use the app's functions. ",
                            "If you are unsure whether you have or should have a key, ask a colleague or your manager.",
                        ],
                    },
                    {
                        question: "Why are no WCs shown to me in the app?",
                        answers: [
                            "There may be a technical problem.",
                            `Check your Internet connection, otherwise contact the Service Desk by email at ${EMAIL_SUPPORT} or by phone at +49 30 256 22020.`,
                        ],
                    },
                    {
                        question:
                            "Will my details be passed on to a third party (e.g. my manager)?",
                        answers: [
                            "The personal information you provide when using the WC app including your WC keys will not be evaluated or shared with third parties.",
                        ],
                    },
                    {
                        question: "Why do I need to share my location?",
                        answers: [
                            "To be able to use all functionalities to the full extent, we recommend that you share your location. The following features can only be used with location sharing:",
                            " - When sharing your location, you can see on the map where you are and which WCs are near you.",
                            " - In the WC list or when you click on a WC, you can see the walking time and the distance to the WCs.",
                            " - The live navigation function easily guides you to the selected WC.",
                        ],
                    },
                    {
                        question: "What happens if I do not share my location?",
                        answers: [
                            "If you don't share your location, you can only view the WCs on the map, select them and read the individual information. Navigation and information about the distance and walk time cannot be displayed.",
                        ],
                    },
                    {
                        question:
                            "Can anyone see my location if I share it in the WC app?",
                        answers: [
                            "The personal information you provide when using the WC app, including your location, will not be evaluated or shared with third parties.",
                        ],
                    },
                    {
                        question: "I can't manage the WC app, who can help me?",
                        answers: [
                            `Contact a colleague who already knows the WC app, use the ‘Give feedback’ function in the WC app menu, or contact the Service Desk. You can reach them by email at ${EMAIL_SUPPORT} or by phone at +49 30 256 22020.`,
                        ],
                    },
                ],
            },
            individualFunctionalities: {
                headline: "Notes on individual functionalities",
                faq: [
                    {
                        question: "What do I see in the map view?",
                        answers: [
                            "In the map view, you will be shown a map with WCs in Berlin, provided you are authorized to use them and have a key according to the information you provide. If you do not have the authorization and the corresponding key, no WCs will be displayed in the first version of the WC app.",
                        ],
                    },
                    {
                        question: "What do I see in the list view?",
                        answers: [
                            "In the list view you will see a smaller map and a list of WCs. In the list you can see the WC's specific information in text form. ",
                            "If you have shared your location, the list will show you the nearest 10 WCs with the duration of the walk in minutes and the distance in meters, relative to your current location. ",
                            "If you have not shared your location, all WCs in Berlin will be displayed in the list view.",
                        ],
                    },
                    {
                        question:
                            "Why am I not shown all the WCs in Berlin in the list view?",
                        answers: [
                            "If you have shared your location, the list will show you the nearest 10 WCs. We assume that this number is sufficient for the users and better for the performance of the app than displaying all WCs in Berlin.",
                        ],
                    },
                    {
                        question: "What is the ‘Navigate’ function for?",
                        answers: [
                            "Provided you have shared your location and click on a WC on the map or in the list, you can use the ‘Navigate’ function. With this you can be guided in real time to the selected WC.",
                        ],
                    },
                    {
                        question: "Why can't I use the ‘Navigate’ function?",
                        answers: [
                            "You can only use the ‘Navigate’ function if you have shared your location. Otherwise, navigation is technically not possible.",
                        ],
                    },
                    {
                        question: "Which map material does the WC app use?",
                        answers: [
                            "The WC app uses data provided by OpenStreetMap for the map material. The map material is generated by BVG itself.",
                        ],
                    },
                ],
            },
            createIssues: {
                headline: "Notes on reporting issues",
                faq: [
                    {
                        question: "How do I report an issue on a WC?",
                        answers: [
                            "Select a WC and click on ‘Report issue’. Depending on the WC, issues are reported differently - but the WC app shows you all the information you need to report an issue at the selected WC.",
                        ],
                    },
                    {
                        question:
                            "Why can't I report an issue for each WC in the same way?",
                        answers: [
                            "Depending on the type of WC, different teams are responsible for rectifying issues. To ensure that the issue reports reach the right team, issues at the WCs are reported differently. However, all issue reports are recorded and processed.",
                        ],
                    },
                    {
                        question:
                            "What happens with an issue report I have made?",
                        answers: [
                            "The issue report you submit via the WC app is forwarded to the responsible team for rectification.",
                        ],
                    },
                    {
                        question: "Can I report an issue anonymously?",
                        answers: [
                            "You can report issues at WCs on the route anonymously. Issues at WCs in BVG properties can currently only be reported by e-mail and are therefore not anonymous.",
                        ],
                    },
                    {
                        question:
                            "Why does it make sense to include my contact details when reporting an issue?",
                        answers: [
                            "If you include your contact details, the issue report processors will be able to contact you with any queries and provide updates on the status of your reported issue.",
                        ],
                    },
                    {
                        question:
                            "How can I send my contact details when reporting an issue?",
                        answers: [
                            "When you report an issue at a WC on the route, you will be asked how you want to report the issue. Simply select ‘Add my contact details’ and the WC app will add your work email address to the issue report. You will also see the contact details that will be sent at the top of the issue report window.",
                        ],
                    },
                    {
                        question:
                            "What do I do if the type of issue I want to report is not present in the list of issues?",
                        answers: [
                            "If the issue you want to report fits into a certain category, select it and search for ‘other issue’ in the list of issues.  After clicking on ‘other issue’ describe the issue in the text field, the description is a mandatory field in this case.",
                            "If the category of your issue is not present in the list, simply select the category ‘other issue’ and describe the issue in the text field, the description is also a mandatory field in this case.",
                        ],
                    },
                    {
                        question:
                            "Why should I look for the issue I want to report in the list of reported issues for a WC?",
                        answers: [
                            "We want to avoid that issues are reported twice, so that the responsible teams can concentrate on processing the existing issues. Please do not report an issue if it already appears in the list - it has already been reported and is already in the issue reporting system for further processing.",
                        ],
                    },
                    {
                        question:
                            "Why is the issue I want to report grayed out and cannot be reported?",
                        answers: [
                            "Issues are grayed out in the WC app if the respective issue has already been reported at the selected WC in the last 48 hours. This prevents issues from being reported more than once. Any issue that has been reported will be processed as quickly as possible.",
                        ],
                    },
                    {
                        question:
                            "Which issues are listed under ‘bookmarked issues’?",
                        answers: [
                            "All issues that you reported or bookmarked can be found here. This enables you to track the progress on these issues.",
                            "We cannot offer bookmarking for issues pertaining to Wall toilets, as issues with Wall toilets are directly reported to and managed by Wall.",
                        ],
                    },
                    {
                        question: "What purpose does ‘Bookmark issue’ serve?",
                        answers: [
                            "It allows you to bookmark issues so that they appear under ‘bookmarked issues’.",
                        ],
                    },
                    {
                        question: "Where can I bookmark an issue?",
                        answers: [
                            "If you select a toilet which has reported issues already you are able to view these and bookmark them by clicking on ‘Bookmark issue’. The bookmarked issue will then be listed under ‘Bookmarked issues’ for your convenience.",
                        ],
                    },
                    {
                        question:
                            "How do I know if an issue has been corrected?",
                        answers: [
                            "You can view the status of the issues you have reported at any time in the ‘My reported issues’ menu item. Corrected issue reports are marked with ‘completed’.",
                            "We cannot offer status tracking for issues pertaining to Wall toilets, as issues with Wall toilets are directly reported to and managed by Wall.",
                        ],
                    },
                    {
                        question:
                            "Can I see the processing status of a report?",
                        answers: [
                            "You can only see the processing status of an issue you have reported. You can find the issues you have reported in the menu item ‘My reported issues’.",
                            "We cannot offer status tracking for issues pertaining to Wall toilets, as issues with Wall toilets are directly reported to and managed by Wall.",
                        ],
                    },
                    {
                        question:
                            "Where can I view the issues I have reported?",
                        answers: [
                            "You can access the menu by clicking on the three lines at the top left. There you will find the menu item ‘My reported issues’, where you can view all issues that you have reported. Please note that the reported issues are stored in the browser and depending on the device. If you change the device or delete the browser data, all information about the issues you reported will be irrevocably deleted. The issue will be processed, but you will not receive any information about it in the WC app.",
                            "We cannot offer tracking for issues pertaining to Wall toilets, as issues with Wall toilets are directly reported to and managed by Wall.",
                        ],
                    },
                    {
                        question:
                            "I can’t find a previously bookmarked issue under ‘Bookmarked issues’ anymore. Why?",
                        answers: [
                            "If an issue has been closed or if you have deleted it, the issue won’t show up in the WC-App anymore.",
                        ],
                    },
                    {
                        question:
                            "Can I withdraw an issue report after it has been sent?",
                        answers: [
                            "No, once you have sent an issue report, you can no longer edit it.",
                        ],
                    },
                    {
                        question:
                            "Can I send photos of an issue with the report?",
                        answers: [
                            "Yes, when creating an issue report you have the option to create and/or upload three photos. The photos will be sent to the person who is processing the report, so they can get a better picture of the issue you are reporting.",
                            "Photos cannot be attached to issue reports for Wall toilets.",
                        ],
                    },
                    {
                        question:
                            "Can I look at the photos of an issue again after sending the report?",
                        answers: [
                            "If you took the photos first and then attached them to the issue report, you will find the photos in your device. If you took the photos when you created the issue report, they will not be saved on the device and you will not be able to view them.",
                        ],
                    },
                ],
            },
        },
        noToiletsToShow: {
            header: "We are sorry",
            noDataAvailable: "At the moment we can't show you any WCs.",
            reportIssue:
                "Please contact the operations control center directly to report toilet malfunctions.",
            workInProgress: "We are working hard to show all of the BVG WCs.",
            revisitApp: "Please come back at a later time.",
        },
    },
    profile: {
        information: "User information",
        email: "E-mail",
        name: "Name",
    },
    updateDialog: {
        title: "New update available!",
        restart: "Restart now",
        salutation: "Hello,",
        description: `a new update to your WC app is available. Just click the "Restart now" button and the application will restart`,
    },
    header: {
        menu: "Open the menu",
        toggle: "Toggle between map and list view",
    },
    links: {
        termsAndConditions:
            "https://www.bvg.de/en/general-terms-and-conditions",
        privacyPolicy: "https://www.bvg.de/en/privacy-policy",
        imPrint: "https://www.bvg.de/en/imprint",
        accessibility: "https://www.bvg.de/en/accessibility-declaration",
    },
    map: {
        attribution: `Data by <a href="${OPENSTREET_MAP_LINK}" target="_blank">OpenStreetMap</a>, under <a href="${OPEN_DATA_COMMONS_LINK}" target="_blank">ODbL</a>`,
    },
    geolocationPermissionDialog: {
        title: "Location access permission necessary",
        description:
            "In order to use this functionality you have to grant location access permission on your device and browser.",
        howto: "How to grant location access permission",
        confirm: "Understood",
        imageAlt: "Location Access Permission missing",
        grantGps: "Grant location",
        ignore: "Ignore",
    },
    accordionComponent: {
        browserInstruction: {
            title: "Turn on location sharing in the browser",
            step_1: "Open the browser settings via the three dots below",
            step_2: "Open the settings via the cogwheel",
            step_3: "Select privacy and security",
            step_4: "Select website permissions",
            step_5: "Choose location",
            step_6: "Activate the switch at location",
            step_7_1: "If the WC app appears under Blocked, select it",
            step_7_2: "Choose Allow",
        },
        mobileDeviceInstruction: {
            title: "Enable location sharing on mobile devices",
            step_1: "Open the app settings",
            step_2: "Choose location",
            step_3: "Enable switch - This will enable location tracking for all apps on your mobile device.",
            step_4: "Alternatively, location sharing can also be enabled via quick access.",
        },
        close: "Understood",
    },
    bottomSheet: {
        reportDialog: { title: "Report an issue on Toilet", confirm: "Got it" },
    },
    actionButton: { navigate: "navigate", report: ["report", "issue"] },
    issueList: {
        reportedIssues: "Reported issues ({{count}})",
        status: {
            done: "completed",
            incomplete: "in process",
            noIssues: "You don't have any bookmarked issues",
        },
        bookmarkIssue: {
            action: "Bookmark issue",
            tooltip: "This issue is already bookmarked",
        },
        removeIssue: {
            title: "Remove issue report",
            cancel: "Cancel",
            confirm: "Remove",
            prompt: "Do you really want to remove the report from your list?",
            info: "The report will still be processed, even if it is no longer displayed in your list.",
            irreversibel: "You can't undo this action.",
        },
    },
    issueReport: {
        relevantInformation: "The following information is required:",
        disturbanceType: "Type of issue:",
        describeIssue: "Please describe the problem",
        acknowledgement: "Thank you for your commitment!",
        property: {
            instructions: `If you would like to report a malfunction at this WC, please contact the TLO here by email (<Email>{{email}}</Email>) - or if necessary, by phone at <PhoneNumber>{{phoneNumber}}</PhoneNumber>.`,
            address: "address:",
            locationDetail: "WC:",
            locationDetailDescription:
                "Describe where exactly the WC is located (floor, if possible room number)",
            mail: {
                subject: "Meldung einer Störung an einem WC", // subject in german on purpose
                body: `Hello,${LINE_BREAK}${LINE_BREAK}I would like to report a malfunction at a WC in the property "{{address}}."${LINE_BREAK}${LINE_BREAK}- Please describe the exact WC location and the malfunction. -`,
            },
        },
        bu: {
            instructions:
                "If you would like to report an issue with this restroom, please use the emergency call box at the station.",
            address: "Station name:",
            locationDetail: "WC-number:",
        },
        generalInformation: {
            instructions:
                "If you would like to report a problem with this toilet, please use the company's radio communication and report the problem to the control center.",
            address: "Station Name:",
        },
        general: {
            info: "If you have noticed a malfunction at this WC, feel free to create a report here.",
            locationDetailLabel: "WC Number:",
            locationDetailValue: "#{{value}}",
            category: "Category",
            categoryLabel: "Category:",
            issue: "Issue",
            contactInformation: "Contact information:",
            reachedReportLimit: `Anonymous only ${REACT_APP_MAX_LIMIT_ISSUE_REPORTS} reports per hour allowed`,
            issuesStillLoading:
                "Establishing connection to the issue reporting system",
            notAdded: "not added",
            issueLabel: "Issue:",
            addPhoto: {
                title: "Add photo (optional)",
                amount: " / 3 photos added",
                notice1:
                    "Please take care that no people, personal identifiable information or license plates are visible in the photo.",
                notice2:
                    "The added images should show the issue clearly. If necessary use the torch on your device.",
                notice3: "You may upload up to 3 images.",
                doNotShowAgain: "Do not show this notice again",
                confirm: "Understood",
                uploadedImage: "Uploaded image",
            },
            additionalNotes: {
                label: "Description of the issue (mandatory)",
                placeholder: "Please describe the present issue in detail",
                requiredText: "Description field must be filled",
            },
            additionalOptionalNotes: {
                label: "Description of the issue (optional)",
            },
            btn: {
                newIssueBtn: "Report new issue",
                submitBtn: "Submit report",
                continue: "Continue",
                abort: "Cancel",
                back: "Back",
                done: "Finished",
            },
            subHeadings: {
                howToReportIssue: "How would you like to report the issue?",
                anonymous: "Anonymous",
                contactDetails: "Add my contact information",
            },
            anonymOrContactInfoText: {
                anonymous: `You can only report <strong>${REACT_APP_MAX_LIMIT_ISSUE_REPORTS} times per hour</strong> an issue, however we cannot contact you for queries.`,
                giveContact:
                    "You can report an issue at any time and we will get back to you if we have any queries. In this way, we can process the issue better.",
            },
            stepTitle: {
                reportIssueTitle: "Report issue",
                addContactDataTitle: "Add contact information",
                chooseCategoryTitle: "Select category",
                chooseIssueTitle: "Select issue",
                additionsTitle: "Add additional information",
                imageUploadNoticeTitle: "Notice to images",
                acknowledgementTitle: "Issue report received",
            },
            alreadyReportedHint: "This issue has already been reported",
        },
    },
    introDialog: {
        title: "Welcome!",
        step1: "Step 1",
        step2: "Step 2",
    },
    permissionInfoDialog: {
        title: "Welcome",
        description:
            "Thank you for using the WC app. To be able to use all functionalities to the full extent, we recommend that you share your location. You can only use the following functions with location sharing:",

        explanation1:
            "With the location function, you can see on the map where you are and which WCs are near you.",
        explanation2:
            "In the WC list you can see the walking time and the distance to the nearest WCs.",
        explanation3:
            "With the navigation function, you can easily be guided to the selected WC and keep your bearings.",
        close: "Understood",
    },
    keyPermissionDialog: {
        title: "Welcome!",
        regards: "Thanks for using the WC app.",
        description1:
            "Some toilets are only accessible with a key. So that we can guide you to the right toilet, please tell us if you have a key.",
        description2: "You can change your selection later in the settings.",
        keyQuestion: "Do you have a key?",
        keyConfirmation: "I have a key",
        ownedKeys: "You have a key for these areas:",
        noOwnedKeys: "- you don't own a key -",
        surfaceKey: "Overground",
        undergroundKey: "Underground",
        btn: {
            confirmKeyBtn: "Yes, I have a key",
            denyKeyBtn: "No, I don't have a key",
            apply: "Apply",
            back: "Back",
        },
        checkBox: {
            surface: {
                header: "For the overground",
                keyTypes: [
                    "Number 1480497 (BO/BS Facilities)",
                    "Number 488HH92 (BU Facilities)",
                    "Number SP69 (WC for women)",
                ],
            },
            underground: {
                header: "For the underground",
                keyType: "Number 8HK8258 (BU Facilities)",
            },
        },
    },
    displayModeDialog: {
        description:
            "Dark mode protects your eyes from bright light. We recommend that you use the system-controlled setting so that the WC App automatically adjusts to the brightness.",
        radioBotton: {
            system: "System-controlled",
            dark: "Dark",
            light: "Light",
        },
    },
    facility: {
        description: {
            building: "WC in BVG facility",
            track: "WC on the route",
            wall: "Wall WC",
            propertyToilets: "Show list of WCs",
            iconAccessibility: "accessible",
            issues: "Show malfunctions",
        },
    },
    propertyToiletsListDialog: {
        title: "Toilets's in the facility",
        floors: {
            kg: "Basement",
            ug: "Underground",
            eg: "Ground floor",
            og: "Upper floor",
        },
        building: "Building",
        roomNumber: "Room",
        type: "Toilet",
    },
    notifications: {
        noGps: {
            title: "No location available",
            message: "Your GPS signal is not available.",
        },
        noNetwork: {
            title: "No Internet connection available",
            message:
                "Your Internet connection is currently unavailable. Please contact the operations control center directly to report toilet malfunctions.",
        },
        noToiletsOnTrack: {
            title: "Error loading toilets",
            message:
                "Toilets on the route cannot be displayed at the moment. Please contact the operations control center directly to report toilet malfunctions.",
        },
        noPropertyToilets: {
            title: "Error loading toilets",
            message:
                "Toilets in BVG properties cannot be displayed at the moment.",
        },
        noIssues: {
            title: "Error loading issue reports",
            message:
                "Issue reports cannot be displayed at the moment. Please contact the operations control center directly to report toilet malfunctions.",
        },
        noIssueReportOptions: {
            title: "Error creating issue reports",
            message:
                "Issue reports cannot be created at the moment. Please contact the operations control center directly to report toilet malfunctions.",
        },
        sendIssueError: {
            title: "Error creating issue report",
            message: "The issue report could not be created.",
        },
        mapError: {
            title: "Error loading the map",
            message: "Some map sections may not be displayed properly.",
        },
        navigationError: {
            title: "Error during navigation",
            message: "Navigation to the destination is currently not possible.",
        },
        general: {
            message:
                "The WC app is not available right now. Please try again later. Please contact the operations control center directly to report toilet malfunctions.",
        },
        invalidImageType: {
            title: "Error during image selection",
            message:
                "At least one of the issues is not a JPEG. You may only selected JPEG files.",
        },
        tooManyImagesSelected: {
            title: "Error during image selection",
            message:
                "You selected too many images. You may select up to 3 images.",
        },
        imageCompressionError: {
            title: "Error during image processing",
            message:
                "An error occurred during image processing. Please select other images or take a new picture.",
        },
    },
};

export default en;
