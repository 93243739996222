import { BvgProperty, Toilet } from "../../api/wcAppSchemas";

export const LIST_ICON_SIZE = 42;
const MAP_MARKER_SIZE = 40;

export type Facility = Toilet | BvgProperty;

export const isToilet = (x: Facility | null): x is Toilet =>
    (x as Toilet).objectNumber !== undefined;

export const isBvgProperty = (x: Facility | null): x is BvgProperty =>
    (x as BvgProperty).toilets !== undefined;

export type FacilityProperties = { isAccessible?: boolean };

export const getFacilityMarkerIconSize = (facility: Facility): number => {
    const iconSize = MAP_MARKER_SIZE;

    if (isBvgProperty(facility)) {
        return iconSize + 16;
    }
    return iconSize;
};
