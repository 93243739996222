import React, { ReactElement } from "react";
import { useIsAuthenticated } from "@azure/msal-react";

type ProtectedRouteProps = {
    children: ReactElement;
};

function ProtectedRoute({ children }: ProtectedRouteProps) {
    const isAuthenticated = useIsAuthenticated();

    if (!isAuthenticated) {
        return <>Loading...</>;
    }

    return children;
}

export default ProtectedRoute;
