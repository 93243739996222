import L from "leaflet";
import { useState, useEffect, useCallback } from "react";
import { DirectionRequestBody } from "../../api/wcAppSchemas";
import { calculateBeeline } from "../distance";
import { currentPositionToLeaflet } from "../map";
import useCurrentPosition from "./useCurrentPosition";
import useGetAccessToken from "./useGetAccessToken";
import { Facility } from "../facility";
import useGetAllFacilities from "./useGetAllFacilities";
import useGetDirectionsMutation from "./useGetDirectionsMutation";

export type FacilityWithDistance = Facility & {
    distance?: number;
    duration?: number | null;
};

const sortFacilityList = (a: FacilityWithDistance, b: FacilityWithDistance) => {
    const sortByAddress = a.address < b.address ? 1 : 0;

    // If distance is not set, sort by address
    if (a.distance === undefined || b.distance === undefined) {
        return sortByAddress;
    }

    // Sort by distance. If distance is the same, sort by address
    return a.distance - b.distance || sortByAddress;
};

export const useFacilitiesWithDistance = (): {
    isLoading: boolean;
    facilitiesWithDistance: FacilityWithDistance[];
} => {
    const { data: allFacilities, isLoading } = useGetAllFacilities();
    const { getDirectionsAsync } = useGetDirectionsMutation();
    const accessToken = useGetAccessToken();
    const currentPosition = useCurrentPosition({ minChangeRadius: 100 });
    const [facilitiesWithDistance, setFacilitiesWithDistance] = useState<
        FacilityWithDistance[]
    >([]);

    const fetchFootpathDistances = useCallback(
        (
            facilitiesToFetch: FacilityWithDistance[],
            position: L.LatLngExpression
        ) => {
            if (accessToken) {
                const fetchDirectionsPromises = facilitiesToFetch.map(
                    (facilityToFetch: FacilityWithDistance) => {
                        const directionsParams: DirectionRequestBody = {
                            coordinates: [
                                [
                                    L.latLng(position).lng,
                                    L.latLng(position).lat,
                                ],
                                [
                                    facilityToFetch.geolocation.coordinates[0],
                                    facilityToFetch.geolocation.coordinates[1],
                                ],
                            ],
                            preference: "shortest",
                            instructions: false,
                        };
                        return getDirectionsAsync({
                            headers: { Authorization: `Bearer ${accessToken}` },
                            body: { coordinates: directionsParams.coordinates },
                        });
                    }
                );
                Promise.allSettled(fetchDirectionsPromises).then(
                    (directionsResponses) => {
                        setFacilitiesWithDistance(
                            facilitiesToFetch
                                .map((facility, i) => {
                                    const facilityWithDistance = facility;

                                    const directionsResponse =
                                        directionsResponses[i];

                                    if (
                                        directionsResponse.status ===
                                        "fulfilled"
                                    ) {
                                        facilityWithDistance.distance =
                                            Math.trunc(
                                                directionsResponse.value
                                                    .features[0].properties
                                                    .summary.distance
                                            );
                                        facilityWithDistance.duration =
                                            Math.trunc(
                                                directionsResponse.value
                                                    .features[0].properties
                                                    .summary.duration
                                            );
                                    }
                                    return facilityWithDistance;
                                })
                                .sort(sortFacilityList)
                        );
                    }
                );
            }
        },
        [getDirectionsAsync, accessToken]
    );

    useEffect(() => {
        const location = currentPositionToLeaflet(currentPosition);
        if (allFacilities) {
            if (location) {
                const filteredFacilities = allFacilities
                    .map((v) => ({
                        ...v,
                        distance: calculateBeeline(v, location),
                        duration: null,
                    }))
                    .sort(sortFacilityList)
                    .slice(0, 10);
                setFacilitiesWithDistance(filteredFacilities);
                fetchFootpathDistances(filteredFacilities, location);
            } else {
                // sort alphabetically by stations and facility names
                const getStringToCompare = (facility: Facility) => {
                    if ("station" in facility) {
                        return facility.station;
                    }
                    if ("name" in facility) {
                        return facility.name;
                    }
                    return "";
                };
                const sortedFacilities = [...allFacilities].sort((a, b) => {
                    const aStr = getStringToCompare(a);
                    const bStr = getStringToCompare(b);
                    return aStr.localeCompare(bStr);
                });

                setFacilitiesWithDistance(sortedFacilities);
            }
        }
    }, [allFacilities, currentPosition, fetchFootpathDistances]);

    return { isLoading, facilitiesWithDistance };
};
