import { createStyles } from "@mantine/core";

const useIssueReportStyles = createStyles((theme) => ({
    content: {
        marginBottom: "8px",
        backgroundColor:
            theme.colorScheme === "dark"
                ? theme.colors.dark[7]
                : theme.colors.light[0],
    },
    text: { fontSize: theme.fontSizes.md, lineHeight: "120%" },
    importantInformation: {
        fontSize: theme.fontSizes.md,
        border: `solid 2px ${
            theme.colorScheme === "dark"
                ? theme.colors.light[0]
                : theme.colors.dark[7]
        }`,
        borderRadius: "4px",
        padding: "8px",
    },
    boldText: {
        fontWeight: "bold",
    },
}));

export type IssueReportStylesClasses = ReturnType<
    typeof useIssueReportStyles
>["classes"];

export default useIssueReportStyles;
