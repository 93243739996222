import { useEffect, useMemo } from "react";
import { notifications } from "@mantine/notifications";
import useIntroductionStore from "../../../stores/introduction.store";
import { useGetProperties, useGetToilets } from "../../api/wcAppComponents";
import { Facility } from "../facility";
import {
    NO_PROPERTY_TOILETS_NOTIFICATION,
    NO_TOILETS_ON_TRACK_NOTIFICATION,
} from "../notifications";
import useNotificationStore from "../../../stores/notification.store";

const STALE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
const CACHE_TIME = 6 * 60 * 1000; // 6 minutes in milliseconds. Larger than staleTime to avoid visible re-loadings.

const useGetAllFacilities = (): {
    isLoading: boolean;
    data: Facility[];
} => {
    const getKeyIdsFilter = useIntroductionStore(
        (state) => state.getKeyIdsFilter
    );

    const {
        data: toilets,
        isLoading: areToiletsLoading,
        isError: isToiletsError,
    } = useGetToilets(
        {
            queryParams: { keyIds: getKeyIdsFilter() },
        },
        { staleTime: STALE_TIME, cacheTime: CACHE_TIME }
    );

    const {
        data: properties,
        isLoading: arePropertiesLoading,
        isError: isPropertyError,
    } = useGetProperties({}, { staleTime: STALE_TIME, cacheTime: CACHE_TIME });

    const wasNoToiletsOnTrackNotificationShown = useNotificationStore(
        (state) => state.wasNoToiletsOnTrackNotificationShown
    );
    const setWasNoToiletsOnTrackNotificationShown = useNotificationStore(
        (state) => state.setWasNoToiletsOnTrackNotificationShown
    );
    const wasNoPropertyToiletsNotificationShown = useNotificationStore(
        (state) => state.wasNoPropertyToiletsNotificationShown
    );
    const setWasNoPropertyToiletsNotificationShown = useNotificationStore(
        (state) => state.setWasNoPropertyToiletsNotificationShown
    );

    useEffect(() => {
        if (!areToiletsLoading) {
            if (!wasNoToiletsOnTrackNotificationShown && isToiletsError) {
                notifications.show(NO_TOILETS_ON_TRACK_NOTIFICATION);
                setWasNoToiletsOnTrackNotificationShown(true);
            } else if (!isToiletsError) {
                // request was now successful, close notification manually
                notifications.hide(NO_TOILETS_ON_TRACK_NOTIFICATION.id);
                setWasNoToiletsOnTrackNotificationShown(false);
            }
        }
    }, [
        areToiletsLoading,
        isToiletsError,
        setWasNoToiletsOnTrackNotificationShown,
        wasNoToiletsOnTrackNotificationShown,
    ]);

    useEffect(() => {
        if (!arePropertiesLoading) {
            if (!wasNoPropertyToiletsNotificationShown && isPropertyError) {
                notifications.show(NO_PROPERTY_TOILETS_NOTIFICATION);
                setWasNoPropertyToiletsNotificationShown(true);
            } else if (!isPropertyError) {
                // request was now successful, close notification manually
                notifications.hide(NO_PROPERTY_TOILETS_NOTIFICATION.id);
                setWasNoPropertyToiletsNotificationShown(false);
            }
        }
    }, [
        arePropertiesLoading,
        isPropertyError,
        setWasNoPropertyToiletsNotificationShown,
        wasNoPropertyToiletsNotificationShown,
    ]);

    const allFacilities: Facility[] = useMemo(() => {
        const data: Facility[] = [];
        if (toilets) {
            data.push(...toilets);
        }
        if (properties) {
            data.push(...properties);
        }
        return data;
    }, [toilets, properties]);

    return {
        isLoading: areToiletsLoading && arePropertiesLoading, // use AND here, because when we have something, we want to display it
        data: allFacilities,
    };
};

export default useGetAllFacilities;
