import { Button, Group, Stack, Text } from "@mantine/core";
import React, { ReactElement } from "react";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import mailtoLink from "mailto-link";

import { useHover } from "@mantine/hooks";
import { useAccount } from "@azure/msal-react";
import { IconMailLine, IconMailSolid } from "../../Icons/Icons";

import useIssueReportStyles from "./Feedback.styles";
import reportAnimation from "../../../assets/animations/feedback.json";
import { EMAIL_FEEDBACK } from "../../../constants/map";

const ICON_SIZE = 18;
function Feedback(): ReactElement {
    const { classes } = useIssueReportStyles();
    const { t } = useTranslation();
    const account = useAccount();
    const navigate = useNavigate();
    const { hovered, ref } = useHover();

    const SUBJECT = t("feedbackMail.subject");
    const BODY = t("feedbackMail.body", {
        customerName: account?.name,
    });
    const mailtoHref = mailtoLink({
        to: EMAIL_FEEDBACK,
        subject: SUBJECT,
        body: BODY,
    });

    return (
        <Stack className={classes.content} spacing={0} align="center">
            <Lottie
                style={{ maxWidth: 200 }}
                animationData={reportAnimation}
                loop={false}
            />
            <Group spacing={16}>
                <Text className={classes.contentText}>
                    {t("pages.feedback.acknowledgement")}
                    <p>{t("pages.feedback.instructions")}</p>
                    <p>{t("pages.feedback.privacyHint")}</p>
                    <p>{t("pages.feedback.finalAcknowledgement")}</p>
                </Text>
            </Group>

            <Group position="right" className={classes.footer}>
                <Button onClick={() => navigate("/")} variant="outline">
                    {t("close")}
                </Button>

                <div ref={ref}>
                    <Button
                        component="a"
                        href={mailtoHref}
                        leftIcon={
                            hovered ? (
                                <IconMailSolid size={ICON_SIZE} />
                            ) : (
                                <IconMailLine size={ICON_SIZE} />
                            )
                        }
                    >
                        {t("sendEmail")}
                    </Button>
                </div>
            </Group>
        </Stack>
    );
}

export default Feedback;
