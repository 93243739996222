import React, { ReactElement } from "react";
import { Polyline } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import { useMantineTheme } from "@mantine/core";

import { swapCoordinates } from "../../utilities/client/map";
import useFacilityStore from "../../stores/facility.store";

type MapRoutePathProps = {
    color?: string | null;
    thickness?: number;
    dashed?: boolean;
};

function MapRoutePath({
    color,
    thickness = 4,
    dashed = false,
}: MapRoutePathProps): ReactElement {
    const theme = useMantineTheme();
    const selectedFacilityPathCoordinates = useFacilityStore(
        (state) => state.selectedFacilityPathCoordinates
    );
    return (
        <Polyline
            positions={swapCoordinates(
                selectedFacilityPathCoordinates as unknown as LatLngTuple[]
            )}
            pathOptions={{
                color: color || theme.colors.bvg[7],
                dashArray: dashed ? [2, 8] : [1],
                weight: thickness,
            }}
        />
    );
}

export default MapRoutePath;
