import React, { ReactElement } from "react";
import {
    Button,
    Group,
    Tooltip,
    Stack,
    Text,
    Box,
    Skeleton,
    Divider,
    Center,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { ReportForm, stepIndexHandlerType } from "../GeneralReport";
import BottomSheetHeader from "../../../../../BottomSheetHeader/BottomSheetHeader";
import BottomSheetFooter from "../../../../../BottomSheetFooter/BottomSheetFooter";
import IssueList from "../../../../../IssueList/IssueList";
import useGetToiletIssues from "../../../../../../utilities/client/hooks/useGetToiletIssues";
import reportAnimation from "../../../../../../assets/animations/report.json";

type Step1Props = {
    isIssueListOpen?: boolean;
    form: ReportForm;
    stepIndexHandler: stepIndexHandlerType;
    abort: () => void;
};

function Step1({
    isIssueListOpen = false,
    form,
    stepIndexHandler,
    abort,
}: Step1Props): ReactElement {
    const { t } = useTranslation();
    const { data: openIssues, isLoading } = useGetToiletIssues(
        form.values.toiletSapEqNumber
    );

    let issueListContent;
    if (openIssues && openIssues.length > 0) {
        issueListContent = (
            <IssueList issues={openIssues} isOpenInitially={isIssueListOpen} />
        );
    } else if (isLoading) {
        issueListContent = (
            <Stack justify="flex-start" spacing="xs" mt={16} mb={32}>
                <Skeleton height={16} />
                <Divider mt={8} />
            </Stack>
        );
    } else {
        issueListContent = (
            <Center mt="xl">
                <Lottie
                    style={{ maxWidth: 200 }}
                    animationData={reportAnimation}
                    loop={false}
                />
            </Center>
        );
    }

    return (
        <>
            <BottomSheetHeader
                title={t("issueReport.general.stepTitle.reportIssueTitle")}
            />
            <Stack align="stretch" px={8} spacing={0}>
                <Group spacing={0} mt={16}>
                    <Text weight="bold" mr={4}>
                        {t("issueReport.general.locationDetailLabel")}
                    </Text>
                    <Text>
                        {t("issueReport.general.locationDetailValue", {
                            value: form.values.toiletObjectNumber,
                        })}
                    </Text>
                </Group>
                {issueListContent}
                <Text ta="left" mb="sm" mx="md">
                    {t("issueReport.general.info")}
                </Text>
            </Stack>
            <BottomSheetFooter>
                <Stack spacing={8}>
                    {isLoading ? (
                        <Tooltip
                            label={t("issueReport.general.issuesStillLoading")}
                            color="black"
                            withArrow
                            offset={-12}
                            position="bottom"
                            opened
                        >
                            <Box component="div">
                                <Button w="100%" disabled>
                                    {t("issueReport.general.btn.newIssueBtn")}
                                </Button>
                            </Box>
                        </Tooltip>
                    ) : (
                        <Button onClick={stepIndexHandler.increment}>
                            {t("issueReport.general.btn.newIssueBtn")}
                        </Button>
                    )}
                    <Button onClick={abort} variant="outline">
                        {t("issueReport.general.btn.abort")}
                    </Button>
                </Stack>
            </BottomSheetFooter>
        </>
    );
}

export default Step1;
