import { useEffect } from "react";
import { notifications } from "@mantine/notifications";
import {
    UseMutateAsyncFunction,
    UseMutateFunction,
} from "@tanstack/react-query";
import {
    GetDirectionsError,
    GetDirectionsVariables,
    useGetDirections,
} from "../../api/wcAppComponents";
import { NAVIGATION_ERROR_NOTIFICATION } from "../notifications";
import { Direction } from "../../api/wcAppSchemas";
import useNotificationStore from "../../../stores/notification.store";

const useGetDirectionsMutation = (): {
    directions?: Direction;
    getDirections: UseMutateFunction<
        Direction,
        GetDirectionsError,
        GetDirectionsVariables,
        unknown
    >;
    getDirectionsAsync: UseMutateAsyncFunction<
        Direction,
        GetDirectionsError,
        GetDirectionsVariables,
        unknown
    >;
} => {
    const {
        data: directions,
        mutate: getDirections,
        mutateAsync: getDirectionsAsync,
        isLoading,
        isError,
    } = useGetDirections();

    const wasNavigationErrorNotificationShown = useNotificationStore(
        (state) => state.wasNavigationErrorNotificationShown
    );
    const setWasNavigationErrorNotificationShown = useNotificationStore(
        (state) => state.setWasNavigationErrorNotificationShown
    );
    useEffect(() => {
        if (!isLoading) {
            if (!wasNavigationErrorNotificationShown && isError) {
                notifications.show(NAVIGATION_ERROR_NOTIFICATION);
                setWasNavigationErrorNotificationShown(true);
            } else if (!isError) {
                // request was now successful, close notification manually
                notifications.hide(NAVIGATION_ERROR_NOTIFICATION.id);
                setWasNavigationErrorNotificationShown(false);
            }
        }
    }, [
        isError,
        isLoading,
        setWasNavigationErrorNotificationShown,
        wasNavigationErrorNotificationShown,
    ]);

    return {
        directions,
        getDirections,
        getDirectionsAsync,
    };
};

export default useGetDirectionsMutation;
