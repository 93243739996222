import React, { ReactElement, useState } from "react";
import { Group, Text, UnstyledButton, Tooltip } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IconBellLine } from "../../../Icons/Icons";
import useUserIssueReportsStore from "../../../../stores/userIssueReports.store";
import { TOOLTIP_DISPLAY_TIME } from "../../../../constants/map";
import { Issue } from "../../../../utilities/api/wcAppSchemas";

const BOOKMARK_ICON_SIZE = 18;

type BookmarkIssueButtonProps = {
    issueEntry: Issue;
};

function BookmarkIssueButton({
    issueEntry,
}: BookmarkIssueButtonProps): ReactElement | null {
    const { t } = useTranslation();

    const [isBookmarkedTooltipOpen, setIsBookmarkedTooltipOpen] =
        useState(false);
    const userIssues = useUserIssueReportsStore((state) => state.userIssues);
    const addUserIssue = useUserIssueReportsStore(
        (state) => state.addUserIssue
    );

    const isIssueBookmarked = userIssues.some(
        (issue) => issue.id === issueEntry.id
    );
    const bookmarkIssueColor = isIssueBookmarked ? "grey" : "black";
    const bookmarkIssueIcon = React.createElement(IconBellLine, {
        size: BOOKMARK_ICON_SIZE,
        color: bookmarkIssueColor,
    });

    const bookmarkIssue = () => {
        if (isIssueBookmarked) {
            setIsBookmarkedTooltipOpen(true);
            setTimeout(() => {
                setIsBookmarkedTooltipOpen(false);
            }, TOOLTIP_DISPLAY_TIME);
        } else {
            addUserIssue(issueEntry);
        }
    };

    return (
        <Tooltip
            label={t("issueList.bookmarkIssue.tooltip")}
            color="black"
            withArrow
            offset={-2}
            position="bottom"
            opened={isBookmarkedTooltipOpen}
        >
            <UnstyledButton onClick={bookmarkIssue}>
                <Group position="right" spacing="0">
                    {bookmarkIssueIcon}
                    <Text
                        size="sm"
                        ml={4}
                        sx={{
                            color: bookmarkIssueColor,
                            textDecoration: "underline",
                        }}
                        weight="bold"
                    >
                        {t("issueList.bookmarkIssue.action")}
                    </Text>
                </Group>
            </UnstyledButton>
        </Tooltip>
    );
}

export default BookmarkIssueButton;
