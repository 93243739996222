import { createStyles } from "@mantine/core";

const useMapControlsStyles = createStyles((theme) => ({
    icon: {
        height: "34px",
        strokeWidth: 1,
        width: "34px",
    },
    button: {
        backgroundColor:
            theme.colorScheme === "dark"
                ? theme.colors.dark[7]
                : theme.colors.light[0],
        border: "0px",
        borderRadius: "50%",
        lineHeight: 0,
        padding: 10,
        cursor: "pointer",
        pointerEvents: "auto",
        marginBottom: 10,
        boxShadow: `rgb(0 0 0 / 16%) 0px 3px 6px`,
    },
    mapListToggle: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "end",
        width: "100%",
    },
    root: {
        pointerEvents: "none",
        alignContent: "center",
        display: "flex",
        top: 100,
        right: 0,
        zIndex: theme.other.zIndex.leaflet,
        position: "fixed",
        border: "none",
        margin: "0 8px",
    },
}));

export default useMapControlsStyles;
