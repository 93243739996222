import React, { Component, ReactNode } from "react";
import GlobalErrorNotification from "./GlobalErrorNotification";

type Props = {
    children?: ReactNode;
};

type State = {
    hasError: boolean;
};

class GlobalErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getDerivedStateFromError(_error: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public render() {
        const { hasError } = this.state;
        if (hasError) {
            return <GlobalErrorNotification />;
        }

        const { children } = this.props;
        return children;
    }
}

export default GlobalErrorBoundary;
