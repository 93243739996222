import { Container, Radio, Text, Stack } from "@mantine/core";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
    useDisplayModeStore,
    DisplayModeSettings,
} from "../../../stores/displayModeSettings.store";
import useDisplayModeStyles from "./displayModeDialog.style";

function DisplayModeDialog(): ReactElement {
    const setDisplayModeSettings = useDisplayModeStore(
        (state) => state.setDisplayModeSettings
    );
    const displayModeSetting = useDisplayModeStore(
        (state) => state.displayModeSetting
    );

    const { t } = useTranslation();
    const { classes } = useDisplayModeStyles();

    return (
        <Container size={700}>
            <Text>{t("displayModeDialog.description")}</Text>
            <Radio.Group
                name="displayModes"
                value={displayModeSetting}
                onChange={setDisplayModeSettings}
                style={{ marginTop: "1rem" }}
            >
                <Stack spacing="sm">
                    <Radio
                        value={DisplayModeSettings.system}
                        label={t("displayModeDialog.radioBotton.system")}
                        classNames={{
                            label: classes.label,
                            body: classes.body,
                        }}
                        labelPosition="left"
                    />
                    <Radio
                        value={DisplayModeSettings.dark}
                        label={t("displayModeDialog.radioBotton.dark")}
                        classNames={{
                            label: classes.label,
                            body: classes.body,
                        }}
                        labelPosition="left"
                    />
                    <Radio
                        value={DisplayModeSettings.light}
                        label={t("displayModeDialog.radioBotton.light")}
                        classNames={{
                            label: classes.label,
                            body: classes.body,
                        }}
                        labelPosition="left"
                    />
                </Stack>
            </Radio.Group>
        </Container>
    );
}

export default DisplayModeDialog;
