import React, { ReactElement } from "react";
import {
    Text,
    Checkbox,
    Box,
    Button,
    Stack,
    Grid,
    useMantineTheme,
    Avatar,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import BottomSheetFooter from "../BottomSheetFooter/BottomSheetFooter";
import BottomSheetHeader from "../BottomSheetHeader/BottomSheetHeader";
import { IconAlertCircleLine } from "../Icons/Icons";
import useImageUploadNoticeStore from "../../stores/imageUploadNotice.store";
import useImageUploadNoticeStyles from "./ImageUploadNotice.styles";

type ImageUploadNoticeProps = {
    onAcknowledge: () => void;
};

function ImageUploadNotice({
    onAcknowledge: acknowledge,
}: ImageUploadNoticeProps): ReactElement {
    const theme = useMantineTheme();
    const { classes } = useImageUploadNoticeStyles();
    const { t } = useTranslation();

    const doNotShowAgain = useImageUploadNoticeStore(
        (state) => state.doNotShowAgain
    );
    const setDoNotShowAgain = useImageUploadNoticeStore(
        (state) => state.setDoNotShowAgain
    );

    const icon = (
        <Avatar className={classes.infoIconBox} radius="sm">
            <IconAlertCircleLine color={theme.colors.bvg[8]} size={28} />
        </Avatar>
    );

    return (
        <>
            <BottomSheetHeader
                title={t(
                    "issueReport.general.stepTitle.imageUploadNoticeTitle"
                )}
            />
            <Stack px={8}>
                <Grid align="center" mt={16}>
                    <Grid.Col span={2}>{icon}</Grid.Col>
                    <Grid.Col span="auto">
                        <Text className={classes.text}>
                            {t("issueReport.general.addPhoto.notice1")}
                        </Text>
                    </Grid.Col>
                </Grid>
                <Grid align="center">
                    <Grid.Col span={2}>{icon}</Grid.Col>
                    <Grid.Col span="auto">
                        <Text className={classes.text}>
                            {t("issueReport.general.addPhoto.notice2")}
                        </Text>
                    </Grid.Col>
                </Grid>
                <Grid align="center">
                    <Grid.Col span={2}>{icon}</Grid.Col>
                    <Grid.Col span="auto">
                        <Text className={classes.text}>
                            {t("issueReport.general.addPhoto.notice3")}
                        </Text>
                    </Grid.Col>
                </Grid>
                <Checkbox
                    mt={8}
                    pl={8}
                    label={t("issueReport.general.addPhoto.doNotShowAgain")}
                    checked={doNotShowAgain}
                    onChange={(event) =>
                        setDoNotShowAgain(event.currentTarget.checked)
                    }
                />
            </Stack>

            <BottomSheetFooter>
                <Box component="div">
                    <Button w="100%" onClick={acknowledge}>
                        {t("issueReport.general.addPhoto.confirm")}
                    </Button>
                </Box>
            </BottomSheetFooter>
        </>
    );
}

export default ImageUploadNotice;
