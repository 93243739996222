import { createStyles, keyframes } from "@mantine/core";

// Export animation to reuse it in other components
export const pulse = keyframes({
    "0%": {
        transform: "scale(0)",
        opacity: "0",
    },
    "50%": {
        opacity: "1",
    },
    "100%": {
        transform: "scale(1)",
        opacity: "0",
    },
});

type useCurrentPositionMarkerStylesProps = {
    heading?: number | null;
    cr: number;
};

const useCurrentPositionMarkerStyles = createStyles(
    (_theme, { heading = 0, cr }: useCurrentPositionMarkerStylesProps) => ({
        marker: {
            fill: "#f0d722",
        },
        bgCircle: {
            fill: "#fff",
            opacity: ".54",
        },
        pulseAnimation: {
            animation: `${pulse} 4s ease-in-out infinite`,
            fill: "lightblue",
            transformOrigin: `${cr}px ${cr}px`,
        },
        svg: {
            transform: `rotateZ(${heading}deg)`,
        },
        tip: {
            fill: "#000",
        },
    })
);

export default useCurrentPositionMarkerStyles;
