import { createStyles } from "@mantine/core";

const useAccordionComponentStyles = createStyles(() => ({
    image: {
        marginTop: "8px",
        marginBottom: "16px",
        border: "2px solid black",
        borderRadius: "4px",
    },

    chevron: {},

    listItem: {
        width: "270px",
        marginRight: "24px",
    },
}));

export default useAccordionComponentStyles;
