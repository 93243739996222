import React, { ReactElement } from "react";
import {
    ActionIcon,
    Box,
    Divider,
    Group,
    Navbar as MantineNavbar,
    Overlay,
    Text,
    ScrollArea,
    Stack,
    Transition,
    useMantineTheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useClickOutside, useId } from "@mantine/hooks";

import {
    IconChevronRightLine as IconChevronRight,
    IconChevronLeftLine as IconChevronLeft,
} from "../Icons/Icons";
import useNavbarStyles from "./Navbar.styles";
import NavbarLinkList from "./NavbarLinkList/NavbarLinkList";
import UserButton from "../UserButton/UserButton";

type NavbarProps = {
    opened: boolean;
    toggleMenu: () => void;
};

function Navbar({ opened, toggleMenu }: NavbarProps): ReactElement {
    const { classes, cx } = useNavbarStyles();
    const { t } = useTranslation();
    const { PUBLIC_URL } = process.env;
    const menuRef = useClickOutside(() => opened && toggleMenu());
    const theme = useMantineTheme();
    const id = useId();
    const overlayFadeIn = {
        in: { opacity: 0.6 },
        out: { opacity: 0 },
        transitionProperty: "opacity",
    };

    return (
        <div id={`wc-app-navbar-${id}`}>
            <Transition
                duration={200}
                mounted={opened}
                transition={overlayFadeIn}
            >
                {(styles) => (
                    <Overlay
                        color="#000"
                        style={styles}
                        zIndex={theme.other.zIndex.navbar}
                    />
                )}
            </Transition>
            <MantineNavbar
                classNames={{
                    root: cx(classes.root, { [classes.rootHidden]: !opened }),
                }}
                ref={menuRef}
            >
                <MantineNavbar.Section>
                    <Box
                        sx={(themeLocal) => ({
                            paddingLeft: themeLocal.spacing.xs,
                            paddingRight: themeLocal.spacing.xs,
                        })}
                    >
                        <Group position="apart">
                            <div className={classes.header}>
                                <img
                                    alt="WC-App-Logo"
                                    className="logo"
                                    src={`${PUBLIC_URL}/app-logo.svg`}
                                />
                                <h1 className="heading">
                                    <Text>WC-App</Text>
                                </h1>
                            </div>
                            <ActionIcon
                                variant="transparent"
                                onClick={() => toggleMenu()}
                                size={42}
                            >
                                {opened ? (
                                    <IconChevronLeft size={24} />
                                ) : (
                                    <IconChevronRight size={24} />
                                )}
                            </ActionIcon>
                        </Group>
                    </Box>
                </MantineNavbar.Section>
                <MantineNavbar.Section>
                    <Box
                        sx={(themeLocal) => ({
                            paddingLeft: themeLocal.spacing.xs,
                            paddingRight: themeLocal.spacing.xs,
                            borderBottom: `1px solid ${
                                themeLocal.colorScheme === "dark"
                                    ? themeLocal.colors.dark[4]
                                    : themeLocal.colors.gray[2]
                            }`,
                            borderTop: `1px solid ${
                                themeLocal.colorScheme === "dark"
                                    ? themeLocal.colors.dark[4]
                                    : themeLocal.colors.gray[2]
                            }`,
                        })}
                    >
                        <UserButton />
                    </Box>
                </MantineNavbar.Section>
                <MantineNavbar.Section
                    grow
                    component={ScrollArea}
                    mx="-xs"
                    px="xs"
                    className={classes.linkListWrapper}
                >
                    <NavbarLinkList />
                </MantineNavbar.Section>
                <Divider />
                <MantineNavbar.Section>
                    <Stack className={classes.stack}>
                        <Text size="sm">
                            {" "}
                            {t("navbar.version")}{" "}
                            {process.env.REACT_APP_FRONTEND_VERSION}
                            {process.env.REACT_APP_STAGE_NAME_SUFFIX}
                        </Text>
                    </Stack>
                </MantineNavbar.Section>
            </MantineNavbar>
        </div>
    );
}

export default Navbar;
