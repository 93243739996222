import { useState, useCallback, useEffect } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export default () => {
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
        null
    );
    const [showReload, setShowReload] = useState<boolean>(false);
    // Called when a service worker updates. This function is a callback to the
    // actual service worker registration onUpdate.
    const onSwUpdate = useCallback(
        (registration: ServiceWorkerRegistration) => {
            setShowReload(true);
            setWaitingWorker(registration.waiting);
        },
        []
    );

    const onSwWaiting = useCallback(
        (registration: ServiceWorkerRegistration) => {
            setShowReload(true);
            setWaitingWorker(registration.waiting);
        },
        []
    );

    // This tells the service worker to skip the waiting phase and reloads the page
    const reloadPage = useCallback(() => {
        waitingWorker?.postMessage({ type: "SKIP_WAITING" });
        setShowReload(false);
        window.location.reload();
    }, [waitingWorker]);
    // Register the service worker
    useEffect(() => {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://cra.link/PWA
        serviceWorkerRegistration.register({
            onUpdate: onSwUpdate,
            onWaiting: onSwWaiting,
        });
    }, [onSwUpdate, onSwWaiting]);
    return { showReload, waitingWorker, reloadPage };
};
