import { createStyles } from "@mantine/core";
import { isMobile } from "react-device-detect";

const headerDistanceTop = 10;
const useHeaderStyles = createStyles((theme) => ({
    button: {
        backgroundColor:
            theme.colorScheme === "dark"
                ? theme.colors.dark[7]
                : theme.colors.light[0],
        border: "0px",
        lineHeight: 0,
        padding: 0,
        width: "34px",

        "&:active": {
            backgroundColor:
                theme.colorScheme === "dark"
                    ? theme.colors.dark[6]
                    : theme.colors.dark[0],
        },
    },
    mapListToggle: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "end",
        width: "100%",
    },
    fadeOut: {
        opacity: 0,
        transition: "0.3s",
        transform: "translateY(-10px) translateX(-50%)",
    },
    fadeIn: {
        opacity: 1,
        transition: "0.3s",
        transform: `translateY(${headerDistanceTop}px) translateX(-50%)`,
    },
    root: {
        alignContent: "center",
        display: "grid",
        padding: "0 1rem",
        top: isMobile ? "env(safe-area-inset-top)" : headerDistanceTop,
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: 550,
        minWidth: 250,
        zIndex: theme.other.zIndex.header,
        position: "fixed",
        borderRadius: 10,
        border: "none",
        boxShadow: "  4px 1px 15px rgba(0, 0, 0, 0.07)",
    },
}));

export default useHeaderStyles;
