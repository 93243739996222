import { createStyles } from "@mantine/core";

const useCollapsibleStyles = createStyles((theme) => {
    return {
        divider: {
            margin: 4,
        },
        wrapperCard: {
            border:
                theme.colorScheme === "dark"
                    ? `1px solid ${theme.colors.dark[0]}`
                    : `1px solid ${theme.colors.dark[9]}`,
            borderRadius: "12px",
            marginBottom: "10px",
            padding: 8,

            "&:active": {
                backgroundColor:
                    theme.colorScheme === "dark"
                        ? theme.colors.dark[6]
                        : theme.colors.dark[0],
            },
        },
        selectedWrapperCard: {
            border: "2px solid #f8e688",
            borderRadius: "12px",
            margin: 0,
            marginBottom: "10px",
            padding: 8,
            "&:active": {
                backgroundColor:
                    theme.colorScheme === "dark"
                        ? theme.colors.dark[6]
                        : theme.colors.dark[0],
            },
        },
        collapseButtonContainer: {
            height: "100%",
            fill:
                theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors.dark[9],
        },
        collapseButton: {
            marginRight: 4,
        },
    };
});

export default useCollapsibleStyles;
