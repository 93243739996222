import { createStyles } from "@mantine/core";

const useKeyPermissionDialogStyles = createStyles((theme) => ({
    dialog: {
        paddingTop: "0px",
        maxWidth: "700px",
        margin: "0 auto",
    },
    title: {
        alignContent: "center",
        fontWeight: "bold",
        fontSize: theme.fontSizes.lg,
    },
    checkboxGroup: {
        marginTop: "-16px",
        "& label": {
            display: "inline-block",
            marginLeft: "8px",
        },
    },
    checkboxLabel: {
        width: "240px",
    },
    checkboxBody: {
        alignItems: "center",
    },
    keyDetailsList: {
        listStyleType: "none",
        padding: 0,
        margin: 0,
    },
    keyDetailsListElement: {
        marginLeft: "8px",
    },
}));

export default useKeyPermissionDialogStyles;
