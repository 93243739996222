import React, { ReactElement, useEffect, useState } from "react";
import L, { LatLngExpression } from "leaflet";
import { DirectionRequestBody } from "../../utilities/api/wcAppSchemas";
import Beeline from "../Beeline/Beeline";
import HumanReadableDistance from "../HumanReadableDistance/HumanReadableDistance";
import useGetAccessToken from "../../utilities/client/hooks/useGetAccessToken";
import useGetDirectionsMutation from "../../utilities/client/hooks/useGetDirectionsMutation";

type FootpathDistanceProps = {
    from: LatLngExpression;
    to: LatLngExpression;
};

function FootpathDistance({ from, to }: FootpathDistanceProps): ReactElement {
    const { directions, getDirections } = useGetDirectionsMutation();
    const accessToken = useGetAccessToken();
    const [distance, setDistance] = useState(0);
    const [duration, setDuration] = useState<number | null>(null);

    // Trigger POST request to /directions endpoint (on first render only!)
    useEffect(() => {
        if (accessToken) {
            const fromPoint = L.latLng(from);
            const toPoint = L.latLng(to);
            const directionsParams: DirectionRequestBody = {
                coordinates: [
                    [fromPoint.lng, fromPoint.lat],
                    [toPoint.lng, toPoint.lat],
                ],
                preference: "shortest",
                instructions: false,
            };
            getDirections({
                headers: { Authorization: `Bearer ${accessToken}` },
                body: { coordinates: directionsParams.coordinates },
            });
        }
    }, [from, getDirections, to, accessToken]);

    const newDistance = directions?.features[0].properties.summary.distance;
    const newDuration = directions?.features[0].properties.summary.duration;
    useEffect(() => {
        if (newDistance) {
            setDistance(newDistance);
        }
        if (newDuration) {
            setDuration(newDuration);
        }
    }, [newDistance, newDuration]);

    return distance === 0 ? (
        <Beeline from={from} to={to} />
    ) : (
        <HumanReadableDistance distance={distance} duration={duration} />
    );
}

export default FootpathDistance;
