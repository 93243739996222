// This file is generated - DO NOT EDIT!
// Change ./frontend/src/utilities/icons/generate.js and run "npm run icons:generate"

import React from "react";
import SvgIcon from "../SvgIcon/SvgIcon";
import { ReactComponent as SvgAccessibilityLine } from "../../assets/ui-icons/accessibility-line.svg";
import { ReactComponent as SvgAddLine } from "../../assets/ui-icons/add-line.svg";
import { ReactComponent as SvgAlertCircleLine } from "../../assets/ui-icons/alert-circle-line.svg";
import { ReactComponent as SvgAlertLine } from "../../assets/ui-icons/alert-line.svg";
import { ReactComponent as SvgAlertSolid } from "../../assets/ui-icons/alert-solid.svg";
import { ReactComponent as SvgBellLine } from "../../assets/ui-icons/bell-line.svg";
import { ReactComponent as SvgBvgPropertySolid } from "../../assets/ui-icons/bvg-property-solid.svg";
import { ReactComponent as SvgCategoryLine } from "../../assets/ui-icons/category-line.svg";
import { ReactComponent as SvgChevronDownLine } from "../../assets/ui-icons/chevron-down-line.svg";
import { ReactComponent as SvgChevronLeftLine } from "../../assets/ui-icons/chevron-left-line.svg";
import { ReactComponent as SvgChevronRightLine } from "../../assets/ui-icons/chevron-right-line.svg";
import { ReactComponent as SvgChevronUpLine } from "../../assets/ui-icons/chevron-up-line.svg";
import { ReactComponent as SvgCrossLine } from "../../assets/ui-icons/cross-line.svg";
import { ReactComponent as SvgDiversLine } from "../../assets/ui-icons/divers-line.svg";
import { ReactComponent as SvgFeedbackLine } from "../../assets/ui-icons/feedback-line.svg";
import { ReactComponent as SvgFemaleLine } from "../../assets/ui-icons/female-line.svg";
import { ReactComponent as SvgImprintLine } from "../../assets/ui-icons/imprint-line.svg";
import { ReactComponent as SvgInfoLine } from "../../assets/ui-icons/info-line.svg";
import { ReactComponent as SvgKeyLine } from "../../assets/ui-icons/key-line.svg";
import { ReactComponent as SvgKeySolid } from "../../assets/ui-icons/key-solid.svg";
import { ReactComponent as SvgLogoutLine } from "../../assets/ui-icons/logout-line.svg";
import { ReactComponent as SvgMailLine } from "../../assets/ui-icons/mail-line.svg";
import { ReactComponent as SvgMailSolid } from "../../assets/ui-icons/mail-solid.svg";
import { ReactComponent as SvgMaleLine } from "../../assets/ui-icons/male-line.svg";
import { ReactComponent as SvgMapLine } from "../../assets/ui-icons/map-line.svg";
import { ReactComponent as SvgMapPinSolid } from "../../assets/ui-icons/map-pin-solid.svg";
import { ReactComponent as SvgMenuLine } from "../../assets/ui-icons/menu-line.svg";
import { ReactComponent as SvgNavigationLine } from "../../assets/ui-icons/navigation-line.svg";
import { ReactComponent as SvgNavigationSolid } from "../../assets/ui-icons/navigation-solid.svg";
import { ReactComponent as SvgPrivacyPolicyLine } from "../../assets/ui-icons/privacy-policy-line.svg";
import { ReactComponent as SvgRepairLine } from "../../assets/ui-icons/repair-line.svg";
import { ReactComponent as SvgStopNavigationLine } from "../../assets/ui-icons/stop-navigation-line.svg";
import { ReactComponent as SvgStopNavigationSolid } from "../../assets/ui-icons/stop-navigation-solid.svg";
import { ReactComponent as SvgTargetLine } from "../../assets/ui-icons/target-line.svg";
import { ReactComponent as SvgWallWcLine } from "../../assets/ui-icons/wall-wc-line.svg";
import { ReactComponent as SvgWallWcSolid } from "../../assets/ui-icons/wall-wc-solid.svg";

export enum IconsComponentFileNames {
    Accessibility = "accessibility",
    Add = "add",
    AlertCircle = "alert-circle",
    Alert = "alert",
    Bell = "bell",
    BvgProperty = "bvg-property",
    Category = "category",
    ChevronDown = "chevron-down",
    ChevronLeft = "chevron-left",
    ChevronRight = "chevron-right",
    ChevronUp = "chevron-up",
    Cross = "cross",
    Divers = "divers",
    Feedback = "feedback",
    Female = "female",
    Imprint = "imprint",
    Info = "info",
    Key = "key",
    Logout = "logout",
    Mail = "mail",
    Male = "male",
    Map = "map",
    MapPin = "map-pin",
    Menu = "menu",
    Navigation = "navigation",
    PrivacyPolicy = "privacy-policy",
    Repair = "repair",
    StopNavigation = "stop-navigation",
    Target = "target",
    WallWc = "wall-wc",
}
type IconProps = {
    size?:
        | number
        | {
              height: number;
              width: number;
          };
    color?: string;
    onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
    strokeWidth?: number;
};

export type WcAppIcon = (props: IconProps) => JSX.Element;

export function IconAccessibilityLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Accessibility,
                svgElement: SvgAccessibilityLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconAddLine({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Add,
                svgElement: SvgAddLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconAlertCircleLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.AlertCircle,
                svgElement: SvgAlertCircleLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconAlertLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Alert,
                svgElement: SvgAlertLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconAlertSolid({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Alert,
                svgElement: SvgAlertSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconAlert({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Alert,
                svgElement: SvgAlertLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            activeIcon={{
                name: IconsComponentFileNames.Alert,
                svgElement: SvgAlertSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconBellLine({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Bell,
                svgElement: SvgBellLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconBvgPropertySolid({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.BvgProperty,
                svgElement: SvgBvgPropertySolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconCategoryLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Category,
                svgElement: SvgCategoryLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconChevronDownLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.ChevronDown,
                svgElement: SvgChevronDownLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconChevronLeftLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.ChevronLeft,
                svgElement: SvgChevronLeftLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconChevronRightLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.ChevronRight,
                svgElement: SvgChevronRightLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconChevronUpLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.ChevronUp,
                svgElement: SvgChevronUpLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconCrossLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Cross,
                svgElement: SvgCrossLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconDiversLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Divers,
                svgElement: SvgDiversLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconFeedbackLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Feedback,
                svgElement: SvgFeedbackLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconFemaleLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Female,
                svgElement: SvgFemaleLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconImprintLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Imprint,
                svgElement: SvgImprintLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconInfoLine({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Info,
                svgElement: SvgInfoLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconKeyLine({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Key,
                svgElement: SvgKeyLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconKeySolid({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Key,
                svgElement: SvgKeySolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconKey({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Key,
                svgElement: SvgKeyLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            activeIcon={{
                name: IconsComponentFileNames.Key,
                svgElement: SvgKeySolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconLogoutLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Logout,
                svgElement: SvgLogoutLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconMailLine({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Mail,
                svgElement: SvgMailLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconMailSolid({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Mail,
                svgElement: SvgMailSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconMail({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Mail,
                svgElement: SvgMailLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            activeIcon={{
                name: IconsComponentFileNames.Mail,
                svgElement: SvgMailSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconMaleLine({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Male,
                svgElement: SvgMaleLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconMapLine({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Map,
                svgElement: SvgMapLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconMapPinSolid({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.MapPin,
                svgElement: SvgMapPinSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconMenuLine({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Menu,
                svgElement: SvgMenuLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconNavigationLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Navigation,
                svgElement: SvgNavigationLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconNavigationSolid({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Navigation,
                svgElement: SvgNavigationSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconNavigation({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Navigation,
                svgElement: SvgNavigationLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            activeIcon={{
                name: IconsComponentFileNames.Navigation,
                svgElement: SvgNavigationSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconPrivacyPolicyLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.PrivacyPolicy,
                svgElement: SvgPrivacyPolicyLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconRepairLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Repair,
                svgElement: SvgRepairLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconStopNavigationLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.StopNavigation,
                svgElement: SvgStopNavigationLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconStopNavigationSolid({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.StopNavigation,
                svgElement: SvgStopNavigationSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconStopNavigation({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.StopNavigation,
                svgElement: SvgStopNavigationLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            activeIcon={{
                name: IconsComponentFileNames.StopNavigation,
                svgElement: SvgStopNavigationSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconTargetLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.Target,
                svgElement: SvgTargetLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconWallWcLine({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.WallWc,
                svgElement: SvgWallWcLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconWallWcSolid({
    size,
    color,
    strokeWidth,
    onClick,
}: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.WallWc,
                svgElement: SvgWallWcSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}

export function IconWallWc({ size, color, strokeWidth, onClick }: IconProps) {
    return (
        <SvgIcon
            mainIcon={{
                name: IconsComponentFileNames.WallWc,
                svgElement: SvgWallWcLine,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            activeIcon={{
                name: IconsComponentFileNames.WallWc,
                svgElement: SvgWallWcSolid,
                color,
                strokeWidth: strokeWidth || 1,
            }}
            size={size}
            onClick={onClick}
        />
    );
}
