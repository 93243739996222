import { createStyles } from "@mantine/core";

const useToiletListEntryHeaderStyles = createStyles((theme) => {
    return {
        objectNumber: {
            fontSize: theme.fontSizes.xs,
            fontWeight: 700,
            alignItems: "flex-end",
            whiteSpace: "nowrap",
        },
    };
});

export default useToiletListEntryHeaderStyles;
