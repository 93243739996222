import { createStyles } from "@mantine/core";

const useWcAppShellStyles = createStyles((theme) => ({
    body: {
        height: "100%",
        backgroundColor:
            theme.colorScheme === "dark"
                ? theme.colors.dark[7]
                : theme.colors.light[0],
    },
    main: {
        overflow: "hidden",
    },
    content: {
        height: "100%",
    },
}));

export default useWcAppShellStyles;
