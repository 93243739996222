import React from "react";
import { Center, Loader } from "@mantine/core";
import MapHeader from "../components/MapHeader/MapHeader";
import SplitView from "../components/SplitView/SplitView";
import FacilityList from "../components/FacilityList/FacilityList";
import FacilityMap from "../components/FacilityMap/FacilityMap";
import NoFacilities from "../components/Pages/NoFacilities/NoFacilities";
import useApplicationStore from "../stores/application.store";
import useGetAllFacilities from "../utilities/client/hooks/useGetAllFacilities";

function FacilityMapPage() {
    const isFacilityListActive = useApplicationStore(
        (state) => state.isFacilityListActive
    );
    const isInNavigationMode = useApplicationStore(
        (state) => state.isInNavigationMode
    );
    const { data: allFacilities, isLoading } = useGetAllFacilities();

    return (
        <>
            <MapHeader visible={!isInNavigationMode} />
            {isLoading ? (
                <Center style={{ height: "100%", width: "100%" }}>
                    <Loader size="xl" />
                </Center>
            ) : (
                (allFacilities.length && (
                    <SplitView
                        bottom={<FacilityList />}
                        showBottom={isFacilityListActive}
                        top={<FacilityMap />}
                    />
                )) || <NoFacilities />
            )}
        </>
    );
}

export default FacilityMapPage;
