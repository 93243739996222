import React, { ReactElement } from "react";
import { Stepper } from "@mantine/core";
import { useCounter } from "@mantine/hooks";
import useStepperWithoutHeaderStyles from "./StepperWithoutHeader.styles";

export type stepIndexHandlerType = ReturnType<typeof useCounter>[1];

type StepperWithoutHeaderProps = {
    active: number;
    children: React.ReactNode;
};

function StepperWithoutHeader({
    active,
    children,
}: StepperWithoutHeaderProps): ReactElement {
    const { classes } = useStepperWithoutHeaderStyles();

    return (
        <Stepper
            active={active}
            classNames={{
                steps: classes.displayNone,
                root: classes.fullWidth,
                content: classes.content,
            }}
        >
            {children}
        </Stepper>
    );
}

export default StepperWithoutHeader;
