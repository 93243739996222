import React, {
    ReactElement,
    createRef,
    useLayoutEffect,
    useState,
} from "react";
import { DraggableData } from "react-draggable";
import { useId, useViewportSize } from "@mantine/hooks";
import SplitViewHandle from "./SplitViewHandle/SplitViewHandle";
import useSplitViewStyles from "./SplitView.styles";

type SplitViewProps = {
    bottom: ReactElement;
    showBottom: boolean;
    top: ReactElement;
};

function SplitView({ bottom, showBottom, top }: SplitViewProps): ReactElement {
    const [handleHeight, sethandleHeight] = useState(0);
    const handleRef = createRef<HTMLDivElement>();
    const { height } = useViewportSize();
    const [listHeight, setListHeight] = useState(0);
    const [mapHeight, setMapHeight] = useState(0);
    const ref = createRef<HTMLDivElement>();
    const [splitOffset, setSplitOffset] = useState(0);
    const splitTop = height / 2;
    const [isDragging, setIsDragging] = useState(false);
    const { classes } = useSplitViewStyles({
        handleHeight,
        isDragging,
    });
    const id = useId();

    useLayoutEffect(() => {
        if (ref.current) {
            if (showBottom) {
                setMapHeight(height / 2 + splitOffset);
                setListHeight(height / 2 - splitOffset);
            } else {
                setMapHeight(height);
                setListHeight(handleHeight);
            }
        }
        if (handleRef.current) {
            sethandleHeight(handleRef.current.clientHeight);
        }
    }, [handleHeight, handleRef, height, ref, showBottom, splitOffset]);

    const splitRatioChange = (data: DraggableData) => {
        setSplitOffset(data.y);
        setIsDragging(true);
    };

    const splitRatioChangeEnd = () => {
        setIsDragging(false);
    };

    /*
        Note that the dynamic styles are implemented using the style property.

        This is necessary as Mantine generates unique class names for each
        class generated by createStyles. This leads to the document getting
        cluttered up by thousands of style nodes as soon as the dragging starts.
    */
    return (
        <div className={classes.main} id={`wc-app-split-view-${id}`} ref={ref}>
            <div
                className={classes.handle}
                style={{
                    transform: !showBottom
                        ? `translateY(${
                              height / 2 - splitOffset - handleHeight
                          }px)`
                        : "",
                }}
                ref={handleRef}
            >
                <SplitViewHandle
                    containerHeight={height}
                    onMove={splitRatioChange}
                    onMoveEnd={splitRatioChangeEnd}
                    top={splitTop}
                />
            </div>
            <div
                className={classes.top}
                style={{
                    height: `${mapHeight}px`,
                }}
            >
                {top}
            </div>
            <div
                className={classes.bottom}
                style={{
                    height: `${listHeight - handleHeight}px`,
                }}
            >
                {showBottom && bottom}
            </div>
        </div>
    );
}

export default SplitView;
