import React, { ReactElement } from "react";
import { Icon as TablerIcon } from "@tabler/icons-react";
import { Indicator, NavLink, useMantineTheme } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";

import {
    IconChevronRightLine as IconChevronRight,
    WcAppIcon,
} from "../../../Icons/Icons";
import useApplicationStore from "../../../../stores/application.store";

export type NavBarLinkProps = {
    children?: NavBarLinkProps[];
    href?: string;
    icon: TablerIcon | WcAppIcon;
    label: string;
    method?: () => void | undefined | null;
    rightSection: boolean;
    showNewsIndicator?: boolean;
};

function NavBarLink({
    children = undefined,
    href,
    icon: Icon,
    label,
    method = () => null,
    rightSection,
    showNewsIndicator = false,
}: NavBarLinkProps): ReactElement {
    const location = useLocation();
    const theme = useMantineTheme();
    const toggleDrawerActive = useApplicationStore(
        (state) => state.toggleDrawerActive
    );

    const isActive = (nextHref: string | undefined) => {
        return nextHref === location.pathname;
    };

    return (
        <NavLink
            color={theme.colorScheme === "dark" ? "dark" : "blue"}
            variant={theme.colorScheme === "dark" ? "filled" : "light"}
            active={isActive(href)}
            label={
                <div
                    style={{
                        lineHeight: "initial",
                        fontSize: theme.fontSizes.md,
                    }}
                >
                    {label}
                </div>
            }
            icon={
                <Indicator
                    disabled={!showNewsIndicator}
                    size={8}
                    color="flamingo.6"
                    offset={4}
                    withBorder
                >
                    <Icon size={22} strokeWidth={2} />
                </Indicator>
            }
            childrenOffset={28}
            to={{ pathname: href || location.pathname }}
            component={Link}
            onClick={() => {
                if (href) toggleDrawerActive(false);
                if (method) method();
            }}
            rightSection={
                rightSection ? (
                    <IconChevronRight size={12} strokeWidth={1.5} />
                ) : (
                    false
                )
            }
        >
            {children?.map((child) => {
                return (
                    <NavBarLink
                        key={child.label}
                        href={child.href}
                        icon={child.icon}
                        label={child.label}
                        method={child.method}
                        rightSection={child.rightSection}
                    >
                        {child.children}
                    </NavBarLink>
                );
            })}
        </NavLink>
    );
}

export default NavBarLink;
