import { Group, Text, Accordion, Image, List } from "@mantine/core";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import useAccordionComponentStyles from "./AccordionComponent.styles";

type AccordionLabelProps = {
    label: string;
};

function AccordionLabel({ label }: AccordionLabelProps) {
    return (
        <Group noWrap>
            <div>
                <Text>{label}</Text>
            </div>
        </Group>
    );
}

function AccordionComponent(): ReactElement {
    const { t } = useTranslation();
    const { classes } = useAccordionComponentStyles();

    const listItem = (
        text: string,
        src: string,
        continueListItem?: boolean
    ) => {
        const image = (
            <Image
                src={src}
                fit="contain"
                className={classes.image}
                width={240}
            />
        );
        return continueListItem ? (
            <div style={{ marginLeft: 16 }}>
                {text}
                {image}
            </div>
        ) : (
            <List.Item className={classes.listItem}>
                {text}
                {image}
            </List.Item>
        );
    };

    const charactersList = [
        {
            id: "browserInstruction",
            label: t("accordionComponent.browserInstruction.title"),
            content: (
                <List type="ordered">
                    {listItem(
                        t("accordionComponent.browserInstruction.step_1"),
                        "/images/browser-settings-1-1.png"
                    )}

                    {listItem(
                        t("accordionComponent.browserInstruction.step_2"),
                        "/images/browser-settings-2-1.png"
                    )}
                    {listItem(
                        t("accordionComponent.browserInstruction.step_3"),
                        "/images/security-settings-3-1.png"
                    )}
                    {listItem(
                        t("accordionComponent.browserInstruction.step_4"),
                        "/images/site-permissions-4-1.png"
                    )}
                    {listItem(
                        t("accordionComponent.browserInstruction.step_5"),
                        "/images/location-access-5-1.png"
                    )}
                    {listItem(
                        t("accordionComponent.browserInstruction.step_6"),
                        "/images/grant-location-browser-6-1.png"
                    )}
                    {listItem(
                        t("accordionComponent.browserInstruction.step_7_1"),
                        "/images/grant-location-browser-7-1.png"
                    )}
                    {listItem(
                        t("accordionComponent.browserInstruction.step_7_2"),
                        "/images/grant-location-browser-7-2.png",
                        true
                    )}
                </List>
            ),
        },
        {
            id: "mobileDeviceInstruction",
            label: t("accordionComponent.mobileDeviceInstruction.title"),
            content: (
                <List type="ordered">
                    {listItem(
                        t("accordionComponent.mobileDeviceInstruction.step_1"),
                        "/images/device-gps-settings-1-1.png"
                    )}
                    {listItem(
                        t("accordionComponent.mobileDeviceInstruction.step_2"),
                        "/images/device-gps-location-2-1.png"
                    )}
                    {listItem(
                        t("accordionComponent.mobileDeviceInstruction.step_3"),
                        "/images/device-gps-location-switch-3-1.png"
                    )}
                    {listItem(
                        t("accordionComponent.mobileDeviceInstruction.step_4"),
                        "/images/device-gps-enable-fast-4-1.png"
                    )}
                </List>
            ),
        },
    ];
    const items = charactersList.map((item) => (
        <Accordion.Item value={item.label} key={item.label}>
            <Accordion.Control>
                <AccordionLabel label={item.label} />
            </Accordion.Control>
            <Accordion.Panel>{item.content}</Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <Accordion chevronPosition="left" variant="contained">
            {items}
        </Accordion>
    );
}

export default AccordionComponent;
