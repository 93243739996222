import { Accordion, Group, Text, useMantineTheme } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconMoonStars } from "@tabler/icons-react";
import DisplayModeDialog from "../components/Dialogs/DisplayModeDialog/displayModeDialog";
import KeyPermissionDialog from "../components/Dialogs/IntroDialog/KeyPermissionDialog/KeyPermissionDialog";
import { IconKeyLine } from "../components/Icons/Icons";
import DefaultPage from "../components/Pages/DefaultPage";

function SettingsPage() {
    const { t } = useTranslation();
    const theme = useMantineTheme();

    return (
        <DefaultPage title={t("pages.settings.header")}>
            <Accordion chevronPosition="left">
                <Accordion.Item value="customization">
                    <Accordion.Control>
                        <Group noWrap>
                            <IconKeyLine
                                size={38}
                                color={
                                    theme.colorScheme === "dark"
                                        ? theme.colors.dark[0]
                                        : theme.colors.dark[9]
                                }
                            />
                            <div>
                                <Text weight={700} size="lg">
                                    {t("pages.settings.keyAuthorization")}
                                </Text>
                            </div>
                        </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <KeyPermissionDialog usedInIntro={false} />
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="displayMode">
                    <Accordion.Control>
                        <Group noWrap>
                            <div
                                style={{
                                    width: "38px",
                                    height: "38px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <IconMoonStars size={30} />
                            </div>

                            <div>
                                <Text weight={700} size="lg">
                                    {t("pages.settings.darkMode")}
                                </Text>
                            </div>
                        </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <DisplayModeDialog />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </DefaultPage>
    );
}

export default SettingsPage;
