import { createStyles } from "@mantine/core";

type useTileStylesProps = {
    align: "left" | "center";
};

const useTileStyles = createStyles((theme, { align }: useTileStylesProps) => {
    return {
        button: {
            borderRadius: "8px",
            padding: "12px 8px",
            height: "auto",
            minHeight: "36px",
        },
        inner: {
            justifyContent: align,
        },
        label: { whiteSpace: "normal" },
    };
});

export default useTileStyles;
