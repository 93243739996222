import React from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../components/Pages/DefaultPage";
import FrequentlyAskedQuestions from "../components/Pages/FrequentlyAskedQuestions/FrequentlyAskedQuestions";

function FrequentlyAskedQuestionsPage() {
    const { t } = useTranslation();
    return (
        <DefaultPage title={t("pages.frequentlyAskedQuestions.header")}>
            <FrequentlyAskedQuestions />
        </DefaultPage>
    );
}

export default FrequentlyAskedQuestionsPage;
