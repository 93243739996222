import { createStyles } from "@mantine/core";

const useImageUploadNoticeStyles = createStyles((theme) => {
    return {
        infoIconBox: {
            border: `2px solid ${theme.colors.bvg[6]}`,
        },
        text: {
            lineHeight: "120%",
        },
    };
});

export default useImageUploadNoticeStyles;
