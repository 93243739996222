import { Modal, Image, Stepper } from "@mantine/core";
import { useTranslation } from "react-i18next";
import React, { ReactElement } from "react";
import { isMobile } from "react-device-detect";
import { IconCircleX } from "@tabler/icons-react";
import useIntroductionStore from "../../../stores/introduction.store";
import KeyPermissionDialog from "./KeyPermissionDialog/KeyPermissionDialog";
import LocationPermissionIntroDialog from "./LocationPermissionIntroDialog/LocationPermissionIntroDialog";
import useIntroDialogStyles from "./IntroDialog.styles";

function IntroDialog(): ReactElement {
    const { t } = useTranslation();
    const locationPermissionDone = useIntroductionStore(
        (state) => state.locationPermissionDone
    );
    const toggleLocationPermissionDone = useIntroductionStore(
        (state) => state.toggleLocationPermissionDone
    );
    const isApplyKey = useIntroductionStore((state) => state.isApplyKey);
    const active = useIntroductionStore((state) => state.active);
    const isBackFormLocationPermission = useIntroductionStore(
        (state) => state.isBackFormLocationPermission
    );
    const onStep = useIntroductionStore((state) => state.onStep);
    const { classes } = useIntroDialogStyles();

    return (
        <Modal
            classNames={{
                title: classes.title,
                header: classes.header,
                body: classes.body,
            }}
            closeOnClickOutside={false}
            fullScreen={isMobile}
            centered
            opened={!locationPermissionDone || !isApplyKey}
            onClose={toggleLocationPermissionDone}
            closeOnEscape={false}
            title={t("introDialog.title")}
            withCloseButton={false}
            overlayProps={{ opacity: 0.55, blur: 3 }}
            transitionProps={{
                transition: "fade",
                duration: 600,
                timingFunction: "pop",
            }}
        >
            <Stepper
                active={active}
                breakpoint={320}
                orientation="horizontal"
                onStepClick={onStep}
                classNames={{
                    root: classes.root,
                    separatorActive: classes.separatorActive,
                    stepIcon: classes.stepIcon,
                    step: classes.step,
                    stepBody: classes.stepBody,
                }}
            >
                <Stepper.Step
                    label={t("introDialog.step1")}
                    className={`stepperIncompleted `}
                    icon={<Image src="/ui-icons/bvg-key-line.svg" />}
                    completedIcon={
                        !isApplyKey ? (
                            <IconCircleX />
                        ) : (
                            <Image src="/ui-icons/bvg-key-line.svg" />
                        )
                    }
                    color="red"
                />
                <Stepper.Step
                    label={t("introDialog.step2")}
                    icon={
                        !isBackFormLocationPermission ? (
                            <Image src="/ui-icons/bvg-map-pin-line.svg" />
                        ) : (
                            <IconCircleX />
                        )
                    }
                />
            </Stepper>

            {active === 0 ? (
                <KeyPermissionDialog />
            ) : (
                <LocationPermissionIntroDialog />
            )}
        </Modal>
    );
}
export default IntroDialog;
