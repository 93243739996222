import { createStyles } from "@mantine/core";

const useFloorToiletsListStyles = createStyles((theme) => ({
    tableRow: {
        textAlign: "center",
        borderColor: `${theme.colors.bvg[5]} !important`,
        borderWidth: "2px !important",
    },
}));

export default useFloorToiletsListStyles;
