import React, { ReactElement, useEffect, useState } from "react";
import { FacilityWithDistance } from "../../utilities/client/hooks/useFacilitiesWithDistance";
import FacilityListEntryActions from "../FacilityListEntryActions/FacilityListEntryActions";
import useFacilityStore from "../../stores/facility.store";
import Collapsible from "../Collapsible/Collapsible";
import { Facility, isBvgProperty } from "../../utilities/client/facility";
import BvgPropertyListEntryHeader from "./ListEntryHeader/BvgPropertyListEntryHeader";
import ToiletListEntryHeader from "./ListEntryHeader/ToiletListEntryHeader";
import IssueReport from "../BottomSheetPages/IssueReport/IssueReport";

export type FacilityListEntryDistanceDetails = {
    lng: number;
    lat: number;
    distance?: number;
    duration?: number | null;
};

type FacilityListEntryProps = {
    facility: Facility | Required<FacilityWithDistance>;
    hasIssues?: boolean;
    isDescriptionHidden?: boolean;
    isObjectNumberHidden?: boolean;
    onShowPropertyToiletsChanged?: () => void;
    onSelect?: () => void;
};

function FacilityListEntry({
    facility,
    hasIssues = false,
    isDescriptionHidden = false,
    isObjectNumberHidden = false,
    onShowPropertyToiletsChanged,
    onSelect = () => ({}),
}: FacilityListEntryProps): ReactElement {
    // useState part
    const [isIssueReportBottomSheetOpen, setIsIssueReportBottomSheetOpen] =
        useState(false);
    const [isIssueListOpenInitially, setIsIssueListOpenInitially] =
        useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const selectedFacility = useFacilityStore(
        (state) => state.selectedFacility
    );
    const selectedFacilityCollapsed = useFacilityStore(
        (state) => state.selectedFacilityCollapsed
    );
    const setSelectedFacility = useFacilityStore(
        (state) => state.setSelectedFacility
    );
    const setSelectedFacilityCollapsed = useFacilityStore(
        (state) => state.setSelectedFacilityCollapsed
    );

    const selectedFacilityId = selectedFacility?.id;
    const isSelected = selectedFacilityId === facility.id;

    const distanceDetails: FacilityListEntryDistanceDetails = {
        lng: facility.geolocation.coordinates[0],
        lat: facility.geolocation.coordinates[1],
    };

    if ("distance" in facility && facility.duration) {
        distanceDetails.distance = facility.distance;
        distanceDetails.duration = facility.duration;
    }

    const change = () => {
        if (!isSelected) {
            setSelectedFacility(facility);
            setSelectedFacilityCollapsed(false);
            // to trigger onSelect event even if no transitionEnd callback has fired
        }
    };

    useEffect(() => {
        if (isSelected && isOpen) {
            onSelect();
        }
    }, [isOpen, isSelected, onSelect, selectedFacilityId]);

    return (
        <>
            <Collapsible
                isSelected={isSelected}
                isOpen={isSelected ? !selectedFacilityCollapsed : isOpen}
                onTransitionEnd={() => isSelected && onSelect()}
                onCollapseChanged={(open) => {
                    if (isSelected) {
                        setSelectedFacilityCollapsed(!open);
                    } else {
                        setIsOpen(open);
                    }
                }}
                header={
                    isBvgProperty(facility) ? (
                        <BvgPropertyListEntryHeader
                            property={facility}
                            isDescriptionHidden={isDescriptionHidden}
                            distanceDetails={distanceDetails}
                            onShowPropertyToiletsChanged={
                                onShowPropertyToiletsChanged
                            }
                        />
                    ) : (
                        <ToiletListEntryHeader
                            toilet={facility}
                            isDescriptionHidden={isDescriptionHidden}
                            isObjectNumberHidden={isObjectNumberHidden}
                            distanceDetails={distanceDetails}
                            hasIssues={hasIssues}
                            onShowIssueReports={() => {
                                // open issue report bottom sheet with issue list open
                                setIsIssueReportBottomSheetOpen(true);
                                setIsIssueListOpenInitially(true);
                            }}
                        />
                    )
                }
                content={
                    <FacilityListEntryActions
                        onNewIssueReport={() => {
                            // open issue report bottom sheet with issue list closed
                            setIsIssueReportBottomSheetOpen(true);
                            setIsIssueListOpenInitially(false);
                        }}
                    />
                }
                onClick={change}
            />
            <IssueReport
                isOpen={isIssueReportBottomSheetOpen}
                facility={facility}
                isIssueListOpen={isIssueListOpenInitially}
                onClose={() => {
                    setIsIssueReportBottomSheetOpen(false);
                }}
            />
        </>
    );
}

export default React.memo(FacilityListEntry);
