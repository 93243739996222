import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BvgProperty } from "../../../utilities/api/wcAppSchemas";
import FacilityListEntryHeader from "./FacilityListEntryHeader";
import PropertiesList from "./PropertiesList/PropertiesList";
import { IconBvgPropertySolid } from "../../Icons/Icons";
import { LIST_ICON_SIZE } from "../../../utilities/client/facility";
import { FacilityListEntryDistanceDetails } from "../FacilityListEntry";

type BvgPropertyListEntryHeaderProps = {
    property: BvgProperty;
    isDescriptionHidden?: boolean;
    distanceDetails: FacilityListEntryDistanceDetails;
    onShowPropertyToiletsChanged?: () => void;
};

function BvgPropertyListEntryHeader({
    property,
    isDescriptionHidden = false,
    distanceDetails,
    onShowPropertyToiletsChanged,
}: BvgPropertyListEntryHeaderProps): ReactElement {
    const { t } = useTranslation();

    const iconSize = LIST_ICON_SIZE + 14;
    const icon = React.createElement(IconBvgPropertySolid, {
        size: iconSize,
    });

    const hasPropertyAccessibleToilet = property.toilets.some(
        (toilet) => toilet.accessible
    );

    return (
        <FacilityListEntryHeader
            icon={icon}
            title={property.name}
            address={property.address}
            isDescriptionHidden={isDescriptionHidden}
            locationDescription={t("facility.description.building")}
            distanceDetails={distanceDetails}
            detailsLink={{
                text: `${t("facility.description.propertyToilets")} (${
                    property.toilets.length
                })`,
                onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
                    e.stopPropagation();
                    if (onShowPropertyToiletsChanged) {
                        onShowPropertyToiletsChanged();
                    }
                },
            }}
            information={
                <PropertiesList isAccessible={hasPropertyAccessibleToilet} />
            }
        />
    );
}

export default React.memo(BvgPropertyListEntryHeader);
