import React, { ReactElement, useMemo } from "react";
import { Text, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import FacilityListEntryHeader from "./FacilityListEntryHeader";
import { Toilet } from "../../../utilities/api/wcAppSchemas";
import { LIST_ICON_SIZE } from "../../../utilities/client/facility";
import useToiletListEntryHeaderStyles from "./ToiletListEntryHeader.styles";
import {
    IconWallWcLine,
    IconWallWcSolid,
    IconMapPinSolid,
} from "../../Icons/Icons";
import { FacilityListEntryDistanceDetails } from "../FacilityListEntry";

type ToiletListEntryHeaderProps = {
    toilet: Toilet;
    isDescriptionHidden?: boolean;
    isObjectNumberHidden?: boolean;
    distanceDetails: FacilityListEntryDistanceDetails;
    hasIssues: boolean;
    onShowIssueReports: () => void;
};

const FF_ISSUE_REPORT = process.env.REACT_APP_FF_ISSUE_REPORT === "true";

function ToiletListEntryHeader({
    toilet,
    isDescriptionHidden = false,
    isObjectNumberHidden = false,
    distanceDetails,
    hasIssues = false,
    onShowIssueReports,
}: ToiletListEntryHeaderProps): ReactElement {
    const { classes } = useToiletListEntryHeaderStyles();

    const { t } = useTranslation();

    const isWallToilet = toilet.toiletType === "WALL";

    const theme = useMantineTheme();
    const isDarkTheme = theme.colorScheme === "dark";
    const iconSize = LIST_ICON_SIZE;
    let iconImage = IconMapPinSolid;
    if (isWallToilet) {
        if (isDarkTheme) {
            iconImage = IconWallWcLine;
        }
        iconImage = IconWallWcSolid;
    }
    const icon = React.createElement(iconImage, {
        size: iconSize,
        color: !isDarkTheme && isWallToilet ? "none" : undefined,
    });

    const displayIssueText = useMemo(() => {
        return FF_ISSUE_REPORT && !isWallToilet && hasIssues;
    }, [isWallToilet, hasIssues]);

    return (
        <FacilityListEntryHeader
            icon={icon}
            title={toilet.station}
            address={toilet.address}
            comment={toilet.comment}
            isDescriptionHidden={isDescriptionHidden}
            locationDescription={
                isWallToilet
                    ? t("facility.description.wall")
                    : t("facility.description.track")
            }
            distanceDetails={distanceDetails}
            detailsLink={
                displayIssueText
                    ? {
                          text: t("facility.description.issues"),
                          onClick: (
                              e: React.MouseEvent<Element, MouseEvent>
                          ) => {
                              e.stopPropagation();
                              onShowIssueReports();
                          },
                      }
                    : null
            }
            information={
                isObjectNumberHidden ? null : (
                    <Text className={classes.objectNumber}>
                        # {toilet.objectNumber}
                    </Text>
                )
            }
        />
    );
}

export default React.memo(ToiletListEntryHeader);
