import { createStyles } from "@mantine/core";

const useImageInputStyles = createStyles(() => {
    return {
        "image-input": {
            display: "none",
        },
    };
});

export default useImageInputStyles;
