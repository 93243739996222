import React, { ReactElement, useEffect, useState } from "react";
import {
    Group,
    Text,
    Stack,
    useMantineTheme,
    Indicator,
    CloseButton,
} from "@mantine/core";
import { useElementSize, useViewportSize } from "@mantine/hooks";
import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import useIssueListEntryStyles from "./IssueListEntry.styles";
import {
    IconChevronDownLine as IconChevronDown,
    IconChevronUpLine as IconChevronUp,
} from "../../Icons/Icons";
import { Issue, Toilet } from "../../../utilities/api/wcAppSchemas";
import useGetAllFacilities from "../../../utilities/client/hooks/useGetAllFacilities";
import { isToilet } from "../../../utilities/client/facility";
import RemoveUserIssue from "../../BottomSheetPages/RemoveUserIssue/RemoveUserIssue";
import BookmarkIssueButton from "./BookmarkIssueButton/BookmarkIssueButton";

type IssueListEntryProps = {
    issueEntry: Issue;
    showState?: boolean;
    showNewsIndicator?: boolean;
};

const CHEVRON_ICON_SIZE = 24;

function IssueListEntry({
    issueEntry,
    showState = false,
    showNewsIndicator = false,
}: IssueListEntryProps): ReactElement | null {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const { classes } = useIssueListEntryStyles();

    const [isOpen, setIsOpen] = useState(false);
    const [isCollapsible, setIsCollapsible] = useState(false);
    const { ref: descriptionRef, width: descriptionWidth } =
        useElementSize<HTMLDivElement>();
    const { width: viewPortWidth } = useViewportSize();

    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

    const { data: facilities, isLoading: isFacilitiesLoading } =
        useGetAllFacilities();

    const toilet: Toilet = facilities.find(
        (facility) =>
            isToilet(facility) &&
            facility.sapEqNumber === issueEntry.sapEqNumber
    ) as Toilet;

    useEffect(() => {
        if (descriptionRef.current) {
            setIsOpen(false);
            const isDescriptionTextClamped =
                descriptionRef.current.scrollHeight >
                descriptionRef.current.clientHeight;
            setIsCollapsible(isDescriptionTextClamped);
        }
    }, [descriptionRef, descriptionWidth, viewPortWidth]);

    let collapseIcon = null;
    if (isCollapsible) {
        collapseIcon = isOpen ? (
            <IconChevronUp
                size={CHEVRON_ICON_SIZE}
                color={theme.colors.dark[9]}
            />
        ) : (
            <IconChevronDown
                size={CHEVRON_ICON_SIZE}
                color={theme.colors.dark[9]}
            />
        );
    }

    let issueDescription = null;
    if (issueEntry.description) {
        issueDescription = isOpen ? (
            <Text ref={descriptionRef} className={classes.text}>
                {issueEntry.description}
            </Text>
        ) : (
            <Text ref={descriptionRef} lineClamp={2} className={classes.text}>
                {issueEntry.description}
            </Text>
        );
    }

    const toggleIsOpen = () => {
        if (isCollapsible) {
            setIsOpen(!isOpen);
        }
    };

    const parsedIssueDate = format(
        parseISO(issueEntry.dateCreated),
        "dd.MM.yyyy"
    );

    if (showState && isFacilitiesLoading) {
        return null;
    }

    return (
        <>
            <Indicator
                disabled={!showNewsIndicator}
                offset={2}
                position="top-start"
                color="flamingo.6"
                size={12}
                withBorder
                mt={showState ? 10 : 0}
            >
                {showState && (
                    <CloseButton
                        color="red.9"
                        size="sm"
                        radius="xl"
                        variant="filled"
                        className={classes.closeButton}
                        onClick={() => {
                            setIsRemoveDialogOpen(true);
                        }}
                        iconSize={16}
                    />
                )}
                <Stack
                    onClick={toggleIsOpen}
                    className={classes.yellowBackground}
                    spacing={4}
                >
                    <Group position="apart">
                        <Text size="sm" className={classes.text}>
                            {parsedIssueDate}
                        </Text>
                        {showState && (
                            <Text
                                size="sm"
                                mr={12}
                                align="end"
                                className={
                                    issueEntry.status === "CLOSED"
                                        ? classes.statusDone
                                        : classes.statusIncomplete
                                }
                                weight="bold"
                            >
                                {issueEntry.status === "CLOSED"
                                    ? t("issueList.status.done")
                                    : t("issueList.status.incomplete")}
                            </Text>
                        )}
                        {!showState && (
                            <BookmarkIssueButton issueEntry={issueEntry} />
                        )}
                    </Group>

                    {showState && toilet && !isFacilitiesLoading && (
                        <Text weight="bold" className={classes.text}>
                            {toilet.station}
                        </Text>
                    )}
                    <Group position="apart" noWrap align="flex-start">
                        <Text weight="bold" size="sm" className={classes.text}>
                            {issueEntry.title}
                        </Text>
                        {collapseIcon}
                    </Group>
                    {issueDescription ?? ""}
                </Stack>
            </Indicator>
            <RemoveUserIssue
                isOpen={isRemoveDialogOpen}
                userIssueId={issueEntry.id}
                onClose={() => {
                    setIsRemoveDialogOpen(false);
                }}
            />
        </>
    );
}

export default IssueListEntry;
