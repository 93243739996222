import React from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../components/Pages/DefaultPage";
import Accessibility from "../components/Pages/Accessibility/Accessibility";

function AccessibilityPage() {
    const { t } = useTranslation();
    return (
        <DefaultPage title={t("pages.accessibility.header")}>
            <Accessibility />
        </DefaultPage>
    );
}

export default AccessibilityPage;
