import { sub } from "date-fns";
import { Issue, IssueReportOption } from "../../api/wcAppSchemas";

export const OTHER_ISSUE = "Sonstige Störung";
export const OTHER_CATEGORY = "Sonstige Störung";

const SUBMIT_CONTACT_INFORMATION_STRING = "Kontaktdaten:";

export function getCategoryOptions(options: IssueReportOption[]): string[] {
    const categories = options.map((option) => option.category);
    return Array.from(new Set(categories)).sort();
}

export function getIssueOptionsByCategory(
    options: IssueReportOption[],
    categoryName: string
): IssueReportOption[] {
    const optionsInCategory = options.filter(
        (option) => option.category === categoryName
    );

    return optionsInCategory.sort((a, b) => a.issue.localeCompare(b.issue));
}

export function getIssueDescription(description: string, email: string | null) {
    const contactData = email
        ? `${SUBMIT_CONTACT_INFORMATION_STRING} ${email}`
        : "";
    const issueDescription = `${description}\n${contactData}`.trim();
    return issueDescription.length > 0 ? issueDescription : null;
}

export function hasIssueWithinDisableTimeframe(
    issuesArray: Issue[],
    title: string
) {
    const TIME_LIMIT_DAYS_FOR_DISABLED_ISSUE = Number(
        process.env.REACT_APP_TIME_LIMIT_DAYS_FOR_DISABLED_ISSUE
    );
    const timeFrame = sub(new Date(), {
        days: TIME_LIMIT_DAYS_FOR_DISABLED_ISSUE,
    }).toISOString();
    const issuesFound = issuesArray.find(
        (issue) => issue.title === title && issue.dateCreated > timeFrame
    );

    return issuesFound !== undefined;
}

async function toBase64(image: File) {
    const rawData = await image.arrayBuffer();
    const bytes = new Uint8Array(rawData);
    let binary = "";
    for (let i = 0; i < rawData.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

export async function convertFilesToIssueImages(files: File[]) {
    return Promise.all(
        files.map(async (file) => {
            return {
                filename: file.name,
                data: await toBase64(file),
            };
        })
    );
}
