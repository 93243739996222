import React, { ReactElement, useMemo, useState } from "react";
import L, { MarkerCluster } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useMantineTheme } from "@mantine/core";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import FacilityMarker from "../FacilityMarker/FacilityMarker";
import StaticClusterMarker from "./StaticClusterMarker";
import FacilityListEntry from "../FacilityListEntry/FacilityListEntry";
import useApplicationStore from "../../stores/application.store";
import useFacilityStore from "../../stores/facility.store";
import useGetAllFacilities from "../../utilities/client/hooks/useGetAllFacilities";
import BottomSheet from "../BottomSheet/BottomSheet";
import useGetToiletIssues from "../../utilities/client/hooks/useGetToiletIssues";
import { isBvgProperty, isToilet } from "../../utilities/client/facility";
import PropertyToiletsList from "../Pages/PropertyToiletsList/PropertyToiletsList";

const MARKER_TRANSPARENCY = 0.3;

function ClusterMarker(): ReactElement | null {
    const { data: allFacilities, isLoading } = useGetAllFacilities();
    const { data: issues } = useGetToiletIssues();
    const [showPropertyToilets, setShowPropertyToiletsDialog] = useState(false);

    const theme = useMantineTheme();
    const { t } = useTranslation();
    const selectedFacility = useFacilityStore(
        (state) => state.selectedFacility
    );
    const selectedFacilityCollapsed = useFacilityStore(
        (state) => state.selectedFacilityCollapsed
    );
    const propertyToilets = useFacilityStore((state) => state.propertyToilets);
    const setSelectedFacility = useFacilityStore(
        (state) => state.setSelectedFacility
    );
    const setPropertyToilets = useFacilityStore(
        (state) => state.setPropertyToilets
    );

    const isFacilityListActive = useApplicationStore(
        (state) => state.isFacilityListActive
    );
    const isInNavigationMode = useApplicationStore(
        (state) => state.isInNavigationMode
    );

    const selectedFacilityId = selectedFacility?.id;
    const selectedSapEqNumber =
        selectedFacility && isToilet(selectedFacility)
            ? selectedFacility.sapEqNumber
            : null;

    const createClusterCustomIcon = (cluster: MarkerCluster) => {
        return L.divIcon({
            html: renderToString(
                <StaticClusterMarker
                    selectedFacility={!!selectedFacility}
                    childCount={cluster.getChildCount()}
                    color={theme.colors.blue[8]}
                    transparency={MARKER_TRANSPARENCY}
                />
            ),
            className: "", // reset styles
        });
    };

    const facilityMarkers = useMemo(() => {
        const showingFacilities = selectedFacilityId
            ? allFacilities?.filter(
                  (facility) => selectedFacilityId !== facility.id
              )
            : allFacilities;

        return showingFacilities?.map((facility) => {
            const getMarkerTransparencyConfig = {
                initialStateTransparency: 1,
                transparency: MARKER_TRANSPARENCY,
            };

            return (
                <FacilityMarker
                    key={facility.id}
                    facilityData={facility}
                    transparencyConfig={getMarkerTransparencyConfig}
                />
            );
        });
    }, [selectedFacilityId, allFacilities]);

    const hasIssues = useMemo(() => {
        const toiletIssues = selectedSapEqNumber
            ? issues.filter(
                  (issue) => issue.sapEqNumber === selectedSapEqNumber
              )
            : [];
        return toiletIssues.length > 0;
    }, [issues, selectedSapEqNumber]);

    const onShowPropertyToiletsChanged = () => {
        setPropertyToilets(
            isBvgProperty(selectedFacility) ? selectedFacility?.toilets : []
        );
        setShowPropertyToiletsDialog(true);
    };
    const facilityListEntry = selectedFacility && (
        <FacilityListEntry
            facility={selectedFacility}
            hasIssues={hasIssues}
            onShowPropertyToiletsChanged={onShowPropertyToiletsChanged}
            isDescriptionHidden={
                selectedFacilityCollapsed && isInNavigationMode
            }
            isObjectNumberHidden={
                selectedFacilityCollapsed && isInNavigationMode
            }
        />
    );

    if (isLoading || !allFacilities) {
        return null;
    }

    return (
        <>
            <MarkerClusterGroup
                showCoverageOnHover={false}
                maxClusterRadius={80}
                iconCreateFunction={createClusterCustomIcon}
                key={`${selectedFacility?.id ? "-transparent" : "-opaque"}`}
            >
                {facilityMarkers}
            </MarkerClusterGroup>
            {selectedFacility && (
                <FacilityMarker
                    key={selectedFacility?.id}
                    facilityData={selectedFacility}
                    transparencyConfig={{
                        initialStateTransparency: 1,
                        isMarkerTransparent: true,
                        transparency: MARKER_TRANSPARENCY,
                    }}
                />
            )}
            <BottomSheet
                isOpen={selectedFacility !== null && !isFacilityListActive}
                onClose={() => setSelectedFacility(null)}
                showBackdrop={false}
                closeOnDrag={false}
            >
                {facilityListEntry}
            </BottomSheet>
            <BottomSheet
                headerTitle={t("propertyToiletsListDialog.title")}
                onClose={() =>
                    setShowPropertyToiletsDialog((prevState) => !prevState)
                }
                isOpen={showPropertyToilets}
            >
                <PropertyToiletsList propertyToilets={propertyToilets} />
            </BottomSheet>
        </>
    );
}

export default ClusterMarker;
