import { useId } from "@mantine/hooks";
import React, { ReactElement } from "react";
import useApplicationStore from "../../stores/application.store";
import Navbar from "../Navbar/Navbar";
import useWcAppShellStyles from "./WcAppShell.styles";

type WcAppShellProps = {
    children: ReactElement | ReactElement[];
};
function WcAppShell({ children }: WcAppShellProps): ReactElement {
    const { classes } = useWcAppShellStyles();
    const isDrawerActive = useApplicationStore((state) => state.isDrawerActive);
    const toggleDrawerActive = useApplicationStore(
        (state) => state.toggleDrawerActive
    );
    const id = useId();

    return (
        <div
            className={`wc-app-app-shell ${classes.body} ${classes.main}`}
            id={`wc-app-app-shell-${id}`}
        >
            <Navbar opened={isDrawerActive} toggleMenu={toggleDrawerActive} />
            <div className={classes.content}>{children}</div>
        </div>
    );
}

export default WcAppShell;
