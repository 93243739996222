import { createStyles } from "@mantine/core";

const useIssueReportStyles = createStyles((theme) => ({
    content: {
        marginBottom: "8px",
        width: "100%",
    },
    contentText: {
        fontSize: theme.fontSizes.md,
        lineHeight: "120%",
    },

    footer: {
        borderTop: "1px solid #E5E6E7",
        backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : "white",
        position: "fixed",
        bottom: "0",
        padding: "0 24px",
        minHeight: 72,
        width: "100%",
    },
}));

export default useIssueReportStyles;
