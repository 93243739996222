import { isPointWithinRadius } from "geolib";
import { shallow } from "zustand/shallow";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { useTimeout } from "@mantine/hooks";
import useGeolocationStore from "../../../stores/geolocation.store";
import useGeolocationWatcher from "./useGeolocationWatcher";
import {
    NO_GPS_NOTIFICATION,
    LONG_NOTIFICATION_TIMEOUT,
} from "../notifications";
import useNotificationStore from "../../../stores/notification.store";

type UseCurrentPositionOptions = {
    minUpdateInterval?: number;
    minChangeRadius?: number;
    whenPropertyChanged?:
        | keyof GeolocationCoordinates
        | (keyof GeolocationCoordinates)[];
};

const useCurrentPosition = (
    options?: UseCurrentPositionOptions
): GeolocationCoordinates | null => {
    const {
        minUpdateInterval,
        minChangeRadius,
        whenPropertyChanged,
    }: UseCurrentPositionOptions = {
        ...options,
    };
    useGeolocationWatcher();
    // eslint-disable-next-line no-restricted-syntax
    const { coords, validPosition } = useGeolocationStore(
        (state) => state,
        (stateOld, stateNew) => {
            const { coords: coordsOld, timestamp: timestampOld } = stateOld;
            const { coords: coordsNew, timestamp: timestampNew } = stateNew;

            if (minUpdateInterval || minChangeRadius || whenPropertyChanged) {
                let equals = true;

                if (minUpdateInterval) {
                    equals &&= timestampOld + minUpdateInterval > timestampNew;
                }

                if (minChangeRadius) {
                    equals &&= isPointWithinRadius(
                        {
                            latitude: coordsNew.latitude,
                            longitude: coordsNew.longitude,
                        },
                        {
                            latitude: coordsOld.latitude,
                            longitude: coordsOld.longitude,
                        },
                        minChangeRadius
                    );
                }

                if (whenPropertyChanged) {
                    if (typeof whenPropertyChanged === "string") {
                        equals &&=
                            coordsOld[whenPropertyChanged] ===
                            coordsNew[whenPropertyChanged];
                    } else if (whenPropertyChanged.length > 0) {
                        for (let i = 0; i < whenPropertyChanged.length; i++) {
                            equals &&=
                                coordsOld[whenPropertyChanged[i]] ===
                                coordsNew[whenPropertyChanged[i]];
                        }
                    }
                }
                return equals;
            }
            return shallow(stateOld, stateNew);
        }
    );

    const wasGpsNotificationShown = useNotificationStore(
        (state) => state.wasGpsNotificationShown
    );
    const setWasGpsNotificationShown = useNotificationStore(
        (state) => state.setWasGpsNotificationShown
    );
    const { start: startNotificationTimeout, clear: clearNotificationTimeout } =
        useTimeout(() => {
            notifications.show(NO_GPS_NOTIFICATION);
            setWasGpsNotificationShown(true);
        }, LONG_NOTIFICATION_TIMEOUT);
    useEffect(() => {
        if (!wasGpsNotificationShown && !validPosition) {
            startNotificationTimeout();
        } else if (validPosition) {
            clearNotificationTimeout();
            notifications.hide(NO_GPS_NOTIFICATION.id);
            setWasGpsNotificationShown(false);
        }
    }, [
        clearNotificationTimeout,
        setWasGpsNotificationShown,
        startNotificationTimeout,
        validPosition,
        wasGpsNotificationShown,
    ]);

    return validPosition ? coords : null;
};

export default useCurrentPosition;
