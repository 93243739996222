import React, { ReactElement, useCallback, useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { Marker } from "react-leaflet";
import L from "leaflet";
import { useMantineTheme } from "@mantine/core";
import {
    Facility,
    getFacilityMarkerIconSize,
    isToilet,
} from "../../utilities/client/facility";
import useFacilityStore from "../../stores/facility.store";
import StaticMapIcon from "../SvgIcon/StaticMapIcon/StaticMapIcon";
import { useDisplayModeStore } from "../../stores/displayModeSettings.store";

type FacilityMarkerProps = {
    facilityData: Facility;
    transparencyConfig: {
        initialStateTransparency: number;
        isMarkerTransparent?: boolean;
        transparency: number;
    };
};

function FacilityMarker({
    facilityData,
    transparencyConfig,
}: FacilityMarkerProps): ReactElement {
    const selectedFacility = useFacilityStore(
        (state) => state.selectedFacility
    );
    const setSelectedFacility = useFacilityStore(
        (state) => state.setSelectedFacility
    );

    const theme = useMantineTheme();
    const displayMode = useDisplayModeStore((state) => state.displayMode);
    const markerRef = useRef<L.Marker>(null);

    const iconSize = getFacilityMarkerIconSize(facilityData);
    const getIconColor = () => {
        if (isToilet(facilityData) && facilityData.toiletType === "WALL") {
            return displayMode === "dark"
                ? theme.colors.dark[0]
                : theme.colors.dark[9];
        }
        return displayMode === "dark"
            ? theme.colors.light[5]
            : theme.colors.dark[9];
    };
    const iconColor = getIconColor();

    const icon = (
        <StaticMapIcon
            facility={facilityData}
            color={iconColor}
            displayMode={displayMode}
        />
    );

    const mapIcon = new L.DivIcon({
        html: renderToString(icon),
        iconSize: new L.Point(iconSize, iconSize),
        iconAnchor: [iconSize / 2, iconSize],
        className: "",
    });

    const change = useCallback(() => {
        setSelectedFacility?.(facilityData);
    }, [setSelectedFacility, facilityData]);

    const selectedFacilityId = selectedFacility?.id;
    const facilityDataId = facilityData.id;
    useEffect(() => {
        if (selectedFacilityId === facilityDataId && markerRef.current) {
            markerRef.current.fire("click");
        }
    }, [facilityDataId, selectedFacilityId]);

    const setMarkerTransparency = (): number => {
        if (transparencyConfig.isMarkerTransparent || selectedFacility) {
            if (selectedFacility?.id !== facilityData.id) {
                return transparencyConfig.transparency;
            }
        }
        return transparencyConfig.initialStateTransparency;
    };

    return (
        <Marker
            ref={markerRef}
            position={{
                lng: facilityData.geolocation.coordinates[0],
                lat: facilityData.geolocation.coordinates[1],
            }}
            icon={mapIcon}
            alt={`position-marker-${facilityData.id}`}
            opacity={setMarkerTransparency()}
            eventHandlers={{
                click: change,
            }}
        />
    );
}

export default FacilityMarker;
