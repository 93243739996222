import { notifications } from "@mantine/notifications";
import { useQueries } from "@tanstack/react-query";
import { useEffect } from "react";
import { MAP_ERROR_NOTIFICATION } from "../notifications";
import useNotificationStore from "../../../stores/notification.store";

const TILE_SERVER_HEALTH_URLS = [
    "https://a-tiles.bvg.de/health",
    "https://b-tiles.bvg.de/health",
    "https://c-tiles.bvg.de/health",
];

const HEALTHY_RESPONSE = "OK";

const useMapTilesHealthCheck = () => {
    const fetch = async (url: string) => {
        const response = await window.fetch(url);
        return response.text();
    };

    const queries = TILE_SERVER_HEALTH_URLS.map((url) => {
        return {
            queryKey: ["TILE_HEALTH", url],
            queryFn: () => fetch(url),
        };
    });

    const results = useQueries({ queries });
    const isLoading = results.some((result) => result.isLoading);
    const isError = results.some((result) => result.isError);

    const wasMapErrorNotificationShown = useNotificationStore(
        (state) => state.wasMapErrorNotificationShown
    );
    const setWasMapErrorNotificationShown = useNotificationStore(
        (state) => state.setWasMapErrorNotificationShown
    );
    useEffect(() => {
        if (!isLoading) {
            const isHealthy = results.every(
                (result) => result.data === HEALTHY_RESPONSE
            );
            if (!wasMapErrorNotificationShown && (isError || !isHealthy)) {
                notifications.show(MAP_ERROR_NOTIFICATION);
                setWasMapErrorNotificationShown(true);
            }
            // we do not remove this notification, because the tiles do not reload automatically
        }
    }, [
        isError,
        isLoading,
        results,
        setWasMapErrorNotificationShown,
        wasMapErrorNotificationShown,
    ]);
};

export default useMapTilesHealthCheck;
