import { useEffect } from "react";
import useGeolocationStore from "../../../stores/geolocation.store";
import useIntroductionStore from "../../../stores/introduction.store";

const useLocationPermission = (): boolean => {
    const locationGranted = useIntroductionStore(
        (state) => state.locationGranted
    );

    const permission = useGeolocationStore((state) => state.permission);
    const setPermission = useGeolocationStore((state) => state.setPermission);
    const setPermissionState = useGeolocationStore(
        (state) => state.setPermissionState
    );

    useEffect(() => {
        (async () => {
            const status = await navigator.permissions.query({
                name: "geolocation",
            });

            if (status.state === "granted") {
                setPermission(true);
            } else if (status.state === "prompt") {
                setPermission(locationGranted);
            } else if (status.state === "denied") {
                setPermission(false);
            }
            setPermissionState(status.state);
        })();
    }, [permission, setPermission, setPermissionState, locationGranted]);

    return permission;
};

export default useLocationPermission;
