import {
    Container,
    Stack,
    Text,
    Group,
    Button,
    Checkbox,
    List,
} from "@mantine/core";
import Lottie from "lottie-react";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import keyPermissionAnimation from "../../../../assets/animations/key-permission.json";
import useIntroductionStore from "../../../../stores/introduction.store";

import useKeyPermissionDialogStyles from "./KeyPermissionDialog.styles";

type KeyPermissionDialogProps = {
    usedInIntro?: boolean;
};

function KeyPermissionDialog({
    usedInIntro = true,
}: KeyPermissionDialogProps): ReactElement {
    const { classes } = useKeyPermissionDialogStyles();
    const { t } = useTranslation();

    const isConfirmKey = useIntroductionStore((state) => state.isConfirmKey);
    const setIsConfirmKey = useIntroductionStore(
        (state) => state.setIsConfirmKey
    );
    const setApplyKey = useIntroductionStore((state) => state.setApplyKey);
    const setActive = useIntroductionStore((state) => state.setActive);
    const setHasSurfaceKey = useIntroductionStore(
        (state) => state.setHasSurfaceKey
    );
    const hasSurfaceKey = useIntroductionStore((state) => state.hasSurfaceKey);
    const hasUndergroundKey = useIntroductionStore(
        (state) => state.hasUndergroundKey
    );
    const setHasUndergroundKey = useIntroductionStore(
        (state) => state.setHasUndergroundKey
    );

    const [value, setValue] = useState<string[]>(() => {
        const a = [];
        if (hasSurfaceKey) {
            a.push("surface");
        }
        if (hasUndergroundKey) {
            a.push("underground");
        }
        return a;
    });

    // decides when to show the toast
    useEffect(() => {
        const a = [];
        if (hasSurfaceKey) {
            a.push("surface");
        }
        if (hasUndergroundKey) {
            a.push("underground");
        }
        setValue(a);
    }, [hasSurfaceKey, hasUndergroundKey]);

    const confirmKey = () => {
        setIsConfirmKey(!isConfirmKey);
    };

    const back = () => {
        setIsConfirmKey(false);
        setValue([]);
    };

    const applyKey = () => {
        setIsConfirmKey(false);
        setActive(1);
        if (!isConfirmKey) {
            setHasUndergroundKey(false);
            setHasSurfaceKey(false);
        } else {
            setHasUndergroundKey(value.includes("underground"));
            setHasSurfaceKey(value.includes("surface"));
        }
        setApplyKey(true);
    };

    const keySelectionCheckbox = (
        type: "surface" | "underground",
        header: string,
        keyTypes: string[]
    ) => {
        return (
            <Checkbox
                labelPosition="left"
                label={
                    <div className={classes.checkboxLabel}>
                        <Text weight="bold">{header}</Text>

                        <ul className={classes.keyDetailsList}>
                            {keyTypes.map((keysType) => (
                                <li
                                    className={classes.keyDetailsListElement}
                                    key={keysType}
                                >
                                    {keysType}
                                </li>
                            ))}
                        </ul>
                    </div>
                }
                value={type}
                classNames={{ body: classes.checkboxBody }}
            />
        );
    };

    return (
        <Container fluid={false}>
            <Stack className={classes.dialog}>
                {usedInIntro && (
                    <Group position="center">
                        <Lottie
                            style={{ maxWidth: 140 }}
                            animationData={keyPermissionAnimation}
                            loop={false}
                        />
                    </Group>
                )}
                {usedInIntro && <Text>{t("keyPermissionDialog.regards")}</Text>}
                <Text size="md" inline>
                    {t("keyPermissionDialog.description1")}
                </Text>
                {usedInIntro && (
                    <Text size="md" inline>
                        {t("keyPermissionDialog.description2")}
                    </Text>
                )}

                {!usedInIntro && (
                    <div>
                        <Text size="md" inline mb={6}>
                            {t("keyPermissionDialog.ownedKeys")}
                        </Text>
                        {hasSurfaceKey || hasUndergroundKey ? (
                            <List withPadding>
                                {hasSurfaceKey && (
                                    <List.Item>
                                        <Text size="md" inline>
                                            {t(
                                                "keyPermissionDialog.surfaceKey"
                                            )}
                                        </Text>
                                    </List.Item>
                                )}
                                {hasUndergroundKey && (
                                    <List.Item>
                                        <Text size="md" inline>
                                            {t(
                                                "keyPermissionDialog.undergroundKey"
                                            )}
                                        </Text>
                                    </List.Item>
                                )}
                            </List>
                        ) : (
                            <Text inline italic ml={8}>
                                {t("keyPermissionDialog.noOwnedKeys")}
                            </Text>
                        )}
                    </div>
                )}
                {!isConfirmKey ? (
                    <>
                        <Text weight="bold" mt={8}>
                            {t("keyPermissionDialog.keyQuestion")}
                        </Text>
                        <Button onClick={() => confirmKey()}>
                            {t("keyPermissionDialog.btn.confirmKeyBtn")}
                        </Button>
                        <Button variant="outline" onClick={() => applyKey()}>
                            {t("keyPermissionDialog.btn.denyKeyBtn")}
                        </Button>
                    </>
                ) : (
                    <>
                        <Text weight={700} mt={8}>
                            {t("keyPermissionDialog.keyConfirmation")}
                        </Text>
                        <Checkbox.Group
                            size="md"
                            py="sm"
                            value={value}
                            onChange={setValue}
                            className={classes.checkboxGroup}
                            style={{
                                listStyleType: "none",
                            }}
                        >
                            <Stack spacing="md">
                                {keySelectionCheckbox(
                                    "surface",
                                    t(
                                        "keyPermissionDialog.checkBox.surface.header"
                                    ),
                                    t(
                                        "keyPermissionDialog.checkBox.surface.keyTypes",
                                        {
                                            returnObjects: true,
                                        }
                                    )
                                )}
                                {keySelectionCheckbox(
                                    "underground",
                                    t(
                                        "keyPermissionDialog.checkBox.underground.header"
                                    ),
                                    [
                                        t(
                                            "keyPermissionDialog.checkBox.underground.keyType"
                                        ),
                                    ]
                                )}
                            </Stack>
                        </Checkbox.Group>
                        <Button onClick={() => applyKey()}>
                            {t("keyPermissionDialog.btn.apply")}
                        </Button>
                        <Button variant="outline" onClick={() => back()}>
                            {t("keyPermissionDialog.btn.back")}
                        </Button>
                    </>
                )}
            </Stack>
        </Container>
    );
}

export default KeyPermissionDialog;
