import { Stack } from "@mantine/core";
import React, { ReactElement } from "react";
import useIssueReportStyles from "./IssueReport.styles";
import GeneralInformation from "./ReportTypes/GeneralInformation";
import BvgPropertyReport from "./ReportTypes/BvgPropertyReport";
import GeneralReport from "./ReportTypes/GeneralReport/GeneralReport";
import BuReport from "./ReportTypes/BuReport";
import { Facility, isBvgProperty } from "../../../utilities/client/facility";
import { BvgProperty, Toilet } from "../../../utilities/api/wcAppSchemas";
import BottomSheet from "../../BottomSheet/BottomSheet";
import WallToiletReport from "./ReportTypes/WallToiletReport/WallToiletReport";

type IssueReport = {
    isOpen: boolean;
    facility: Facility;
    isIssueListOpen?: boolean;
    onClose: () => void;
};

const FF_ISSUE_REPORT = process.env.REACT_APP_FF_ISSUE_REPORT === "true";
export const ICON_SIZE = 18;

function IssueReport({
    isOpen,
    facility,
    isIssueListOpen = false,
    onClose,
}: IssueReport): ReactElement {
    const { classes } = useIssueReportStyles();

    function getIssueReportType() {
        if (isBvgProperty(facility)) {
            return (
                <BvgPropertyReport
                    classes={classes}
                    property={facility as BvgProperty}
                    closeBottomSheet={onClose}
                />
            );
        }

        // facility is toilet
        const toilet = facility as Toilet;
        if (toilet.toiletType === "WALL") {
            return (
                <WallToiletReport toilet={toilet} closeBottomSheet={onClose} />
            );
        }

        if (toilet.toiletType === "BO/BS") {
            // use sapEqNumber as feature flag for frontend to disable
            // BS reporting while FF_ISSUE_REPORT_BS (backend) is false
            // --> sapEqNumber always null
            if (FF_ISSUE_REPORT && toilet.sapEqNumber) {
                return (
                    <GeneralReport
                        toilet={toilet}
                        isIssueListOpen={isIssueListOpen}
                        closeBottomSheet={onClose}
                    />
                );
            }
        }

        if (toilet.toiletType === "BO") {
            if (FF_ISSUE_REPORT && toilet.sapEqNumber) {
                return (
                    <GeneralReport
                        toilet={toilet}
                        isIssueListOpen={isIssueListOpen}
                        closeBottomSheet={onClose}
                    />
                );
            }
        }

        if (toilet.toiletType === "BU") {
            return (
                <BuReport
                    classes={classes}
                    toilet={toilet}
                    closeBottomSheet={onClose}
                />
            );
        }

        return (
            <GeneralInformation
                classes={classes}
                toilet={toilet}
                closeBottomSheet={onClose}
            />
        );
    }

    return (
        <BottomSheet
            isOpen={isOpen}
            onClose={onClose}
            closeOnBackdropClick={false}
            closeOnDrag={false}
        >
            <Stack className={classes.content} spacing={0} align="center">
                {getIssueReportType()}
            </Stack>
        </BottomSheet>
    );
}

export default IssueReport;
