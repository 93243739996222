import { createStyles } from "@mantine/core";

const useNavbarStyles = createStyles((theme) => ({
    header: {
        alignItems: "center",
        boxSizing: "border-box",
        display: "flex",
        gap: "15px",
        maxHeight: `${theme.other.appShellHeaderHeight}px`,
        padding: "10px",
        ".heading": {
            display: "inline",
            fontSize: theme.headings.sizes.h1.fontSize,
        },
        ".logo": {
            height: "35px",
            width: "35px",
        },
    },
    root: {
        height: "100%",
        maxWidth: "350px",
        position: "fixed",
        top: 0,
        transition: "transform 0.2s",
        width: "80%",
        zIndex: theme.other.zIndex.navbar,
        padding:
            "env(safe-area-inset-top) 0 env(safe-area-inset-bottom) env(safe-area-inset-left)",
    },
    rootHidden: {
        transform: "translateX(-100%)",
    },
    stack: {
        boxSizing: "border-box",
        overflow: "auto",
        padding: "1rem",
        paddingRight: "2rem",
        transition: "opacity 0.3s ease-in-out",
        width: "100%",
        height: "100%",
    },
    linkListWrapper: {
        height: "100%",
    },
    version: {
        color: "gray",
    },
}));

export default useNavbarStyles;
