import { createStyles } from "@mantine/core";

const useBottomSheetStyles = createStyles((theme) => ({
    sheet: {
        borderTopRightRadius: "15px !important",
        borderTopLeftRadius: "15px !important",
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
        left: 0,
        right: 0,
        // react-modal-sheet applies a white background via the style attribute, that needs override via !important"
        backgroundColor:
            theme.colorScheme === "dark"
                ? `${theme.colors.dark[7]} !important`
                : `${theme.colors.light[0]} !important`,
    },

    header: {
        width: "100%",
        paddingTop: "12px",
    },

    handle: {
        margin: "0 auto",
        border: "none",
        borderRadius: "5px",
        width: "50px",
        height: "4px",
        backgroundColor: theme.colors.dark[0],
    },

    container: {
        padding: "0 10px",
    },

    backdrop: {
        backgroundColor: "rgba(0,0,0,.6) !important",
    },

    noBackdrop: {
        display: "none",
    },
}));

export default useBottomSheetStyles;
