import { createStyles } from "@mantine/core";

const useGeolocationPermissionDialogStyles = createStyles((theme) => ({
    body: {
        justifyContent: "center",
        maxHeight: "calc(100vh - 100px)",
        fontSize: theme.fontSizes.md,
        lineHeight: "120%",
        paddingBottom: "1rem", // The button is cut off on Android devices
    },
    header: {
        justifyContent: "center",
    },
    title: {
        alignContent: "center",
        fontWeight: "bold",
        fontSize: theme.fontSizes.lg,
    },
    link: {
        "&:hover": {
            color: theme.colors.bvg[8],
            cursor: "pointer",
        },
    },
}));

export default useGeolocationPermissionDialogStyles;
