import React, { ReactElement } from "react";
import { Accordion, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import faqAnimation from "../../../assets/animations/faq.json";

type AccordionItemProps = {
    question: string;
    answers: string[];
};

type AccordionGroupProps = {
    headLine: string;
    questionsAndAnswers: {
        question: string;
        answers: string[];
    }[];
    isFirstElement?: boolean;
};

const REACT_APP_FF_FAQ_FOR_RELEASE_2 =
    process.env.REACT_APP_FF_FAQ_FOR_RELEASE_2 === "true";

function AccordionItem({ question, answers }: AccordionItemProps) {
    return (
        <Accordion.Item value={question} key={question}>
            <Accordion.Control>
                <Text style={{ fontWeight: "bold" }}>{question}</Text>
            </Accordion.Control>
            <Accordion.Panel>
                {answers.map((answer) => (
                    <Text
                        key={`${answer}`}
                        style={{
                            marginBottom:
                                answers.map.length !== 1 ? "0px" : "16px",
                        }}
                    >
                        {answer}
                    </Text>
                ))}
            </Accordion.Panel>
        </Accordion.Item>
    );
}

function AccordionGroup({
    headLine,
    questionsAndAnswers,
    isFirstElement,
}: AccordionGroupProps) {
    const accordionsGroup = questionsAndAnswers.map((item) => (
        <AccordionItem
            question={item.question}
            answers={item.answers}
            key={item.question}
        />
    ));

    accordionsGroup.unshift(
        <Accordion.Item value="" key={headLine}>
            <Text
                style={{
                    marginTop: isFirstElement ? "0px" : "24px",
                    marginBottom: "16px",
                }}
                size="lg"
            >
                {headLine}
            </Text>
        </Accordion.Item>
    );

    return <div>{accordionsGroup}</div>;
}

function FrequentlyAskedQuestions(): ReactElement {
    const { t } = useTranslation();

    return (
        <Stack
            spacing="lg"
            justify="flex-start"
            align="center"
            style={{ height: "100%", padding: "24px" }}
        >
            <Lottie
                style={{ maxWidth: 280, margin: -56 }}
                animationData={faqAnimation}
                loop={false}
            />
            <Stack justify="flex-start">
                <Accordion
                    chevronPosition="right"
                    style={{ marginBottom: "28px" }}
                >
                    <AccordionGroup
                        headLine={t(
                            "pages.frequentlyAskedQuestions.generalQuestions.headline"
                        )}
                        questionsAndAnswers={t(
                            "pages.frequentlyAskedQuestions.generalQuestions.faq",
                            {
                                returnObjects: true,
                            }
                        )}
                        isFirstElement
                    />
                    <AccordionGroup
                        headLine={t(
                            "pages.frequentlyAskedQuestions.newUser.headline"
                        )}
                        questionsAndAnswers={t(
                            "pages.frequentlyAskedQuestions.newUser.faq",
                            {
                                returnObjects: true,
                            }
                        )}
                    />
                    <AccordionGroup
                        headLine={t(
                            "pages.frequentlyAskedQuestions.individualFunctionalities.headline"
                        )}
                        questionsAndAnswers={t(
                            "pages.frequentlyAskedQuestions.individualFunctionalities.faq",
                            {
                                returnObjects: true,
                            }
                        )}
                    />
                    {REACT_APP_FF_FAQ_FOR_RELEASE_2 && (
                        <AccordionGroup
                            headLine={t(
                                "pages.frequentlyAskedQuestions.createIssues.headline"
                            )}
                            questionsAndAnswers={t(
                                "pages.frequentlyAskedQuestions.createIssues.faq",
                                {
                                    returnObjects: true,
                                }
                            )}
                        />
                    )}
                </Accordion>
            </Stack>
        </Stack>
    );
}

export default FrequentlyAskedQuestions;
