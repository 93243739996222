import { create } from "zustand";
import { persist } from "zustand/middleware";

export type reportIssueLimit = {
    remainingRequests: number;
    timeStamp: number;

    reduceRemainingRequests: () => void;
    setReportRestriction: () => boolean;
    compareRemainingRequests: () => boolean;
};

const {
    REACT_APP_MAX_LIMIT_ISSUE_REPORTS,
    REACT_APP_TIME_LIMIT_MINUTES_FOR_REPORTS,
} = process.env;

const MAX_LIMIT_ISSUE_REPORTS = Number(REACT_APP_MAX_LIMIT_ISSUE_REPORTS);
const TIME_LIMIT_MINUTES_FOR_REPORTS = Number(
    REACT_APP_TIME_LIMIT_MINUTES_FOR_REPORTS
);

const reportIssueLimit = create<reportIssueLimit>()(
    persist(
        (set, get) => ({
            //----------
            // states
            //----------

            remainingRequests: MAX_LIMIT_ISSUE_REPORTS,
            timeStamp: 0,

            //----------
            // actions
            //----------

            reduceRemainingRequests: () => {
                const { remainingRequests } = get();
                if (remainingRequests === MAX_LIMIT_ISSUE_REPORTS) {
                    set(() => ({
                        timeStamp: new Date().getTime(),
                    }));
                }
                set((state: { remainingRequests: number }) => ({
                    remainingRequests: state.remainingRequests - 1,
                }));
            },

            setReportRestriction: () => {
                const timeStampNow = new Date().getTime();
                const { timeStamp } = get();
                if (
                    timeStampNow - timeStamp <
                    TIME_LIMIT_MINUTES_FOR_REPORTS * 60000
                ) {
                    const { remainingRequests } = get();
                    if (remainingRequests <= 0) {
                        return true;
                    }
                    return false;
                }
                set(() => ({
                    remainingRequests: MAX_LIMIT_ISSUE_REPORTS,
                }));
                return false;
            },

            compareRemainingRequests: () => {
                const { remainingRequests } = get();
                if (remainingRequests === MAX_LIMIT_ISSUE_REPORTS) {
                    return true;
                }
                return false;
            },
        }),
        {
            name: "reportIssueLimit-storage",
        }
    )
);

export default reportIssueLimit;
