import L from "leaflet";
import { Facility } from "./facility";

export const generateHumanReadableDistance = (distance: number) => {
    let distanceString = `${distance} m`;
    if (distance > 1000) {
        const km = Math.round(distance / 100) / 10;
        const kmString = km.toString().replace(".", ",");
        distanceString = `${kmString} km`;
    }
    return distanceString;
};

export const generateHumanReadableDuration = (duration: number | null) => {
    if (duration || duration === 0) {
        const minutes = Math.round(duration / 60);

        let durationString = duration < 60 ? "<1 min" : `${minutes} min`;
        if (minutes > 20) {
            durationString = `+20 min`;
        }
        return durationString;
    }
    return "";
};

export const calculateBeeline = (
    facility: Facility,
    position: L.LatLngExpression
) => {
    return Math.trunc(
        L.latLng(position).distanceTo({
            lng: facility.geolocation.coordinates[0],
            lat: facility.geolocation.coordinates[1],
        })
    );
};

export default generateHumanReadableDistance;
