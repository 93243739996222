import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { DirectionRequestBody } from "../../../utilities/api/wcAppSchemas";
import { currentPositionToLeaflet } from "../../../utilities/client/map";
import useCurrentPosition from "../../../utilities/client/hooks/useCurrentPosition";
import useApplicationStore from "../../../stores/application.store";
import useMapStore from "../../../stores/map.store";
import useFacilityStore from "../../../stores/facility.store";
import useGetAccessToken from "../../../utilities/client/hooks/useGetAccessToken";
import useGetDirectionsMutation from "../../../utilities/client/hooks/useGetDirectionsMutation";

export const DEFAULT_FLYTO_ZOOM = 16;

function MapNavigator(): null {
    const currentPosition = useCurrentPosition({ minChangeRadius: 5 });
    const [isInitialFlyToDone, setIsInitialFlyToDone] = useState(false);

    const isInNavigationMode = useApplicationStore(
        (state) => state.isInNavigationMode
    );

    const hasMoved = useMapStore((state) => state.hasMoved);
    const reCenterMap = useMapStore((state) => state.reCenterMap);

    const selectedFacility = useFacilityStore(
        (state) => state.selectedFacility
    );
    const setSelectedFacilityPathCoordinates = useFacilityStore(
        (state) => state.setSelectedFacilityPathCoordinates
    );
    const mapContainerRef = useMap();

    const { getDirections } = useGetDirectionsMutation();
    const accessToken = useGetAccessToken();

    // map effects
    useEffect(() => {
        const location = currentPositionToLeaflet(currentPosition);
        if (mapContainerRef && selectedFacility) {
            const facilityPosition = L.latLng(
                selectedFacility.geolocation.coordinates[1],
                selectedFacility.geolocation.coordinates[0]
            );
            if (location) {
                if (!isInNavigationMode) {
                    reCenterMap(selectedFacility, currentPosition);
                }
            } else {
                mapContainerRef.flyTo(facilityPosition, DEFAULT_FLYTO_ZOOM, {
                    duration: 0.75,
                });
            }
            setIsInitialFlyToDone(true);
        }

        if (location && isInitialFlyToDone === false) {
            mapContainerRef.flyTo(location, DEFAULT_FLYTO_ZOOM);
            setIsInitialFlyToDone(true);
        }
        // follow current marker position if navigation mode is on and map hasnt been moved
        if (currentPosition && isInNavigationMode && !hasMoved) {
            reCenterMap(selectedFacility, currentPosition);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPosition,
        // hasMoved, // line has to be disabled - caused in navigation recenter issue (WA-488)
        isInNavigationMode,
        isInitialFlyToDone,
        mapContainerRef,
        reCenterMap,
        selectedFacility,
    ]);

    // fetch path for selected facility
    const toPointLng = selectedFacility?.geolocation.coordinates[0];
    const toPointLat = selectedFacility?.geolocation.coordinates[1];
    useEffect(() => {
        const location = currentPositionToLeaflet(currentPosition);
        if (location && toPointLng && toPointLat && accessToken) {
            const fromPoint = L.latLng(location);
            const toPoint = L.latLng({
                lng: toPointLng,
                lat: toPointLat,
            });
            const directionsParams: DirectionRequestBody = {
                coordinates: [
                    [fromPoint.lng, fromPoint.lat],
                    [toPoint.lng, toPoint.lat],
                ],
                preference: "shortest",
                instructions: false,
            };

            getDirections(
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                    body: directionsParams,
                },
                {
                    onSuccess: (data) =>
                        setSelectedFacilityPathCoordinates?.(
                            data.features[0].geometry.coordinates || []
                        ),
                }
            );
        } else {
            setSelectedFacilityPathCoordinates?.([]);
        }
    }, [
        accessToken,
        currentPosition,
        getDirections,
        setSelectedFacilityPathCoordinates,
        toPointLat,
        toPointLng,
    ]);

    return null;
}

export default MapNavigator;
