import React, { ReactElement } from "react";
import { Stack } from "@mantine/core";
import useNavbarLinkListStyles from "./NavbarLinkList.styles";
import navbarLinks from "../../../config/navigation/navbar-link-lists";
import NavBarLink, { NavBarLinkProps } from "./NavBarLink/NavBarLink";
import useUserIssueReportsStore from "../../../stores/userIssueReports.store";

function NavbarLinkList(): ReactElement {
    const { classes } = useNavbarLinkListStyles();

    const hasUnreadUserIssueUpdates = useUserIssueReportsStore(
        (state) => state.hasUnreadUserIssueUpdates
    );
    // use state.userIssueUpdates to trigger updates
    useUserIssueReportsStore((state) => state.userIssueUpdates);

    const renderLinks = (linkList: NavBarLinkProps[]) => {
        return linkList.map((child) => {
            return (
                <NavBarLink
                    key={child.label}
                    href={child.href}
                    icon={child.icon}
                    label={child.label}
                    method={child.method}
                    rightSection={child.rightSection}
                    showNewsIndicator={child.showNewsIndicator}
                >
                    {child.children}
                </NavBarLink>
            );
        });
    };

    return (
        <Stack className={classes.stack}>
            <Stack key="links">
                {renderLinks(navbarLinks(hasUnreadUserIssueUpdates()))}
            </Stack>
        </Stack>
    );
}

export default NavbarLinkList;
