import { createStyles } from "@mantine/core";

const useIssueListEntryStyles = createStyles((theme) => ({
    yellowBackground: {
        padding: "8px 12px",
        backgroundColor: "#FBF5C7",
        borderRadius: "4px",
    },
    text: {
        color: theme.colors.dark[9], // also for dark mode
    },
    statusDone: {
        color: theme.colors.green[9],
    },
    statusIncomplete: {
        color: theme.colors.red[9],
    },
    closeButton: {
        position: "absolute",
        top: "-8px",
        right: "-8px",

        svg: {
            stroke: "white",
            strokeWidth: "1px",
        },
    },
}));

export default useIssueListEntryStyles;
