import React, { ReactElement, useState } from "react";
import {
    Button,
    Divider,
    Group,
    Stack,
    Text,
    Radio,
    Tooltip,
    Box,
} from "@mantine/core";
import { useTranslation, Trans } from "react-i18next";
import { useAccount } from "@azure/msal-react";
import { ReportForm, stepIndexHandlerType } from "../GeneralReport";
import BottomSheetHeader from "../../../../../BottomSheetHeader/BottomSheetHeader";
import BottomSheetFooter from "../../../../../BottomSheetFooter/BottomSheetFooter";
import reportIssueLimit from "../../../../../../stores/reportIssueLimit.store";

type Step2Props = {
    form: ReportForm;
    stepIndexHandler: stepIndexHandlerType;
    abort: () => void;
};

function Step2({ form, stepIndexHandler, abort }: Step2Props): ReactElement {
    const { t } = useTranslation();
    /* the state is string and not boolean, because of the handling of the value for <Radio.Group> */
    const [isAnonym, setIsAnonym] = useState("true");
    const setReportRestriction = reportIssueLimit(
        (state) => state.setReportRestriction
    );
    const isReportsRestrictionSet = setReportRestriction();
    const account = useAccount();
    if (!account) {
        return <>Loading...</>;
    }
    const contactDataSubmitHandler = () => {
        if (isAnonym === "true") {
            form.setFieldValue("email", null);
        } else {
            const email = account.username ?? "noEmailFound";
            form.setFieldValue("email", email);
        }
        stepIndexHandler.increment();
    };

    return (
        <>
            <BottomSheetHeader
                title={t("issueReport.general.stepTitle.addContactDataTitle")}
            />
            <Stack align="stretch" px={8} spacing={0}>
                <Group spacing={0} mt={16}>
                    <Text weight="bold" mr={4}>
                        {t("issueReport.general.locationDetailLabel")}
                    </Text>
                    <Text>
                        {t("issueReport.general.locationDetailValue", {
                            value: form.values.toiletObjectNumber,
                        })}
                    </Text>
                </Group>
                <Text mt={12} weight="bold">
                    {t("issueReport.general.subHeadings.howToReportIssue")}
                </Text>
                <Divider size="xs" />
                <Radio.Group value={isAnonym} onChange={setIsAnonym}>
                    <Stack spacing={0} m="4px 8px">
                        <Text weight="bold" mt={4}>
                            {t("issueReport.general.subHeadings.anonymous")}
                        </Text>

                        <Radio
                            mt={-2}
                            mb={8}
                            value="true"
                            label={
                                <Text size="md" w="auto" maw={600}>
                                    <Trans i18nKey="issueReport.general.anonymOrContactInfoText.anonymous" />
                                </Text>
                            }
                            labelPosition="left"
                        />

                        <Text weight="bold">
                            {t(
                                "issueReport.general.subHeadings.contactDetails"
                            )}
                        </Text>

                        <Radio
                            mt={-2}
                            value="false"
                            label={
                                <Text size="md" w="auto" maw={600}>
                                    {t(
                                        "issueReport.general.anonymOrContactInfoText.giveContact"
                                    )}
                                </Text>
                            }
                            labelPosition="left"
                        />
                    </Stack>
                </Radio.Group>
            </Stack>

            <BottomSheetFooter>
                <Stack spacing={8}>
                    {isAnonym === "true" && isReportsRestrictionSet ? (
                        <Tooltip
                            label={t("issueReport.general.reachedReportLimit")}
                            color="black"
                            withArrow
                            offset={-12}
                            position="bottom"
                            events={{ hover: true, focus: false, touch: true }}
                        >
                            <Box component="div">
                                <Button w="100%" disabled>
                                    {t("issueReport.general.btn.continue")}
                                </Button>
                            </Box>
                        </Tooltip>
                    ) : (
                        <Button onClick={contactDataSubmitHandler}>
                            {t("issueReport.general.btn.continue")}
                        </Button>
                    )}

                    <Button
                        onClick={stepIndexHandler.decrement}
                        variant="outline"
                    >
                        {t("issueReport.general.btn.back")}
                    </Button>
                    <Button onClick={abort} variant="outline">
                        {t("issueReport.general.btn.abort")}
                    </Button>
                </Stack>
            </BottomSheetFooter>
        </>
    );
}

export default Step2;
