import { createStyles } from "@mantine/core";

const usePrivacyPolicyStyles = createStyles(() => ({
    content: {
        marginBottom: "8px",
        width: "100%",
    },

    text: {
        marginBottom: "16px",
    },
    textHead: {
        fontWeight: "bold",
    },
    bulletPoints: {
        lineHeight: "140%",
    },
    footer: {
        borderTop: "1px solid #E5E6E7",
        backgroundColor: "white",
        position: "fixed",
        bottom: "0",
        padding: "0 24px",
        minHeight: 72,
        width: "100%",
    },
}));

export default usePrivacyPolicyStyles;
