import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export type IntroductionStore = {
    locationPermissionDone: boolean;
    locationGranted: boolean;
    toggleLocationPermissionDone: () => void;

    hasSurfaceKey: boolean;
    hasUndergroundKey: boolean;
    isConfirmKey: boolean;
    isApplyKey: boolean;
    active: number;
    isBackFormLocationPermission: boolean;

    setIsConfirmKey: (confirmKey: boolean) => void;
    setHasSurfaceKey: (confirmKey: boolean) => void;
    setHasUndergroundKey: (confirmKey: boolean) => void;
    setApplyKey: (denyKey: boolean) => void;
    setActive: (activ: number) => void;
    getKeyIdsFilter: () => ("N1/XN1" | "8hk8258")[] | undefined;
    onStep: () => number;
    setIsBackFormLocationPermission: (isIntroDialogDone: boolean) => void;
    setLocationGranted: (value: boolean) => void;
};

const useIntroductionStore = create<IntroductionStore>()(
    devtools(
        persist(
            (set, get) => ({
                locationPermissionDone: false,
                locationGranted: false,
                toggleLocationPermissionDone: () =>
                    set((state) => ({
                        locationPermissionDone: !state.locationPermissionDone,
                    })),

                isConfirmKey: false,
                isApplyKey: false,
                hasSurfaceKey: false,
                hasUndergroundKey: false,
                active: 0,
                isBackFormLocationPermission: false,

                getKeyIdsFilter: () => {
                    const filter: ("N1/XN1" | "8hk8258")[] = [];
                    if (get().hasSurfaceKey) {
                        filter.push("N1/XN1");
                    }
                    if (get().hasUndergroundKey) {
                        filter.push("8hk8258");
                    }
                    return filter.length ? filter : undefined;
                },

                setIsConfirmKey: (isConfirmKey: boolean) =>
                    set({ isConfirmKey }),
                setApplyKey: (isApplyKey: boolean) => set({ isApplyKey }),
                setHasSurfaceKey: (hasSurfaceKey: boolean) =>
                    set({ hasSurfaceKey }),
                setHasUndergroundKey: (hasUndergroundKey: boolean) =>
                    set({ hasUndergroundKey }),
                setActive: (active: number) => set({ active }),
                onStep: () => {
                    if (get().active === 0) {
                        set(() => ({
                            active: 1,
                            isBackFormLocationPermission: false,
                        }));
                    } else {
                        set(() => ({
                            active: 0,
                            isBackFormLocationPermission: true,
                        }));
                    }
                    return get().active;
                },

                setIsBackFormLocationPermission: (
                    isBackFormLocationPermission: boolean
                ) => set({ isBackFormLocationPermission }),
                setLocationGranted: (value) =>
                    set(() => ({
                        locationGranted: value,
                    })),
            }),
            { name: "introduction-state" }
        )
    )
);

export default useIntroductionStore;
