import { createStyles } from "@mantine/core";

const carouselHeight = "calc(100% - 220px);";

const useLocationPermissionIntroDialogStyles = createStyles((theme) => ({
    body: {
        justifyContent: "center",
    },
    container: {
        height: "100%",
    },
    header: {
        justifyContent: "center",
    },
    title: {
        alignContent: "center",
        fontWeight: "bold",
        fontSize: theme.fontSizes.lg,
    },
    descriptionText: {
        fontSize: theme.fontSizes.md,
        lineHeight: "120%",
        marginLeft: "32px",
        marginRight: "32px",
    },
    carousel: { height: carouselHeight },
    carouselElement: {
        height: "100%",
        width: 200,
        marginLeft: "auto",
        marginRight: "auto",
    },

    imageText: {
        fontSize: theme.fontSizes.md,
        fontWeight: "normal",
        lineHeight: "110%",
        marginTop: "8px",
    },
    btn: {
        marginLeft: "32px",
        marginRight: "32px",
        marginTop: "auto",
    },
}));

export default useLocationPermissionIntroDialogStyles;
