import { Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../components/Pages/DefaultPage";

function PrivacyPolicyPage() {
    const { t } = useTranslation();
    return (
        <DefaultPage title={t("pages.privacyPolicy.header")}>
            <Text>privacyPolicy</Text>
        </DefaultPage>
    );
}

export default PrivacyPolicyPage;
