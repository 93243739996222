import L, { Map } from "leaflet";
import { create } from "zustand";
import { DEFAULT_FLYTO_ZOOM } from "../components/FacilityMap/MapNavigator/MapNavigator";
import useApplicationStore from "./application.store";
import { Facility } from "../utilities/client/facility";

export type MapStore = {
    mapContainerRef: Map | null;
    hasMoved: boolean;
    setHasMoved: (moved: boolean) => void;
    setMapContainerRef: (map: Map | null) => void;
    reCenterMap: (
        selectedFacility: Facility | null,
        currentPosition: GeolocationCoordinates | null
    ) => void;
};
const useMapStore = create<MapStore>((set, get) => ({
    //----------
    // states
    //----------
    hasMoved: false,
    mapContainerRef: null,
    //----------
    // actions
    //----------

    setMapContainerRef: (mapContainerRef) => {
        return set(() => ({
            mapContainerRef,
        }));
    },
    reCenterMap(selectedFacility, currentPosition) {
        if (selectedFacility) {
            if (currentPosition) {
                const facilityPosition = L.latLng(
                    selectedFacility.geolocation.coordinates[1],
                    selectedFacility.geolocation.coordinates[0]
                );
                const bounds = L.latLngBounds(facilityPosition, [
                    currentPosition.latitude,
                    currentPosition.longitude,
                ]);
                const { isInNavigationMode } = useApplicationStore.getState();

                if (isInNavigationMode) {
                    get().mapContainerRef?.flyTo(
                        [currentPosition.latitude, currentPosition.longitude],
                        18
                    );
                } else {
                    const topPadding = 120;
                    const bottomPadding = 100;
                    get().mapContainerRef?.flyToBounds(bounds, {
                        paddingTopLeft: [40, topPadding],
                        paddingBottomRight: [90, bottomPadding],
                    });
                }
            }
        } else if (currentPosition) {
            get().mapContainerRef?.flyTo(
                [currentPosition.latitude, currentPosition.longitude],
                DEFAULT_FLYTO_ZOOM
            );
        }
        get().mapContainerRef?.once("moveend", () => {
            set(() => ({
                hasMoved: false,
            }));
        });
    },

    /**
     * Set has moved map
     * @param active {Boolean} true|false
     * @returns void
     */
    setHasMoved: (moved: boolean) => {
        return set(() => ({
            hasMoved: moved,
        }));
    },
}));

export default useMapStore;
