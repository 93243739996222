import React, { ReactElement, useEffect } from "react";
import { notifications } from "@mantine/notifications";
import imageCompression from "browser-image-compression";
import { FileInput } from "@mantine/core";
import useImageInputStyles from "./ImageInput.styles";
import {
    IMAGE_COMPRESSION_ERROR_NOTIFICATION,
    INVALID_IMAGE_TYPE_NOTIFICATION,
} from "../../utilities/client/notifications";

type ImageInputProps = {
    images: File[];
    triggerImageInput: boolean;
    onImageInputDisplayed: () => void;
    onImagesSelected: (newImages: File[]) => void;
};

const ACCEPTED_FILE_TYPE = "image/jpeg";

function ImageInput({
    images,
    triggerImageInput,
    onImageInputDisplayed,
    onImagesSelected,
}: ImageInputProps): ReactElement {
    const { classes } = useImageInputStyles();

    useEffect(() => {
        if (triggerImageInput) {
            // dev-note: react strict mode executes this twice in development environment
            document.getElementById("imageInput")?.click();
            onImageInputDisplayed();
        }
    }, [onImageInputDisplayed, triggerImageInput]);

    const imageCompressionOptions = {
        // size restricted to 2.2 MB so it stays below 3 MB after conversion to Base64
        maxSizeMB: 2.2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    };
    const handleImages = async (files: File[]) => {
        // exit early if no images were selected
        if (files === null) {
            onImagesSelected([]);
            return;
        }

        try {
            const newImages = await Promise.all(
                Array.from(files)
                    .filter((image) => image.type === ACCEPTED_FILE_TYPE)
                    .map((image) => {
                        const compressedImage = imageCompression(
                            image,
                            imageCompressionOptions
                        );

                        return compressedImage;
                    })
            );

            if (files.length !== newImages.length) {
                notifications.show(INVALID_IMAGE_TYPE_NOTIFICATION);
            }

            onImagesSelected(newImages);
        } catch (e) {
            notifications.show(IMAGE_COMPRESSION_ERROR_NOTIFICATION);
        }
    };

    return (
        <FileInput
            id="imageInput"
            accept={ACCEPTED_FILE_TYPE}
            multiple
            className={classes["image-input"]}
            value={images}
            onChange={handleImages}
        />
    );
}

export default ImageInput;
