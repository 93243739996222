import React, { ReactElement } from "react";
import {
    Text,
    Button,
    Stack,
    Group,
    Divider,
    ScrollArea,
    Skeleton,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ReportForm, stepIndexHandlerType } from "../GeneralReport";
import Tile from "../../../../../Tile/Tile";
import { IconRepairLine as IconRepair } from "../../../../../Icons/Icons";
import { ICON_SIZE } from "../../../IssueReport";
import BottomSheetHeader from "../../../../../BottomSheetHeader/BottomSheetHeader";
import BottomSheetFooter from "../../../../../BottomSheetFooter/BottomSheetFooter";
import useGetToiletIssues from "../../../../../../utilities/client/hooks/useGetToiletIssues";
import {
    OTHER_ISSUE,
    getIssueOptionsByCategory,
    hasIssueWithinDisableTimeframe,
} from "../../../../../../utilities/client/issue";
import { IssueReportOption } from "../../../../../../utilities/api/wcAppSchemas";
import useGetAllIssueReportOptions from "../../../../../../utilities/client/hooks/useGetAllIssueReportOptions";

type Step4Props = {
    form: ReportForm;
    stepIndexHandler: stepIndexHandlerType;
    abort: () => void;
};

function Step4({ form, stepIndexHandler, abort }: Step4Props): ReactElement {
    const { t } = useTranslation();
    const { data: openIssues } = useGetToiletIssues(
        form.values.toiletSapEqNumber
    );
    const { data: reportOptions, isLoading: isReportOptionsLoading } =
        useGetAllIssueReportOptions();

    const issueClickHandler = (reportOption: IssueReportOption) => {
        form.setFieldValue("issue", reportOption.issue);
        form.setFieldValue("reportOptionId", reportOption.id);
        stepIndexHandler.increment();
    };

    const selectedCategory = form.values.category;
    const issueOptions = getIssueOptionsByCategory(
        reportOptions,
        selectedCategory
    );

    const anonymOrAdded = (
        <Text>
            {form.values.email
                ? form.values.email
                : t("issueReport.general.notAdded")}
        </Text>
    );

    let issueList;
    if (isReportOptionsLoading) {
        issueList = (
            <Stack justify="flex-start" spacing={8} mt="xs">
                <Skeleton height={44} />
                <Skeleton height={44} />
            </Stack>
        );
    } else {
        issueList = (
            <ScrollArea.Autosize mah="300px" type="auto">
                <Stack spacing={8} mt="xs">
                    {issueOptions.map((issueOption) => (
                        <Tile
                            icon={<IconRepair size={ICON_SIZE} color="black" />}
                            cbOnClick={() => issueClickHandler(issueOption)}
                            key={issueOption.id}
                            align="left"
                            text={issueOption.issue}
                            disabled={
                                issueOption.issue !== OTHER_ISSUE &&
                                hasIssueWithinDisableTimeframe(
                                    openIssues,
                                    issueOption.issue
                                )
                            }
                            disabledHint={t(
                                "issueReport.general.alreadyReportedHint"
                            )}
                        />
                    ))}
                </Stack>
            </ScrollArea.Autosize>
        );
    }

    return (
        <>
            <BottomSheetHeader
                title={t("issueReport.general.stepTitle.chooseIssueTitle")}
            />
            <Stack align="stretch" px={8} spacing={0}>
                <Group spacing={0} mt={16}>
                    <Text weight="bold" mr={4}>
                        {t("issueReport.general.locationDetailLabel")}
                    </Text>
                    <Text>
                        {t("issueReport.general.locationDetailValue", {
                            value: form.values.toiletObjectNumber,
                        })}
                    </Text>
                </Group>
                <Group spacing={0}>
                    <Text weight="bold" mr={4}>
                        {t("issueReport.general.contactInformation")}
                    </Text>
                    <Text>{anonymOrAdded}</Text>
                </Group>
                <Group spacing={0}>
                    <Text weight="bold" mr={4}>
                        {t("issueReport.general.categoryLabel")}
                    </Text>
                    <Text>{selectedCategory}</Text>
                </Group>

                <Text weight="bold" mt="xl">
                    {t("issueReport.general.issue")}
                </Text>
                <Divider size="xs" />
                {issueList}
            </Stack>

            <BottomSheetFooter>
                <Stack spacing={8}>
                    <Button
                        onClick={stepIndexHandler.decrement}
                        variant="outline"
                    >
                        {t("issueReport.general.btn.back")}
                    </Button>
                    <Button onClick={abort} variant="outline">
                        {t("issueReport.general.btn.abort")}
                    </Button>
                </Stack>
            </BottomSheetFooter>
        </>
    );
}

export default Step4;
