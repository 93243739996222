import React, { ReactElement } from "react";
import {
    Button,
    Divider,
    Group,
    ScrollArea,
    Skeleton,
    Stack,
    Text,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ReportForm, stepIndexHandlerType } from "../GeneralReport";
import { IconRepairLine as IconRepair } from "../../../../../Icons/Icons";
import Tile from "../../../../../Tile/Tile";
import { ICON_SIZE } from "../../../IssueReport";
import BottomSheetHeader from "../../../../../BottomSheetHeader/BottomSheetHeader";
import BottomSheetFooter from "../../../../../BottomSheetFooter/BottomSheetFooter";
import useGetAllIssueReportOptions from "../../../../../../utilities/client/hooks/useGetAllIssueReportOptions";
import {
    getCategoryOptions,
    getIssueOptionsByCategory,
} from "../../../../../../utilities/client/issue";

type Step3Props = {
    form: ReportForm;
    stepIndexHandler: stepIndexHandlerType;
    abort: () => void;
};

function Step3({ form, stepIndexHandler, abort }: Step3Props): ReactElement {
    const { t } = useTranslation();

    const { data: reportOptions, isLoading: isReportOptionsLoading } =
        useGetAllIssueReportOptions();
    const categoryOptions = getCategoryOptions(reportOptions);

    const categoryClickHandler = (selectedCategory: string) => {
        const issueOptions = getIssueOptionsByCategory(
            reportOptions,
            selectedCategory
        );

        if (issueOptions.length === 1) {
            form.setFieldValue("category", selectedCategory);
            form.setFieldValue("issue", issueOptions[0].issue);
            form.setFieldValue("reportOptionId", issueOptions[0].id);
            stepIndexHandler.set(4);
        } else {
            form.setFieldValue("category", selectedCategory);
            stepIndexHandler.increment();
        }
    };

    const anonymOrAdded = (
        <Text>
            {form.values.email
                ? form.values.email
                : t("issueReport.general.notAdded")}
        </Text>
    );

    let categoryList;
    if (isReportOptionsLoading) {
        categoryList = (
            <Stack justify="flex-start" spacing={8} mt="xs">
                <Skeleton height={44} />
                <Skeleton height={44} />
            </Stack>
        );
    } else {
        categoryList = (
            <ScrollArea.Autosize mah="300px" type="auto">
                <Stack spacing={8} mt="xs">
                    {categoryOptions.map((categoryOption) => (
                        <Tile
                            icon={<IconRepair size={ICON_SIZE} color="black" />}
                            cbOnClick={() =>
                                categoryClickHandler(categoryOption)
                            }
                            key={categoryOption}
                            align="left"
                            text={categoryOption}
                        />
                    ))}
                </Stack>
            </ScrollArea.Autosize>
        );
    }

    return (
        <>
            <BottomSheetHeader
                title={t("issueReport.general.stepTitle.chooseCategoryTitle")}
            />
            <Stack align="stretch" px={8} spacing={0}>
                <Group spacing={0} mt={16}>
                    <Text weight="bold" mr={4}>
                        {t("issueReport.general.locationDetailLabel")}
                    </Text>
                    <Text span>
                        {t("issueReport.general.locationDetailValue", {
                            value: form.values.toiletObjectNumber,
                        })}
                    </Text>
                </Group>
                <Group spacing={0}>
                    <Text weight="bold" mr={4}>
                        {t("issueReport.general.contactInformation")}
                    </Text>
                    <Text>{anonymOrAdded}</Text>
                </Group>

                <Text weight="bold" mt="xl">
                    {t("issueReport.general.category")}
                </Text>
                <Divider size="xs" />
                {categoryList}
            </Stack>

            <BottomSheetFooter>
                <Stack spacing={8}>
                    <Button
                        onClick={stepIndexHandler.decrement}
                        variant="outline"
                    >
                        {t("issueReport.general.btn.back")}
                    </Button>
                    <Button onClick={abort} variant="outline">
                        {t("issueReport.general.btn.abort")}
                    </Button>
                </Stack>
            </BottomSheetFooter>
        </>
    );
}

export default Step3;
