import { Button, Stack, Text } from "@mantine/core";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { IssueReportStylesClasses } from "../IssueReport.styles";

import reportAnimation from "../../../../assets/animations/report.json";
import BottomSheetHeader from "../../../BottomSheetHeader/BottomSheetHeader";
import BottomSheetFooter from "../../../BottomSheetFooter/BottomSheetFooter";
import { Toilet } from "../../../../utilities/api/wcAppSchemas";

type BuReportProps = {
    classes: IssueReportStylesClasses;
    toilet: Toilet;
    closeBottomSheet: () => void;
};
function BuReport({
    classes,
    toilet,
    closeBottomSheet,
}: BuReportProps): ReactElement {
    const { t } = useTranslation();
    return (
        <>
            <BottomSheetHeader title={t("bottomSheet.reportDialog.title")} />
            <Lottie
                style={{ maxWidth: 200 }}
                animationData={reportAnimation}
                loop={false}
            />
            <Stack align="stretch" maw={800} px={8}>
                <Text className={classes.text}>
                    {t("issueReport.bu.instructions")}
                </Text>

                <Text className={classes.text}>
                    {t("issueReport.relevantInformation")}
                </Text>

                <Text className={classes.importantInformation}>
                    <Text weight="bold">{t("issueReport.bu.address")}</Text>
                    {toilet.address}
                    <Text weight="bold">
                        {t("issueReport.bu.locationDetail")}
                    </Text>
                    {toilet.objectNumber}
                    <Text weight="bold">
                        {t("issueReport.disturbanceType")}
                    </Text>
                    {t("issueReport.describeIssue")}
                </Text>

                <Text className={classes.text}>
                    {t("issueReport.acknowledgement")}
                </Text>
            </Stack>
            <BottomSheetFooter>
                <Button fullWidth onClick={() => closeBottomSheet()}>
                    {t("bottomSheet.reportDialog.confirm")}
                </Button>
            </BottomSheetFooter>
        </>
    );
}

export default BuReport;
