import React, { ReactElement } from "react";
import {
    Text,
    Stack,
    Group,
    Divider,
    Textarea,
    Tooltip,
    Box,
    Button,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import BottomSheetHeader from "../../../../../BottomSheetHeader/BottomSheetHeader";
import BottomSheetFooter from "../../../../../BottomSheetFooter/BottomSheetFooter";
import {
    Toilet,
    WallIssue,
} from "../../../../../../utilities/api/wcAppSchemas";
import { useSendWallIssue } from "../../../../../../utilities/api/wcAppComponents";
import { SEND_ISSUE_ERROR_NOTIFICATION } from "../../../../../../utilities/client/notifications";

type Step1Props = {
    toilet: Toilet;
    onSubmit: () => void;
    onAbort: () => void;
};

function Step1({ toilet, onSubmit, onAbort }: Step1Props): ReactElement {
    const { t } = useTranslation();

    const form = useForm({
        initialValues: {
            description: "",
        },
    });

    const { mutateAsync: sendWallIssue, isLoading: isIssueSending } =
        useSendWallIssue();

    const isDescriptionIssueTextAreaInputValid =
        form.values.description.length > 0;

    const submit = async () => {
        const wallIssue: WallIssue = {
            toiletObjectNumber: toilet.objectNumber,
            address: toilet.address,
            description: form.values.description,
        };
        sendWallIssue({ body: wallIssue })
            .then(() => {
                onSubmit();
            })
            .catch(() => notifications.show(SEND_ISSUE_ERROR_NOTIFICATION));
    };

    return (
        <>
            <BottomSheetHeader title={t("bottomSheet.reportDialog.title")} />

            <Stack align="stretch" px={8} spacing={0}>
                <Group spacing={0} mt={16}>
                    <Text weight="bold" mr={4}>
                        {t("issueReport.general.locationDetailLabel")}
                    </Text>
                    <Text span>
                        {t("issueReport.general.locationDetailValue", {
                            value: toilet.objectNumber,
                        })}
                    </Text>
                </Group>

                <Text weight="bold" mt={16}>
                    {t("issueReport.general.additionalNotes.label")}
                </Text>
                <Divider size="xs" />
                <Textarea
                    mt="xs"
                    mb="xl"
                    placeholder={t(
                        "issueReport.general.additionalNotes.placeholder"
                    )}
                    aria-label={t("issueReport.general.additionalNotes.label")}
                    minRows={3}
                    value={form.values.description}
                    onChange={(event) => {
                        form.setFieldValue(
                            "description",
                            event.currentTarget.value
                        );
                    }}
                    size="md"
                />
            </Stack>

            <BottomSheetFooter>
                <Stack spacing={8}>
                    <Tooltip
                        label={t(
                            "issueReport.general.additionalNotes.requiredText"
                        )}
                        color="black"
                        withArrow
                        offset={-12}
                        position="bottom"
                        events={{
                            hover: true,
                            focus: false,
                            touch: true,
                        }}
                        disabled={isDescriptionIssueTextAreaInputValid}
                    >
                        <Box component="div">
                            <Button
                                w="100%"
                                loading={isIssueSending}
                                disabled={!isDescriptionIssueTextAreaInputValid}
                                onClick={submit}
                            >
                                {t("issueReport.general.btn.submitBtn")}
                            </Button>
                        </Box>
                    </Tooltip>

                    <Button
                        onClick={onAbort}
                        variant="outline"
                        disabled={isIssueSending}
                    >
                        {t("issueReport.general.btn.abort")}
                    </Button>
                </Stack>
            </BottomSheetFooter>
        </>
    );
}

export default Step1;
