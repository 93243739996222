import React, { ReactElement } from "react";
import { Text, useMantineTheme, Flex, Divider } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import useApplicationStore from "../../../stores/application.store";
import useFacilityStore from "../../../stores/facility.store";
import useCurrentPosition from "../../../utilities/client/hooks/useCurrentPosition";
import { currentPositionToLeaflet } from "../../../utilities/client/map";
import FootpathDistance from "../../FootpathDistance/FootpathDistance";
import HumanReadableDistance from "../../HumanReadableDistance/HumanReadableDistance";
import useFacilityListStyles from "../FacilityListEntry.styles";
import { FacilityListEntryDistanceDetails } from "../FacilityListEntry";

type FacilityListEntryHeaderProps = {
    icon: ReactElement;
    title: string;
    address: string;
    comment?: string;
    isDescriptionHidden: boolean;
    locationDescription: string;
    distanceDetails: FacilityListEntryDistanceDetails;
    detailsLink: {
        text: string;
        onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
    } | null;
    information: ReactElement | null;
};

function FacilityListEntryHeader({
    icon,
    title,
    address,
    comment,
    isDescriptionHidden,
    locationDescription,
    distanceDetails,
    detailsLink,
    information,
}: FacilityListEntryHeaderProps): ReactElement {
    const isInNavigationMode = useApplicationStore(
        (state) => state.isInNavigationMode
    );

    const currentPosition = useCurrentPosition();
    const location = currentPositionToLeaflet(currentPosition);
    const theme = useMantineTheme();
    const isViewWidthXS = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const { classes } = useFacilityListStyles({
        locationPermission: location !== null,
        isViewWidthXS,
    });

    const selectedFacilityCollapsed = useFacilityStore(
        (state) => state.selectedFacilityCollapsed
    );

    const footpathToLocation = {
        lng: distanceDetails.lng,
        lat: distanceDetails.lat,
    };
    const routeDetails = (
        <div>
            {distanceDetails.distance ? (
                <HumanReadableDistance
                    distance={distanceDetails.distance}
                    duration={distanceDetails.duration}
                />
            ) : (
                location && (
                    <FootpathDistance from={location} to={footpathToLocation} />
                )
            )}
        </div>
    );

    return (
        <>
            <Flex
                className={classes.distanceContainer}
                gap={location ? "4px" : 0}
                justify="flex-start"
                align="center"
                direction={
                    isInNavigationMode && selectedFacilityCollapsed
                        ? "row-reverse"
                        : "column"
                }
                wrap="nowrap"
            >
                {icon}
                {routeDetails}
            </Flex>
            <Flex
                className={classes.informationContainer}
                justify="flex-start"
                align="flex-start"
                direction={
                    isInNavigationMode && selectedFacilityCollapsed
                        ? "row"
                        : "column"
                }
                wrap="wrap"
                gap={0}
            >
                <Text className={classes.locationDescription} align="left">
                    {locationDescription}
                </Text>
                <Text className={classes.title} align="left" mt={4}>
                    {title}
                </Text>
                <Divider my={2} w="100%" size="sm" color="bvg.6" />
                <Text className={classes.description} align="left">
                    {address}
                </Text>
                {!isDescriptionHidden && comment && (
                    <>
                        <Divider
                            my={2}
                            w="100%"
                            size="sm"
                            color="bvg.6"
                            mb={4}
                        />
                        <Text className={classes.description}>{comment}</Text>
                    </>
                )}
                {!isInNavigationMode && (
                    <Text
                        className={classes.detailsLink}
                        onClick={detailsLink?.onClick}
                        align="left"
                        variant="link"
                        size="sm"
                        mt={4}
                    >
                        {detailsLink?.text}
                    </Text>
                )}
                <Flex
                    className={classes.informationContainer}
                    gap={4}
                    justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="nowrap"
                    mt={4}
                >
                    {information}
                </Flex>
            </Flex>
        </>
    );
}
export default React.memo(FacilityListEntryHeader);
