import { useEffect } from "react";
import { notifications } from "@mantine/notifications";
import {
    useGetIssueReportOptions,
    GetIssueReportOptionsResponse,
} from "../../api/wcAppComponents";
import { NO_ISSUE_REPORT_OPTIONS_NOTIFICATION } from "../notifications";

const GET_ISSUE_OPTIONS_STALE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
const GET_ISSUE_OPTIONS_CACHE_TIME = 6 * 60 * 1000; // 6 minutes in milliseconds. Larger than staleTime to avoid visible re-loadings.

const useGetAllIssueReportOptions = (): {
    isLoading: boolean;
    data: GetIssueReportOptionsResponse;
} => {
    const { data, isLoading, isError } = useGetIssueReportOptions(
        {},
        {
            staleTime: GET_ISSUE_OPTIONS_STALE_TIME,
            cacheTime: GET_ISSUE_OPTIONS_CACHE_TIME,
        }
    );

    useEffect(() => {
        if (!isLoading && isError) {
            notifications.show(NO_ISSUE_REPORT_OPTIONS_NOTIFICATION);
        }
    }, [isError, isLoading]);

    return { isLoading, data: data || [] };
};

export default useGetAllIssueReportOptions;
