import {
    Anchor,
    Group,
    List,
    MantineTheme,
    Space,
    Stack,
    Text,
    Title,
    useMantineTheme,
} from "@mantine/core";
import React, { ReactElement } from "react";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";

import usePrivacyPolicyStyles from "./Accessibility.styles";
import accessibilityAnimation from "../../../assets/animations/accessibility.json";

const notAccessibleFriendlyElement = (
    theme: MantineTheme,
    barrierText: string,
    linkText: string,
    linkDestination: string,
    descriptions: string[],
    timeline: string
) => {
    return (
        <div key={`barrier-${linkDestination}`} style={{ margin: "16px 0" }}>
            <Text size="md" weight="bold">
                {barrierText}
                <Anchor inline href={linkDestination} target="_blank" ml={4}>
                    {linkText}
                </Anchor>
            </Text>
            <List withPadding>
                {descriptions.map((bulletPoint) => (
                    <List.Item key={`bullte-point-${bulletPoint}`} pr={32}>
                        {bulletPoint}
                    </List.Item>
                ))}
            </List>
            <Text size="md">{timeline}</Text>
        </div>
    );
};

function Accessibility(): ReactElement {
    const { classes } = usePrivacyPolicyStyles();
    const theme = useMantineTheme();
    const { t } = useTranslation();

    return (
        <Stack className={classes.content} spacing={0} align="flex-start">
            <Lottie
                style={{ maxWidth: 180, margin: "0 auto" }}
                animationData={accessibilityAnimation}
                loop={false}
            />
            <Title order={4} mb={24}>
                {t("pages.accessibility.title")}
            </Title>
            <Text size="md" className={classes.text}>
                {t("pages.accessibility.introduction")}
            </Text>
            <Text size="md" className={classes.text}>
                {t("pages.accessibility.legalBasis")}
            </Text>
            <Text size="md" className={classes.text}>
                {t("pages.accessibility.notFullyAccessible")}
            </Text>
            <Text size="md" className={classes.text} mb={0}>
                {t("pages.accessibility.notSupportedFeatures")}
            </Text>

            <Text size="md" className={classes.bulletPoints}>
                {(
                    t("pages.accessibility.barriers", {
                        returnObjects: true,
                    }) as {
                        title: string;
                        link: string;
                        bulletPoints: string[];
                        timeline: string;
                    }[]
                ).map((barrier) =>
                    notAccessibleFriendlyElement(
                        theme,
                        t("pages.accessibility.barrier"),
                        barrier.title,
                        barrier.link,
                        barrier.bulletPoints,
                        barrier.timeline
                    )
                )}
            </Text>
            <Text size="md" className={classes.text}>
                {t("pages.accessibility.changeNote")}
            </Text>

            <Text size="md" className={classes.text}>
                {t("pages.accessibility.contactInstructions")}
            </Text>
            <Space h="sm" />
            <Group spacing={4}>
                {(
                    t("pages.accessibility.contactAddress", {
                        returnObjects: true,
                    }) as string[]
                ).map((address) => (
                    <div
                        key={`address-${address}`}
                        style={{ width: "100%", lineHeight: "100%" }}
                    >
                        {address}
                    </div>
                ))}
            </Group>
            <Space h="xl" />
            <Text size="md" className={classes.textHead}>
                {t("pages.accessibility.arbitrationBoard")}
            </Text>
            <Text size="md" className={classes.text}>
                {t("pages.accessibility.arbitrationBoardContactInstructions")}
            </Text>

            <Text size="md" className={classes.textHead}>
                {t("pages.accessibility.finalContact")}
            </Text>
            <Text size="md" className={classes.text}>
                {t("pages.accessibility.finalContactInstructions")}
            </Text>
        </Stack>
    );
}

export default Accessibility;
