import {
    EMAIL_FEEDBACK,
    EMAIL_SUPPORT,
    LINE_BREAK,
    OPENSTREET_MAP_LINK,
    OPEN_DATA_COMMONS_LINK,
} from "../constants/map";

const { REACT_APP_MAX_LIMIT_ISSUE_REPORTS } = process.env;

export const de = {
    navigate: "Navigieren",
    stop: "Stop",
    close: "Schließen",
    sendEmail: "E-Mail senden",
    feedbackMail: {
        subject: "Feedback für die WC-App",
        body: `Mit freundlichen Grüßen,${LINE_BREAK}${LINE_BREAK}{{customerName}}`,
    },
    navbar: {
        login: "Anmelden",
        version: "Version",
        bookmarkedIssues: "Gemerkte Störungen",
        map: "Karte",
        settings: "Einstellungen",
        information: "Informationen",
        feedback: "Feedback geben",
        userProfile: "Profil",
        termsAndConditions: "Allgemeine Geschäftsbedinungen",
        privacyPolicy: "Datenschutzerklärung",
        imPrint: "Impressum",
        accessibility: "Barrierefreiheit",
        faq: "Häufig gestellte Fragen",
    },
    pages: {
        feedback: {
            header: "Feedback zur WC-App geben",
            acknowledgement:
                "Vielen Dank, dass du daran interessiert bist, uns eine Rückmeldung zur WC-App zu geben.",
            instructions:
                "Nutze dafür bitte die Möglichkeit, uns eine E-Mail zuzusenden, indem du auf den folgenden Button klickst.",
            privacyHint:
                "Wir verwenden deine Rückmeldung vertraulich und nutzen sie nur innerhalb des Webentwicklungsteam, um die WC-App zu verbessern.",
            finalAcknowledgement: "Vielen Dank für dein Engagement!",
        },
        settings: {
            header: "Einstellungen",
            keyAuthorization: "Schlüsselberechtigung",
            darkMode: "Dunkelmodus",
        },
        userProfile: { header: "Profil" },
        termsAndConditions: { header: "Terms and conditions" },
        privacyPolicy: { header: "Privacy policy" },
        imPrint: { header: "Imprint" },
        accessibility: {
            header: "Barrierefreiheit",
            title: "Erklärung zur digitalen Barrierefreiheit",
            introduction:
                "Die Berliner Verkehrsbetriebe (BVG) sind bemüht, die Webanwendung https://wc-app.bvg.de/ barrierefrei zugänglich zu machen.",
            legalBasis:
                "Rechtsgrundlage sind das Behindertengleichstellungsgesetz (BGG) und die Barrierefreie-Informationstechnik-Verordnung (BITV) 2.0 und BIKTG in ihrer jeweils gültigen Fassung.",
            contactInstructions:
                "Wen kannst du bei Anmerkungen oder Fragen zur digitalen Barrierefreiheit (Feedbackoption) kontaktieren?",
            notFullyAccessible:
                "Diese Webanwendung ist nur teilweise barrierefrei. Es werden nur teilweise die Anforderungen der aktuellen BITV erfüllt.",
            notSupportedFeatures:
                "Die nachstehend aufgeführten Bereiche sind nicht oder nur teilweise barrierefrei:",

            contactAddress: [
                "Webentwicklungsteam VI-VF34",
                `${EMAIL_FEEDBACK}`,
            ],
            arbitrationBoard: "Kontakt zur Schlichtungsstelle",
            arbitrationBoardContactInstructions:
                "Wenn auch nach deinem Feedback an den oben genannten Kontakt keine zufriedenstellende Lösung gefunden wurde, kannst du dich an die Schlichtungsstelle nach § 16 BGG per Email wenden: info@schlichtungsstelle-bgg.de",
            finalContact:
                "Kontakt zur Landesbeauftragten für digitale Barrierefreiheit",
            finalContactInstructions:
                "Wenn Ihre Kontaktaufnahme mit der öffentlichen Stelle nicht erfolgreich war, können Sie sich an die Landesbeauftragte für digitale Barrierefreiheit wenden.",
            changeNote:
                "Die Änderungen zur Behebungen zu diesen bekannten Barrieren wird auf dieser Seite veröffentlicht.",
            barrier: "Barriere:",
            barriers: [
                {
                    title: "Alternativtexte für Bedienelemente",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-1-1a-alternativtexte-fuer-bedienelemente",
                    bulletPoints: [
                        "GPS Bedienelemente besitzen kein Alternativtext",
                        "Deaktivieren der Navigation ohne Alternativtext Menüpfeile",
                        "Pfeilnavigationen besitzen kein Alternativtext",
                        "Karte und Pins besitzen kein Alternativtext",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Alternativtexte für Grafiken und Objekte",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-1-1b-alternativtexte-fuer-grafiken-und-objekte",
                    bulletPoints: [
                        "Alternativtext für Hintergrundbilder, Icon Fonts und SVGs müssen hinzugefügt werden, sofern diese nicht verlinkt sind",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "HTML Strukturelemente für Überschriften",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-3-1a-html-strukturelemente-fuer-ueberschriften",
                    bulletPoints: [
                        "Überschriften müssen mit mit der HTML Semantik h1-h6 versehen werden",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "HTML Strukturelemente für Listen",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-3-1b-html-strukturelemente-fuer-listen",
                    bulletPoints: [
                        "Listen müssen die ul, ol HTML Elemente enthalten",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Kontraste von Texten ausreichend",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-4-3-kontraste-von-texten-ausreichend",
                    bulletPoints: [
                        "Karte muss angepasst werden",
                        "Für jedes Element, für das eine Vordergrundfarbe festgelegt wurde, muss auch eine Hintergrundfarbe festgelegt werden und umgekehrt",
                        "Bei der Auswahl von einem WC bei 200% Zoom ist die Zentrierung falsch",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Inhalte brechen um",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-4-10-inhalte-brechen-um",
                    bulletPoints: [
                        "Nutzung der App bei der Breite von 1280 Pixel und 400% Zoom nicht möglich",
                        "Bei der Breite von 320 Pixel gibt es horizontale Scrollbalken im Menü",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Kontraste von Grafiken und grafischen Bedienelementen ausreichend",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-4-11-kontraste-von-grafiken-und-grafischen-bedienelementen-ausreichend",
                    bulletPoints: [
                        "Grafiken und Bedienelemente müssen einen Kontrast von 3:1 zum Hintergrund besitzen",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Seite ohne Maus nutzbar",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-1-1-ohne-maus-nutzbar",
                    bulletPoints: ["Seite ist nicht bedienbar ohne Maus"],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Keine Tastaturfalle",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-1-2-keine-tastaturfalle",
                    bulletPoints: ["Tastaturnavigation nicht vorhanden"],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Schlüssige Reihenfolge bei der Tastaturbedienung",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-4-3-schluessige-reihenfolge-bei-der-tastaturbedienung",
                    bulletPoints: ["Tastaturnavigation nicht vorhanden"],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Aktuelle Position des Fokus deutlich",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-4-7-aktuelle-position-des-fokus-deutlich",
                    bulletPoints: ["Tastaturnavigation nicht vorhanden"],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Sichtbare Beschriftung Teil des zugänglichen Namens",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-2-5-3-sichtbare-beschriftung-teil-des-zugaenglichen-namens",
                    bulletPoints: [
                        "Alternativtexte von Bedienelementen wie Links, Beschriftungen von Textfeldern, Buttons oder Checkboxen sollen deren sichtbare Beschriftungen enthalten",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Keine unerwartete Kontextänderung bei Fokus",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-3-2-1-keine-unerwartete-kontextaenderung-bei-fokus",
                    bulletPoints: [
                        "Es ist keine Tastaturnavigation vorhanden, daher ist es nicht prüfbar",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Konsistente Bezeichnung",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-3-2-4-konsistente-bezeichnung",
                    bulletPoints: [
                        "Einheitliche Benennungen von mehrfach vorhandenen HTML Elementen wie zum Beispiel Buttons",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Benutzerdefinierte Einstellungen",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/11-7-benutzerdefinierte-einstellungen",
                    bulletPoints: [
                        "Schriftgrößen sind nicht anpassbar",
                        "Dunkelmodus noch nicht verhanden",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Keine unerwartete Kontextänderung",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-3-2-2-keine-unerwartete-kontextaenderung-bei-eingabe",
                    bulletPoints: [
                        "Die Schlüsselauswahl muss angepasst werden",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Name Rolle Wert verfügbar",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-4-1-2-name-rolle-wert-verfuegbar",
                    bulletPoints: [
                        "WC-Marker müssen mit Name, Rolle und Eigenschaften versehen werden",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
                {
                    title: "Inhalt gegliedert",
                    link: "https://ergebnis.bitvtest.de/pruefschritt/bitv-20-web/9-1-3-1d-inhalt-gegliedert",
                    bulletPoints: [
                        "Fette Schriften müssen mit dem HTML Element strong ausgezeichnet werden",
                    ],
                    timeline:
                        "Der Zeitplan für die Behebung der Barrieren ist bis zum 2. Quartal 2024 geplant.",
                },
            ],
        },
        noToiletsToShow: {
            header: "Es tut uns leid",
            noDataAvailable: "Im Moment können wir dir keine WCs anzeigen.",
            reportIssue:
                "Wende dich zur Meldung von WC-Störungen bitte direkt an die Betriebsleitstelle.",
            workInProgress:
                "Wir arbeiten mit Hochdruck daran, die WCs allen BVGer*innen hier anzuzeigen, weil wir dich lieben.",
            revisitApp: "Komme bitte zu einem späteren Zeitpunkt wieder.",
        },
        frequentlyAskedQuestions: {
            header: "FAQ",
            generalQuestions: {
                headline: "Allgemeines zur WC-App",
                faq: [
                    {
                        question: "Für wen ist die WC-App gedacht?",
                        answers: [
                            "Die Hauptzielgruppe der WC-App ist derzeit das Fahrpersonal der Oberfläche, da nur für deren WCs Störungen gemeldet werden können. Personengruppen, die auf der Strecke an der Oberfläche Tätigkeiten ausüben, gehören ebenfalls zu dieser Zielgruppe.",
                        ],
                    },
                    {
                        question: "Wer kann und darf die WC-App nutzen?",
                        answers: [
                            "Alle BVGer*innen können die WC-App nutzen. Das Fahrpersonal an der Oberfläche kann WCs auf der Strecke, andere BVGer*innen können WCs in den Liegenschaften der BVG in der WC-App finden.",
                        ],
                    },
                    {
                        question:
                            "Auf welchen Geräten darf ich die WC-App nutzen?",
                        answers: [
                            "Die WC-App darf nur auf BVG-eigenen Geräten genutzt werden. Eine Nutzung auf privaten Geräten ist nicht mitbestimmt und daher nicht erlaubt. ",
                            "Die Darstellung der Inhalte in der App ist aktuell für die Verwendung mit Smartphone und Tablet maßgeschneidert. In der Zukunft wird die Darstellung für die Nutzung am Desktop überarbeitet.",
                        ],
                    },
                    {
                        question:
                            "Wer darf die WCs, die in der WC-App angezeigt werden, nutzen?",
                        answers: [
                            "Für die WCs des Fahrpersonals der Oberfläche wird ein Schlüssel benötigt, diesen erhält man über die zuständige Führungskraft oder wird von dieser instruiert, um einen Schlüssel zu bekommen.",
                            "Die WCs in den Liegenschaften der BVG dürfen von allen Mitarbeiter*innen genutzt werden. Zu beachten ist dabei, dass der Zutritt zur Liegenschaft nur mit Mitarbeitendenausweis erfolgt.",
                        ],
                    },
                    {
                        question: "Wer hat die WC-App entwickelt?",
                        answers: [
                            "Die WC-App wurde und wird vom BVG-eigenen Webentwicklungsteam VI-VF34 entwickelt.",
                        ],
                    },
                    {
                        question:
                            "Kann ich Feedback zur WC-App geben und wenn ja, wie?",
                        answers: [
                            `Dein Feedback ist für das Webentwicklungsteam sehr wertvoll. Nutze dafür die Funktion “Feedback geben” im Menü der WC-App oder sende uns eine E-Mail an ${EMAIL_FEEDBACK}.`,
                        ],
                    },
                ],
            },
            newUser: {
                headline: "Hinweise für (neue) Nutzer*innen",
                faq: [
                    {
                        question:
                            "Was muss ich machen, um die WC-App zu nutzen?",
                        answers: [
                            "Du kannst die WC-App aus dem BVG Playstore im Geschäftsbereich deines Smartphones herunterladen. Wenn du zum BO-Bereich gehörst, findest du die WC-App im Geschäftsbereich deines Smartphones vorinstalliert.",
                            "Du kannst die WC-App aber auch über die URL wc-app.bvg.de im Browser aufrufen.",
                            "Die Anmeldung erfolgt automatisch per Single-Sign-On.",
                        ],
                    },
                    {
                        question:
                            "Mit welchen Daten logge ich mich in die WC-App ein?",
                        answers: [
                            "Die Anmeldung erfolgt automatisch per Single-Sign-On. Genutzt werden dazu deine Windows-Nutzer-Daten:",
                            "   - Unternehmenskonto: BVG-E-Mail-Adresse",
                            "   - Passwort: Windows-Passwort",
                        ],
                    },
                    {
                        question:
                            "Warum muss ich angeben, ob und welchen WC-Schlüssel ich besitze?",
                        answers: [
                            "Jede*r BVGer*in darf ein WC nutzen, sofern sie*er dafür berechtigt ist. Manche WC-Anlagen sind nur mit einem Schlüssel zugänglich. Damit wir dir die richtigen WCs anzeigen können, musst du angeben, ob du einen Schlüssel besitzt. Du kannst deine Auswahl nachträglich in den Einstellungen ändern.",
                        ],
                    },
                    {
                        question:
                            "Was passiert, wenn ich die Frage nach der Schließberechtigung nicht beantworten möchte oder kann?",
                        answers: [
                            "Wenn du die Fragen nicht beantworten möchtest, dann können unter Umständen in der App nicht alle WCs anzeigt werden, für die du berechtigt bist. Eine Nutzung aller Funktionen der App ist dann eventuell nicht möglich.",
                            "Wenn du unsicher bist, ob du einen Schlüssel hast oder haben solltest, frage eine erfahrene Kollegin, einen erfahrenen Kollegen oder deine Führungskraft.",
                        ],
                    },
                    {
                        question:
                            "Wieso werden mir in der App nicht alle WCs angezeigt?",
                        answers: [
                            "Die WCs in den Liegenschaften der BVG sollten immer sichtbar sein.",
                            "Wenn du einen Schlüssel für die WCs auf der Strecke an der Oberfläche besitzt und dies unter Einstellungen > Schlüsselberechtigung so angegeben hast, sollten dir auch die WCs der Oberfläche angezeigt werden.",
                            `Wenn du dennoch nicht alle oben genannten WCs sehen kannst, dann liegt eventuell ein technisches Problem vor. Wende dich an den Service Desk per E-Mail an ${EMAIL_SUPPORT} oder telefonisch über die Nummer +49 30 256 22020.`,
                        ],
                    },
                    {
                        question:
                            "Werden meine Angaben an einen Dritten (z.B. meine Führungskraft) weitergegeben?",
                        answers: [
                            "Die persönlichen Angaben, die du bei der Nutzung der WC-App machst inklusive deiner WC-Schlüssel, werden nicht ausgewertet oder an Dritte weitergegeben.",
                        ],
                    },
                    {
                        question: "Warum muss ich meinen Standort freigeben?",
                        answers: [
                            "Um alle Funktionalitäten in vollem Umfang nutzen zu können, empfehlen wir dir, deinen Standort freizugeben. Folgende Funktionen kannst du nur mit der Standort-Freigabe nutzen:",
                            "   - Bei Freigabe deines Standorts kannst du auf der Karte erkennen, wo du dich befindest und welche WCs in deiner Nähe sind.",
                            "   - In der WC-Liste oder bei Klick auf ein WC kannst du die Dauer des Fußweges und die Entfernung zu den WCs ablesen.",
                            "   - Mit der Navigationsfunktion kannst du dich bequem zum gewählten WC führen lassen und die Orientierung behalten.",
                        ],
                    },
                    {
                        question:
                            "Was passiert, wenn ich meinen Standort nicht freigebe?",
                        answers: [
                            "Wenn du deinen Standort nicht freigibst, kannst du dir nur die WCs auf der Karte anschauen, diese auswählen und die einzelnen Informationen ablesen. Eine Navigation sowie die Angaben zur Entfernung und Dauer des Fußweges können dann nicht angezeigt werden.",
                        ],
                    },
                    {
                        question:
                            "Kann jemand meinen Standort sehen, wenn ich diesen in der WC-App freigebe?",
                        answers: [
                            "Die persönlichen Angaben, die du bei der Nutzung der WC-App angibst, inklusive deines Standorts, werden nicht ausgewertet oder an Dritte weitergegeben.",
                        ],
                    },
                    {
                        question:
                            "Ich komme mit der WC-App nicht zurecht, wer kann mir helfen?",
                        answers: [
                            `Wende dich an eine Kollegin oder einen Kollegen, die bzw. der die WC-App schon kennt, nutze die Funktion “Feedback geben” im Menü der WC-App oder wende dich an den Service Desk. Du erreichst diesen per E-Mail an ${EMAIL_SUPPORT} oder telefonisch über die Nummer +49 30 256 22020.`,
                        ],
                    },
                ],
            },
            individualFunctionalities: {
                headline: "Hinweise zu einzelnen Funktionalitäten",
                faq: [
                    {
                        question: "Was sehe ich in der Kartenansicht?",
                        answers: [
                            "In der Kartenansicht wird dir eine Karte mit WCs in Berlin angezeigt. Die WCs der BVG-Liegenschaften sind für alle Nutzer*innen sichtbar.",
                            "Wenn du einen Schlüssel für die WCs auf der Strecke an der Oberfläche besitzt und dies unter Einstellungen > Schlüsselberechtigung so angegeben hast, werden dir auch die WCs auf der Strecke an der Oberfläche angezeigt.",
                        ],
                    },
                    {
                        question: "Was sehe ich in der Listenansicht?",
                        answers: [
                            "In der Listenansicht werden dir eine kleinere Karte und eine Liste von WCs angezeigt. In der Liste kannst du die WC-spezifischen Informationen in Textform sehen.",
                            "Wenn du deinen Standort freigegeben hast, werden dir in der Liste die nächstgelegenen 10 WCs mit der Dauer des Fußweges in Minuten und der Entfernung in Metern, bezogen auf deinen aktuellen Standort, angezeigt.",
                            "Hast du deinen Standort nicht freigegeben, werden dir in der Listenansicht alle BVG-WCs in Berlin angezeigt.",
                        ],
                    },
                    {
                        question:
                            "Wieso werden mir in der Listenansicht nicht alle BVG-WCs in Berlin angezeigt?",
                        answers: [
                            "Wenn du deinen Standort freigegeben hast, werden dir in der Liste die nächstgelegenen 10 WCs angezeigt. Wir gehen davon aus, dass diese Anzahl ausreichend für die Nutzer*innen und besser für die Leistungsfähigkeit der App ist, als alle BVG-WCs in Berlin anzuzeigen.",
                        ],
                    },
                    {
                        question: "Wofür ist die Funktion “Navigieren”?",
                        answers: [
                            "Sofern du deinen Standort freigegeben hast und auf ein WC in der Karte oder in der Liste klickst, kannst du die Funktion “Navigieren” nutzen. Mit dieser kannst du dich bequem zum gewählten WC führen lassen und die Orientierung behalten.",
                        ],
                    },
                    {
                        question:
                            "Wieso kann ich die Funktion “Navigieren” nicht nutzen?",
                        answers: [
                            "Die Funktion “Navigieren” kannst du nur nutzen, sofern du deinen Standort freigegeben hast. Andernfalls ist die Navigation technisch nicht möglich.",
                        ],
                    },
                    {
                        question: "Welches Kartenmaterial nutzt die WC-App?",
                        answers: [
                            "Die WC-App nutzt für das Kartenmaterial von OpenStreetMap bereitgestellten Daten. Die Erzeugung des Kartenmaterials erfolgt durch die BVG selbst.",
                        ],
                    },
                ],
            },
            createIssues: {
                headline: "Hinweise zur Meldung von Störungen",
                faq: [
                    {
                        question: "Wie melde ich eine Störung an einem WC?",
                        answers: [
                            "Wähle ein WC aus und klicke auf “Störung melden”. Je nach WC werden Störungen unterschiedlich gemeldet - die WC-App zeigt dir aber alle Informationen an, die du zur Meldung einer Störung an dem ausgewählten WC benötigst.",
                        ],
                    },
                    {
                        question:
                            "Warum kann ich nicht für jedes WC auf die gleiche Art und Weise eine Störung melden?",
                        answers: [
                            "Je nach WC-Art sind unterschiedliche Teams für die Behebung der Störungen zuständig. Damit wir sicherstellen können, dass die Störmeldungen zu dem richtigen Team gelangen, werden Störungen an den WCs unterschiedlich gemeldet. Alle Störmeldungen werden aber aufgenommen und bearbeitet.",
                        ],
                    },
                    {
                        question:
                            "Was passiert mit einer von mir gemachten Störungsmeldung?",
                        answers: [
                            "Die Störmeldung, die du über die WC-App abgibst, wird an das zuständige Team zur Behebung weitergegeben.",
                        ],
                    },
                    {
                        question: "Kann ich anonym eine Störung melden?",
                        answers: [
                            "Störungen an WCs auf der Strecke kannst du anonym melden. Störungen an WCs in BVG-Liegenschaften können aktuell nur per Mail gemeldet werden und sind somit nicht anonym.",
                        ],
                    },
                    {
                        question:
                            "Warum macht es Sinn, beim Melden einer Störung meine Kontaktdaten mitzusenden?",
                        answers: [
                            "Wenn du deine Kontaktdaten mitsendest, können die Bearbeiter*innen der Störmeldung bei eventuellen Rückfragen auf dich zukommen und dir Updates zum Status deiner gemeldeten Störung geben.",
                        ],
                    },
                    {
                        question:
                            "Wie kann ich meine Kontaktdaten beim Senden einer Störung mitsenden?",
                        answers: [
                            "Wenn du eine Störung an einem WC auf der Strecke meldest, wirst du gefragt, wie du die Störung melden möchtest. Wähle einfach “meine Kontaktdaten hinzufügen” aus und die WC-App fügt deine Arbeits-Mail-Adresse der Störmeldung hinzu. Die Kontaktdaten, die mitgesendet werden, siehst du auch oben im Störmeldungsfenster.",
                        ],
                    },
                    {
                        question:
                            "Was mache ich, wenn die Art der Störung, die ich melden möchte, in der Liste der Störungen nicht vorhanden ist?",
                        answers: [
                            "Wenn die Störung, die du melden möchtest zu einer bestimmten Kategorie passt, wähle diese und suche in der Liste der Störungen “sonstige Störung”.  Nach Klick auf “sonstige Störung” beschreibe die Störung im Textfeld, die Beschreibung ist in diesem Falle ein Pflichtfeld.",
                            "Wenn die Kategorie deiner Störung in der Liste nicht vorhanden ist, wähle einfach die Kategorie “sonstige Störung” aus und beschreibe die Störung im Textfeld, die Beschreibung ist auch in diesem Falle ein Pflichtfeld.",
                        ],
                    },
                    {
                        question:
                            "Warum sollte ich die Störung, die ich melden möchte, in der Liste der gemeldeten Störung zu einem WC suchen?",
                        answers: [
                            "Wir wollen vermeiden, dass Störungen doppelt gemeldet werden, damit die verantwortlichen Teams sich auf die Abarbeitung der vorhandenen Störungen konzentrieren können. Bitte melde eine Störung nicht, wenn sie bereits in der Liste auftaucht - sie wurde bereits gemeldet und ist schon im Störmeldungssystem zur weiteren Bearbeitung.",
                        ],
                    },
                    {
                        question:
                            "Warum ist die Störung, die ich melden möchte, ausgegraut und kann nicht gemeldet werden?",
                        answers: [
                            "Störungen werden in der WC-App ausgegraut, wenn die jeweilige Störung an dem ausgewählten WC in den letzten 48 Stunden bereits gemeldet wurde. Damit wird verhindert, dass Störungen mehrfach gemeldet werden. Jede Störung, die gemeldet wurde, wird schnellstmöglich bearbeitet.",
                        ],
                    },
                    {
                        question:
                            "Welche Meldungen werden im Menüpunkt “Gemerkte Störungen” angezeigt?",
                        answers: [
                            "In der Liste “Gemerkte Störungen” werden alle Störungen angezeigt, die du selbst gemeldet hast. Außerdem erscheinen hier Störungen, die du über die Funktion “Störung merken” der Liste hinzugefügt hast. Dadurch kannst du jederzeit den Bearbeitungsstatus der Störungen nachverfolgen und wirst informiert, wenn dazu Neuigkeiten durch die Bearbeiter*innen eingestellt werden.",
                            "Diese Funktionalität können wir für Störmeldungen an Wall-WCs nicht anbieten. Eine Störung an Wall-WCs muss direkt an Wall gemeldet werden und kann nur durch Wall bearbeitet werden, wodurch wir den Stand der Bearbeitung nicht kennen.",
                        ],
                    },
                    {
                        question: "Was macht “Störung merken”?",
                        answers: [
                            "Bei Klick auf “Störung merken”, was bei jeder Störungsmeldung eines WCs zur Verfügung steht, wird diese Meldung in die Liste der gemerkten Störungen aufgenommen, die du im Menüpunkt “Gemerkte Störungen” einsehen kannst. So kannst du jederzeit den Status der Bearbeitung dieser Meldung nachverfolgen.",
                        ],
                    },
                    {
                        question:
                            "An welcher Stelle kann ich mir eine “Störung merken”?",
                        answers: [
                            "Wenn du ein WC anklickst und zu diesem WC bereits Störungen gemeldet wurden, kannst du die Liste der Meldungen über den Link “Störungen anzeigen” einsehen. Hier findest du an jeder Störungsmeldung die Funktion “Störung merken”. Bei Klick darauf wird diese Meldung in die Liste der gemerkten Störungen aufgenommen, die du jederzeit im Menüpunkt “Gemerkte Störungen” einsehen kannst.",
                        ],
                    },
                    {
                        question:
                            "Woher weiß ich, ob eine Störung behoben wurde?",
                        answers: [
                            "Du kannst den Status der von dir gemeldeten Störungen jederzeit im Menüpunkt “Gemerkte Störungen” einsehen. Behobene Störmeldungen werden mit “abgeschlossen” gekennzeichnet.",
                            "Du hast außerdem die Möglichkeit, dir Störungen, die von anderen Nutzern gemeldet wurden, zu merken. Dazu klickst du in der Liste der Störungen eines WCs auf “Störung merken”. Diese Störung wird dann im Menüpunkt “Gemerkte Störungen” mit angezeigt und du kannst dort den Status der Bearbeitung verfolgen.",
                            "Diese Funktionalität können wir für Störmeldungen an Wall-WCs nicht anbieten. Eine Störung an Wall-WCs muss direkt an Wall gemeldet werden und kann nur durch Wall bearbeitet werden, wodurch wir den Stand der Bearbeitung nicht kennen.",
                        ],
                    },
                    {
                        question:
                            "Kann ich den Bearbeitungsstatus einer Meldung sehen?",
                        answers: [
                            "Du kannst den Bearbeitungsstatus einer von dir gemeldeten Störung oder einer gemerkten Störung sehen. Die von dir gemeldeten und gemerkten Störungen findest du im Menüpunkt “Gemerkte Störungen”.",
                            "Diese Funktionalität können wir für Störmeldungen an Wall-WCs nicht anbieten. Eine Störung an Wall-WCs muss direkt an Wall gemeldet werden und kann nur durch Wall bearbeitet werden, wodurch wir den Stand der Bearbeitung nicht kennen.",
                        ],
                    },
                    {
                        question:
                            "Wo kann ich die von mir gemeldeten Störungen einsehen?",
                        answers: [
                            "Das Menü erreichst du durch Anklicken der drei Linien oben links. Dort findest du den Menüpunkt “Gemerkte Störungen”, wo du alle Störungen, die du gemeldet oder dir gemerkt hast, einsehen kannst. Beachte bitte, dass die gemeldeten und gemerkten Störungen im Browser und geräteabhängig gespeichert werden. Wenn du das Gerät wechselst oder die Browserdaten löschst, werden alle Informationen zu den von dir gemeldeten und gemerkten Störungen unwiderruflich gelöscht. Die Störungen werden weiterhin bearbeitet, aber du erhältst keine Informationen darüber in der WC-App.",
                            "Wenn eine Störung abgeschlossen wurde und aus der Liste “Gemerkte Störungen” von dir gelöscht wird, wird die Störungsmeldung nicht mehr in der WC-App angezeigt.",
                            "Diese Funktionalität können wir aus technischen Gründen nicht für gemeldete Störungen an Wall-WCs anbieten.",
                        ],
                    },
                    {
                        question:
                            "Ich finde eine von mir gemerkte oder eine von mir gemeldete Störung nicht mehr in der Liste “Gemerkte Störungen”. Woran liegt das?",
                        answers: [
                            "Wenn eine Störung abgeschlossen wurde und aus der Liste “Gemerkte Störungen” von dir gelöscht wird, wird die Störungsmeldung nicht mehr in der WC-App angezeigt.",
                        ],
                    },
                    {
                        question:
                            "Kann ich eine Störmeldung nach dem Absenden zurückziehen?",
                        answers: [
                            "Nein, nach dem Absenden einer Störmeldung kannst du diese nicht mehr bearbeiten.",
                        ],
                    },
                    {
                        question:
                            "Kann ich Fotos zu einer Störung mit der Meldung mitschicken?",
                        answers: [
                            "Ja, beim Erstellen einer Störmeldung hast du die Möglichkeit, drei Fotos zu erstellen und/oder hochzuladen. Die Fotos erhält die Person, die die Meldung bearbeitet und kann sich so ein besseres Bild über die von dir gemeldete Störung machen.",
                            "Diese Funktionalität können wir aus technischen Gründen nicht für das Melden von Störungen an Wall-WCs anbieten.",
                        ],
                    },
                    {
                        question:
                            "Kann ich mir die Fotos zu einer Störung nach dem Absenden der Meldung nochmal anschauen?",
                        answers: [
                            "Wenn du die Fotos zuerst gemacht hast und diese dann der Störmeldung angehangen hast, findest du die Fotos in deinem Gerät. Wenn du die Fotos bei der Erstellung der Störmeldung aufgenommen hast, werden diese nicht auf dem Gerät gespeichert und du kannst diese dann nicht mehr ansehen.",
                        ],
                    },
                ],
            },
        },
    },
    profile: {
        information: "Benutzerdaten",
        email: "E-Mail",
        name: "Name",
    },
    updateDialog: {
        title: "Neues Update verfügbar!",
        restart: "Jetzt neustarten",
        salutation: "Hallo,",
        description: `es steht ein neues Update deiner WC-App zur Verfügung. Klicke einfach auf den Button "Jetzt neustarten" und die Anwendung wird neugestartet.`,
    },
    header: {
        menu: "Das Menü öffnen",
        toggle: "Umschalten zwischen Karten- und Listenansicht",
    },
    links: {
        termsAndConditions: "https://www.bvg.de/de/agb",
        privacyPolicy: "https://www.bvg.de/de/datenschutz",
        imPrint: "https://www.bvg.de/de/impressum",
        accessibility: "https://www.bvg.de/de/erklaerung-barrierefreiheit",
    },
    map: {
        attribution: `Daten von <a href="${OPENSTREET_MAP_LINK}" target="_blank">OpenStreetMap</a> - Veröffentlicht unter <a href="${OPEN_DATA_COMMONS_LINK}" target="_blank">ODbL</a>`,
    },
    geolocationPermissionDialog: {
        title: "Standort-Freigabe wird benötigt",
        description:
            "Um diese Funktion nutzen zu können, muss die Standort-Freigabe auf deinem Gerät und im Browser eingeschaltet sein.",
        howTo: "Wie gebe ich meinen Standort frei?",
        confirm: "Verstanden",
        imageAlt: "Standortfreigabe fehlt",
        instruction: "Standort-Freigabe einschalten",
        grantGps: "Standort freigeben",
        ignore: "Ignorieren",
    },
    accordionComponent: {
        browserInstruction: {
            title: "Standort-Freigabe im Browser einschalten",
            step_1: "Über die drei Punkte unten die Browser-Einstellungen öffnen",
            step_2: "Über das Zahnrad die Einstellungen öffnen",
            step_3: "Datenschutz und sicherheit wählen",
            step_4: "Websiteberechtigungen wählen",
            step_5: "Standort wählen",
            step_6: "Den Schalter bei Standort aktivieren",
            step_7_1:
                "Falls unter Blockiert die WC-App auftaucht, diese anwählen",
            step_7_2: "Zulassen wählen",
        },
        mobileDeviceInstruction: {
            title: "Standort-Freigabe auf mobilen Geräten einschalten",
            step_1: "Die App Einstellungen öffnen",
            step_2: "Standort wählen",
            step_3: "Schalter aktivieren - Damit schaltest du die Standortermittlung für alle Apps auf deinem mobilen Gerät ein",
            step_4: "Alternativ kann die Standort-Freigabe auch über den Schnellzugriff eingeschaltet werde",
        },
        close: "Verstanden",
    },

    bottomSheet: {
        reportDialog: { title: "Störung zum WC melden", confirm: "Verstanden" },
    },
    actionButton: { navigate: "navigieren", report: ["Störung", "Melden"] },
    issueList: {
        reportedIssues: "Gemeldete Störungen ({{count}})",
        status: {
            done: "abgeschlossen",
            incomplete: "in Bearbeitung",
            noIssues: "Du hast dir keine Störungen gemerkt",
        },
        bookmarkIssue: {
            action: "Störung merken",
            tooltip: "Diese Störung ist bereits gemerkt",
        },
        removeIssue: {
            title: "Störmeldung entfernen",
            cancel: "Abbrechen",
            confirm: "Entfernen",
            prompt: "Möchtest du die Meldung aus deiner Liste wirklich entfernen?",
            info: "Die Meldung wird trotzdem weiter bearbeitet, auch wenn sie nicht mehr in deiner Liste angezeigt wird.",
            irreversibel: "Du kannst diese Aktion nicht rückgängig machen.",
        },
    },
    issueReport: {
        relevantInformation: "Folgende Informationen sind anzugeben:",
        disturbanceType: "Art der Störung:",
        describeIssue: "Bitte beschreibe diese",
        acknowledgement: "Vielen Dank für dein Engagement!",
        property: {
            instructions: `Wenn du eine Störung an diesem WC melden möchtest, wende dich bitte hier per E-Mail an die TLO (<Email>{{email}}</Email>) - notfalls aber auch telefonisch unter der <PhoneNumber>{{phoneNumber}}</PhoneNumber>.`,
            address: "Adresse:",
            locationDetail: "WC:",
            locationDetailDescription:
                "Beschreibe wo genau sich das WC befindet (Etage, wenn möglich Raumnummer)",
            mail: {
                subject: "Meldung einer Störung an einem WC",
                body: `Guten Tag,${LINE_BREAK}${LINE_BREAK}hiermit möchte ich eine Störung an einem WC in der Liegenschaft "{{address}}" melden.${LINE_BREAK}${LINE_BREAK}- Bitte beschreiben Sie den genauen WC-Standort und die Störung -`,
            },
        },
        bu: {
            instructions:
                "Wenn du eine Störung an diesem WC melden möchtest, nutze bitte die Notrufsäule am Bahnhof.",
            address: "Haltestellenname:",
            locationDetail: "WC-Nummer:",
        },
        generalInformation: {
            instructions:
                "Wenn du eine Störung an diesem WC melden möchtest, nutze bitte den Betriebsfunk und melde die Störung bei der Leitstelle.",
            address: "Haltestellenname:",
        },
        general: {
            info: "Wenn dir an diesem WC eine Störung aufgefallen ist, kannst du gerne hier eine Meldung erstellen.",
            locationDetailLabel: "WC-Nummer:",
            locationDetailValue: "#{{value}}",
            category: "Kategorie",
            categoryLabel: "Kategorie:",
            issue: "Störung",
            contactInformation: "Kontaktdaten:",
            reachedReportLimit: `Anonym nur ${REACT_APP_MAX_LIMIT_ISSUE_REPORTS} Meldungen pro Stunde erlaubt`,
            issuesStillLoading: "Verbindung zum Störmeldesystem wird aufgebaut",
            notAdded: "nicht hinzugefügt",
            issueLabel: "Störung:",
            addPhoto: {
                title: "Foto hinzufügen (optional)",
                amount: " / 3 Bilder hinzugefügt",
                notice1:
                    "Achte bitte darauf, dass keine Personen, personenbezogene Daten oder Autoschilder auf dem Foto zu sehen sind.",
                notice2:
                    "Die hinzugefügten Bilder sollten die Störung erkenntlich darstellen. Nutze bei Bedarf die Taschenlampe deines Geräts.",
                notice3: "Es können maximal 3 Bilder hochgeladen werden.",
                doNotShowAgain: "Diesen Hinweis nicht mehr anzeigen",
                confirm: "Verstanden",
                uploadedImage: "Hochgeladenes Bild",
            },
            additionalNotes: {
                label: "Beschreibung der Störung (Pflicht)",
                placeholder: "Bitte beschreibe die vorliegende Störung genau",
                requiredText: "Beschreibung muss befüllt werden",
            },
            additionalOptionalNotes: {
                label: "Beschreibung der Störung (optional)",
            },
            btn: {
                newIssueBtn: "Neue Störung melden",
                submitBtn: "Störung abschicken",
                continue: "Weiter",
                abort: "Abbrechen",
                back: "Zurück",
                done: "Fertig",
            },
            subHeadings: {
                howToReportIssue: "Wie möchtest du die Störung melden?",
                anonymous: "Anonym",
                contactDetails: "Meine Kontaktdaten hinzufügen",
            },
            anonymOrContactInfoText: {
                anonymous: `Du kannst nur <strong>${REACT_APP_MAX_LIMIT_ISSUE_REPORTS} mal pro Stunde</strong> eine Störung aufgeben, allerdings können wir dich bei Rückfragen nicht kontaktieren.`,
                giveContact:
                    "Du kannst jederzeit eine Störung aufgeben und bei Rückfragen melden wir uns zurück. Auf diese Weise können wir die Störung besser bearbeiten.",
            },
            stepTitle: {
                reportIssueTitle: "Störung melden",
                addContactDataTitle: "Kontaktdaten hinzufügen",
                chooseCategoryTitle: "Kategorie wählen",
                chooseIssueTitle: "Störung wählen",
                additionsTitle: "Ergänzungen hinzufügen",
                imageUploadNoticeTitle: "Hinweise zum Bild",
                acknowledgementTitle: "Störung aufgenommen",
            },
            alreadyReportedHint: "Diese Störung wurde bereits gemeldet",
        },
    },
    introDialog: {
        title: "Willkommen!",
        step1: "Schritt 1",
        step2: "Schritt 2",
    },
    permissionInfoDialog: {
        description:
            "Um alle Funktionalitäten in vollem Umfang nutzen zu können, empfehlen wir dir, deinen Standort freizugeben. Folgende Funktionen kannst du nur mit der Standort-Freigabe nutzen:",
        explanation1:
            "Mit der Standortfunktion kannst du auf der Karte erkennen, wo du dich befindest und welche WCs in deiner Nähe sind.",
        explanation2:
            "In der WC-Liste kannst du die Dauer des Fußweges und die Entfernung zu den nächstgelegenen WCs ablesen.",
        explanation3:
            "Mit der Navigationsfunktion kannst du dich bequem zum gewählten WC führen lassen und die Orientierung behalten.",
        share: "Standort freigeben",
        ignore: "Ignorieren",
    },
    keyPermissionDialog: {
        title: "Willkommen!",
        regards: "Schön, dass du die WC-App nutzt.",
        description1:
            "Manche WC-Anlagen sind nur mit einem Schlüssel zugänglich. Damit wir dich zum richtigen WC führen können, musst du uns verraten, ob du einen Schlüssel besitzt.",
        description2:
            "Du kannst deine Auswahl nachträglich in den Einstellungen ändern.",
        keyQuestion: "Besitzt du einen Schlüssel?",
        keyConfirmation: "Ich besitze einen Schlüssel",
        ownedKeys: "Für diese Bereiche hast du einen Schlüssel:",
        noOwnedKeys: "- Du hast keinen Schlüssel -",
        surfaceKey: "Oberfläche",
        undergroundKey: "Untergrund",
        btn: {
            confirmKeyBtn: "Ja, ich habe einen Schlüssel",
            denyKeyBtn: "Nein, ich habe keinen Schlüssel",
            apply: "Übernehmen",
            back: "Zurück",
        },
        checkBox: {
            surface: {
                header: "Für die Oberfläche",
                keyTypes: [
                    "Nummer 1480497 (BO/BS Anlagen)",
                    "Nummer 488HH92 (BU Anlagen)",
                    "Nummer SP69 (WC Damen)",
                ],
            },
            underground: {
                header: "Für den Untergrund",
                keyType: "Nummer 8HK8258 (BU Anlagen)",
            },
        },
    },
    displayModeDialog: {
        description:
            "Der Dunkelmodus schützt deine Augen vor grellem Licht. Wir empfehlen dir die systemgesteuerte Einstellung, damit die WC-App sich automatisch and die Helligkeit anpasst.",
        radioBotton: {
            system: "Systemgesteuert",
            dark: "Dunkel",
            light: "Hell",
        },
    },
    facility: {
        description: {
            building: "WC in BVG-Liegenschaft",
            track: "WC auf der Strecke",
            wall: "Wall-WC",
            propertyToilets: "WC-Liste anzeigen",
            iconAccessibility: "barrierefrei",
            issues: "Störungen anzeigen",
        },
    },
    propertyToiletsListDialog: {
        title: "WC's in der Liegenschaft",
        floors: {
            kg: "Kellergeschoss",
            ug: "Untergeschoss",
            eg: "Erdgeschoss",
            og: "Obergeschoss",
        },
        building: "Gebäude",
        roomNumber: "Raum",
        type: "WC",
    },
    notifications: {
        noGps: {
            title: "Kein Standort abrufbar",
            message: "Dein GPS-Signal ist nicht verfügbar.",
        },
        noNetwork: {
            title: "Keine Internetverbindung verfügbar",
            message:
                "Deine Internetverbindung ist gerade nicht verfügbar. Wende dich zur Meldung von WC-Störungen bitte direkt an die Betriebsleitstelle.",
        },
        noToiletsOnTrack: {
            title: "Fehler beim Laden von Toiletten",
            message:
                "Toiletten auf der Strecke können dir aktuell nicht angezeigt werden. Wende dich zur Meldung von WC-Störungen bitte direkt an die Betriebsleitstelle.",
        },
        noPropertyToilets: {
            title: "Fehler beim Laden von Toiletten",
            message:
                "Toiletten in BVG-Liegenschaften können dir aktuell nicht angezeigt werden.",
        },
        noIssues: {
            title: "Fehler beim Laden von Störmeldungen",
            message:
                "Störmeldungen können dir aktuell nicht angezeigt werden. Wende dich zur Meldung von WC-Störungen bitte direkt an die Betriebsleitstelle.",
        },
        noIssueReportOptions: {
            title: "Fehler beim Anlegen von Störmeldungen",
            message:
                "Störmeldungen können aktuell nicht angelegt werden. Wende dich zur Meldung von WC-Störungen bitte direkt an die Betriebsleitstelle.",
        },
        sendIssueError: {
            title: "Fehler beim Anlegen der Störmeldung",
            message: "Die Störmeldung konnte nicht angelegt werden.",
        },
        mapError: {
            title: "Fehler beim Laden der Karte",
            message:
                "Einige Kartenausschnitte können dir nicht korrekt angezeigt werden.",
        },
        navigationError: {
            title: "Fehler bei der Navigation",
            message: "Die Navigation zum Ziel ist aktuell nicht möglich.",
        },
        general: {
            message:
                "Die WC-App ist gerade nicht verfügbar. Bitte probiere es später erneut. Wende dich zur Meldung von WC-Störungen bitte direkt an die Betriebsleitstelle.",
        },
        invalidImageType: {
            title: "Fehler bei der Auswahl von Bildern",
            message:
                "Mindestens eins der ausgewählten Bilder ist kein JPEG. Es können nur JPEGs ausgewählt werden.",
        },
        tooManyImagesSelected: {
            title: "Fehler bei der Auswahl von Bildern",
            message:
                "Es wurden zu viele Bilder ausgewählt. Es können maximal 3 Bilder ausgewählt werden.",
        },
        imageCompressionError: {
            title: "Fehler bei der Verarbeitung von Bildern",
            message:
                "Bei der Verarbeitung der ausgewählten Bilder ist ein Fehler aufgetreten. Bitte wähle andere Bilder aus oder mache ein neues Foto.",
        },
    },
};

export default de;
