import React, { ReactElement } from "react";
import { Flex, Avatar, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IconAccessibilityLine } from "../../../Icons/Icons";
import { FacilityProperties } from "../../../../utilities/client/facility";
import useApplicationStore from "../../../../stores/application.store";

type PropertiesListProps = FacilityProperties;

const CIRCLE_SIZE = 28;
const ICON_SIZE = 20;
const NAVIGATION_OFFSET = -4;

function PropertyIcon(): ReactElement {
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const isInNavigationMode = useApplicationStore(
        (state) => state.isInNavigationMode
    );
    const isDarkTheme = theme.colorScheme === "dark";

    const navigationModeOffset = isInNavigationMode ? NAVIGATION_OFFSET : 0;
    return (
        <Avatar
            bg={isDarkTheme ? theme.colors.blue[8] : theme.colors.bvg[5]}
            radius="xl"
            size={CIRCLE_SIZE + navigationModeOffset}
            alt={t("facility.description.iconAccessibility")}
        >
            <IconAccessibilityLine
                size={ICON_SIZE + navigationModeOffset}
                color={
                    isDarkTheme ? theme.colors.light[2] : theme.colors.dark[9]
                }
            />
        </Avatar>
    );
}

function PropertiesList({ isAccessible }: PropertiesListProps): ReactElement {
    return (
        <Flex
            gap={4}
            w="100%"
            justify="flex-end"
            align="center"
            direction="row"
            wrap="wrap"
        >
            {isAccessible && <PropertyIcon />}
        </Flex>
    );
}

export default PropertiesList;
