import { Center, useMantineTheme } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import React, { ReactElement, useState } from "react";
import { useDisplayModeStore } from "../../stores/displayModeSettings.store";
import { IconsComponentFileNames } from "../Icons/Icons";
import useSvgIconStyles from "./SvgIcon.styles";

type Props = {
    name: IconsComponentFileNames;
    svgElement: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    color?: string;
    strokeWidth?: number;
};
export type IconProps = {
    mainIcon: Props;
    activeIcon?: Props;
    size?:
        | number
        | {
              height: number;
              width: number;
          };
    switchIconsAfterClick?: boolean;
    onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
};

const DEFAULT_SIZE = 64;

function SvgIcon({
    mainIcon,
    activeIcon,
    size = {
        height: DEFAULT_SIZE,
        width: DEFAULT_SIZE,
    },
    switchIconsAfterClick,
    onClick,
}: IconProps): ReactElement {
    const [isClicked, setIsClicked] = useState(false);
    const theme = useMantineTheme();
    const MainIcon = mainIcon.svgElement;
    const ActiveIcon = activeIcon?.svgElement;

    const { classes } = useSvgIconStyles({ size });
    const { hovered, ref } = useHover();

    const displayMode = useDisplayModeStore((state) => state.displayMode);

    const onClickHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
        if (isClicked && ActiveIcon) {
            if (switchIconsAfterClick) {
                setIsClicked((prevIsClicked) => !prevIsClicked);
            }
        } else if (ActiveIcon && switchIconsAfterClick) {
            setIsClicked((prevIsClicked) => !prevIsClicked);
        }
        if (onClick) {
            onClick(e);
        }
    };

    const getDefaultColor = () => {
        switch (mainIcon.name) {
            case IconsComponentFileNames.Mail:
                return theme.colors.dark[9];
            case IconsComponentFileNames.BvgProperty:
            case IconsComponentFileNames.MapPin:
                return displayMode === "dark"
                    ? theme.colors.light[5]
                    : theme.colors.dark[9];
            default:
                return displayMode === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors.dark[9];
        }
    };

    const isClickedOrHovered = isClicked || hovered;

    const defaultIconColor = getDefaultColor();

    return (
        <Center ref={ref} onClick={onClickHandler}>
            {ActiveIcon && isClickedOrHovered ? (
                <ActiveIcon
                    className={classes.icon}
                    fill={activeIcon.color || defaultIconColor}
                    strokeWidth={activeIcon.strokeWidth || 1}
                />
            ) : (
                <MainIcon
                    className={classes.icon}
                    fill={mainIcon.color || defaultIconColor}
                    strokeWidth={mainIcon.strokeWidth || 1}
                />
            )}
        </Center>
    );
}

export default SvgIcon;
