import { ActionIcon, AppShell, Header, Group, Text } from "@mantine/core";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { IconChevronLeftLine as IconChevronLeft } from "../Icons/Icons";
import useDefaultPageStyles from "./DefaultPage.styles";
import useApplicationStore from "../../stores/application.store";

type DefaultPageProps = {
    title: string;
    children: ReactElement | null;
};

const ICON_SIZE = 24;
function DefaultPage({ children, title }: DefaultPageProps): ReactElement {
    const { classes } = useDefaultPageStyles();
    const navigate = useNavigate();
    const toggleDrawerActive = useApplicationStore(
        (state) => state.toggleDrawerActive
    );

    function handleBackNavigation() {
        // TODO: find a way to get prev route and make a desision between routing root or go back
        // one way is to save each route change global eg. https://stackoverflow.com/a/62389224/7091458F
        toggleDrawerActive(true);
        navigate(-1);
    }

    return (
        <AppShell
            padding="md"
            classNames={{ main: classes.main }}
            header={
                <Header
                    classNames={{ root: classes.header }}
                    height={60}
                    p="xs"
                >
                    <Group className={classes.grid} position="apart">
                        <ActionIcon
                            size="xl"
                            onClick={() => handleBackNavigation()}
                            className={classes.icon}
                        >
                            <IconChevronLeft size={ICON_SIZE} />
                        </ActionIcon>
                        <Text className={classes.text}>{title}</Text>
                        <ActionIcon size="xl" />
                    </Group>
                </Header>
            }
            styles={() => ({
                main: {
                    maxHeight: "100vh",
                    overflow: "auto",
                    paddingTop:
                        "calc(var(--mantine-header-height, 0px) + 16px + env(safe-area-inset-top))!important",
                    paddingBottom:
                        "calc(var(--mantine-header-height, 0px) + 16px + env(safe-area-inset-bottom))!important ",
                    backgroundColor: "white",
                },
            })}
        >
            {children}
        </AppShell>
    );
}

export default DefaultPage;
