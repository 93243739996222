import React, { ReactElement, useEffect } from "react";
import {
    SimpleGrid,
    Text,
    Stack,
    Group,
    Skeleton,
    Divider,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import IssueList from "../IssueList/IssueList";
import useUserIssueReportsStore from "../../stores/userIssueReports.store";
import noIssuesAnimation from "../../assets/animations/finished.json";
import useGetToiletIssues from "../../utilities/client/hooks/useGetToiletIssues";

function UserReports(): ReactElement {
    const { t } = useTranslation();
    const { isLoading: isIssuesLoading } = useGetToiletIssues();

    const userIssues = useUserIssueReportsStore((state) => state.userIssues);
    const clearUserIssueUpdates = useUserIssueReportsStore(
        (state) => state.clearUserIssueUpdates
    );
    const userIssueUpdates = useUserIssueReportsStore(
        (state) => state.userIssueUpdates
    );

    useEffect(() => {
        return () => {
            // executed on unmount
            // dev-note: react strict mode executes this twice in development environment
            clearUserIssueUpdates();
        };
        // only execute on (un)mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadingContent = (
        <Stack justify="flex-start" spacing="xs" mt={28}>
            <Skeleton height={8} width="50%" />
            <Divider mt={8} />
            <Group position="center" mt={8}>
                <Skeleton height={140} mx={12} />
                <Skeleton height={100} mx={12} />
            </Group>
        </Stack>
    );

    let issueListContent = loadingContent;
    if (!isIssuesLoading) {
        issueListContent = (
            <IssueList
                issues={userIssues}
                isOpenInitially
                isCollapsible={false}
                showDetails
                highlightIds={userIssueUpdates}
                maxHeight="100%"
            />
        );
    }

    if (!userIssues.length) {
        return (
            <Stack justify="center" spacing={0}>
                <Group position="center">
                    <Lottie
                        style={{ maxWidth: 200 }}
                        animationData={noIssuesAnimation}
                        loop={false}
                    />
                </Group>
                <Text align="center">{t("issueList.status.noIssues")}</Text>
            </Stack>
        );
    }

    return (
        <SimpleGrid cols={1} h="100%">
            {issueListContent}
        </SimpleGrid>
    );
}

export default UserReports;
