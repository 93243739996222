import { useEffect } from "react";
import { notifications } from "@mantine/notifications";
import { useDeepCompareEffect } from "use-deep-compare";
import useUserIssueReportsStore, {
    UserIssue,
} from "../../../stores/userIssueReports.store";
import {
    useGetIssues,
    GetIssuesResponse,
    fetchGetIssue,
} from "../../api/wcAppComponents";
import { useWcAppContext } from "../../api/wcAppContext";
import { Issue } from "../../api/wcAppSchemas";
import { NO_ISSUES_NOTIFICATION } from "../notifications";
import useNotificationStore from "../../../stores/notification.store";

const FF_ISSUE_REPORT = process.env.REACT_APP_FF_ISSUE_REPORT === "true";
const GET_ISSUES_STALE_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
const GET_ISSUES_CACHE_TIME = 6 * 60 * 1000; // 6 minutes in milliseconds. Larger than staleTime to avoid visible re-loadings.

const useGetToiletIssues = (
    sapEqNumber?: string
): {
    isLoading: boolean;
    data: GetIssuesResponse;
} => {
    const sortByCreatedDate = (
        { dateCreated: a }: Issue,
        { dateCreated: b }: Issue
    ) => {
        return b.localeCompare(a);
    };

    const {
        data: issues,
        isLoading,
        isError,
    } = useGetIssues(
        {},
        {
            enabled: FF_ISSUE_REPORT,
            staleTime: GET_ISSUES_STALE_TIME,
            cacheTime: GET_ISSUES_CACHE_TIME,
        }
    );

    const getUserIssues = useUserIssueReportsStore(
        (state) => state.getUserIssues
    );
    const setUserIssues = useUserIssueReportsStore(
        (state) => state.setUserIssues
    );
    const addUserIssueUpdates = useUserIssueReportsStore(
        (state) => state.addUserIssueUpdates
    );

    // Necessary for passing the access token to fetchGetIssue inside the useEffect
    const { fetcherOptions } = useWcAppContext();

    useDeepCompareEffect(() => {
        const abortController = new AbortController();
        let doCommitUpdate = true;
        // check for new updates of user issues
        if (!isLoading && issues) {
            const updateDate = new Date().toISOString();
            const updatedUserIssueIds: Parameters<
                typeof addUserIssueUpdates
            >["0"] = [];
            const updatedUserIssues = getUserIssues().map(async (userIssue) => {
                if (userIssue.status === "OPEN") {
                    const matchingRemoteIssue = issues.find(
                        (issue) => issue.id === userIssue.id
                    );
                    if (matchingRemoteIssue) {
                        if (
                            userIssue.title !== matchingRemoteIssue.title ||
                            userIssue.description !==
                                matchingRemoteIssue.description
                        ) {
                            updatedUserIssueIds.push(userIssue.id);
                            // update title and description
                            return {
                                ...userIssue,
                                title: matchingRemoteIssue.title,
                                description: matchingRemoteIssue.description,
                                lastUpdated: updateDate,
                            };
                        }
                    } else {
                        // no matching issue from server, i.e. issue is closed
                        return (
                            fetchGetIssue(
                                {
                                    ...fetcherOptions,
                                    pathParams: { issueId: userIssue.id },
                                },
                                abortController.signal
                            )
                                .then((closedIssue): UserIssue => {
                                    updatedUserIssueIds.push(closedIssue.id);
                                    return {
                                        ...closedIssue,
                                        lastUpdated: updateDate,
                                    };
                                })
                                // manually close a non-existant issue (or on an
                                // otherwise faulty response)
                                .catch((): UserIssue => {
                                    updatedUserIssueIds.push(userIssue.id);
                                    return {
                                        ...userIssue,
                                        status: "CLOSED",
                                        lastUpdated: updateDate,
                                    };
                                })
                        );
                    }
                }
                return userIssue;
            });

            Promise.all(updatedUserIssues).then((resolvedUserIssues) => {
                if (doCommitUpdate) {
                    addUserIssueUpdates(updatedUserIssueIds);
                    setUserIssues(resolvedUserIssues);
                }
            });
        }
        return () => {
            abortController.abort();
            doCommitUpdate = false;
        };
    }, [
        issues,
        isLoading,
        getUserIssues,
        setUserIssues,
        addUserIssueUpdates,
        fetcherOptions,
    ]);

    const wasNoIssuesNotificationShown = useNotificationStore(
        (state) => state.wasNoIssuesNotificationShown
    );
    const setWasNoIssuesNotificationShown = useNotificationStore(
        (state) => state.setWasNoIssuesNotificationShown
    );
    useEffect(() => {
        if (!isLoading) {
            if (!wasNoIssuesNotificationShown && isError) {
                notifications.show(NO_ISSUES_NOTIFICATION);
                setWasNoIssuesNotificationShown(true);
            } else if (!isError) {
                // request was now successful, close notification manually
                notifications.hide(NO_ISSUES_NOTIFICATION.id);
                setWasNoIssuesNotificationShown(false);
            }
        }
    }, [
        isError,
        isLoading,
        setWasNoIssuesNotificationShown,
        wasNoIssuesNotificationShown,
    ]);

    if (sapEqNumber) {
        const toiletIssues = issues
            ? issues.filter((issue) => issue.sapEqNumber === sapEqNumber)
            : [];
        return { isLoading, data: toiletIssues.sort(sortByCreatedDate) };
    }

    return { isLoading, data: issues?.sort(sortByCreatedDate) || [] };
};

export default useGetToiletIssues;
