import { create } from "zustand";

export type NotificationStore = {
    wasGpsNotificationShown: boolean;
    wasNoToiletsOnTrackNotificationShown: boolean;
    wasNoPropertyToiletsNotificationShown: boolean;
    wasNoIssuesNotificationShown: boolean;
    wasNavigationErrorNotificationShown: boolean;
    wasMapErrorNotificationShown: boolean;
    setWasGpsNotificationShown: (wasGpsNotificationShown: boolean) => void;
    setWasNoToiletsOnTrackNotificationShown: (
        wasNoToiletsOnTrackNotificationShown: boolean
    ) => void;
    setWasNoPropertyToiletsNotificationShown: (
        wasNoPropertyToiletsNotificationShown: boolean
    ) => void;
    setWasNoIssuesNotificationShown: (
        wasNoIssuesNotificationShown: boolean
    ) => void;
    setWasNavigationErrorNotificationShown: (
        wasNavigationErrorNotificationShown: boolean
    ) => void;
    setWasMapErrorNotificationShown: (
        wasMapErrorNotificationShown: boolean
    ) => void;
};
const useNotificationStore = create<NotificationStore>((set) => ({
    //----------
    // states
    //----------

    wasGpsNotificationShown: false,
    wasNoToiletsOnTrackNotificationShown: false,
    wasNoPropertyToiletsNotificationShown: false,
    wasNoIssuesNotificationShown: false,
    wasNavigationErrorNotificationShown: false,
    wasMapErrorNotificationShown: false,

    //----------
    // actions
    //----------

    setWasGpsNotificationShown: (wasGpsNotificationShown) =>
        set({ wasGpsNotificationShown }),

    setWasNoToiletsOnTrackNotificationShown: (
        wasNoToiletsOnTrackNotificationShown
    ) => set({ wasNoToiletsOnTrackNotificationShown }),

    setWasNoPropertyToiletsNotificationShown: (
        wasNoPropertyToiletsNotificationShown
    ) => set({ wasNoPropertyToiletsNotificationShown }),

    setWasNoIssuesNotificationShown: (wasNoIssuesNotificationShown) =>
        set({ wasNoIssuesNotificationShown }),

    setWasNavigationErrorNotificationShown: (
        wasNavigationErrorNotificationShown
    ) => set({ wasNavigationErrorNotificationShown }),

    setWasMapErrorNotificationShown: (wasMapErrorNotificationShown) =>
        set({ wasMapErrorNotificationShown }),
}));

export default useNotificationStore;
