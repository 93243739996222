import React, { ReactElement, useState } from "react";
import { Box, Button, Tooltip } from "@mantine/core";
import useTileStyles from "./Tile.styles";
import { TOOLTIP_DISPLAY_TIME } from "../../constants/map";

type TileProps = {
    icon?: ReactElement;
    cbOnClick: () => void;
    text: string;
    align?: "left" | "center";
    variant?: "default" | "outline";
    disabled?: boolean;
    disabledHint?: string;
};
function Tile({
    icon,
    cbOnClick,
    text,
    align = "center",
    variant = "default",
    disabled = false,
    disabledHint,
}: TileProps): ReactElement {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const { classes } = useTileStyles({ align });

    return (
        <Box
            onClick={() => {
                if (disabled && disabledHint) {
                    setIsTooltipOpen(true);
                    setTimeout(() => {
                        setIsTooltipOpen(false);
                    }, TOOLTIP_DISPLAY_TIME);
                } else {
                    cbOnClick();
                }
            }}
        >
            <Tooltip
                label={disabledHint}
                opened={isTooltipOpen}
                color="dark"
                position="bottom"
                withArrow
            >
                <Button
                    classNames={{
                        root: classes.button,
                        inner: classes.inner,
                        label: classes.label,
                    }}
                    fullWidth
                    leftIcon={icon}
                    variant={variant}
                    disabled={disabled}
                >
                    {text}
                </Button>
            </Tooltip>
        </Box>
    );
}

export default Tile;
