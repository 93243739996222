import {
    AuthenticationResult,
    EventMessage,
    EventType,
    PublicClientApplication,
} from "@azure/msal-browser";

const { REACT_APP_AZURE_AD_AUTHORITY, REACT_APP_AZURE_AD_CLIENT_ID } =
    process.env;

const msalConfig = {
    auth: {
        clientId: REACT_APP_AZURE_AD_CLIENT_ID ?? "",
        authority: REACT_APP_AZURE_AD_AUTHORITY ?? "",
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

function initMsalInstance() {
    const msalInstance = new PublicClientApplication(msalConfig);
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    // callback to set active account after login, required for useAccount() hook and others
    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const { account } = payload;
            msalInstance.setActiveAccount(account);
        }
    });

    return msalInstance;
}

export default initMsalInstance;
