import React, { ReactElement, useState } from "react";
import { Stepper } from "@mantine/core";
import { useCounter } from "@mantine/hooks";
import { useForm, UseFormReturnType } from "@mantine/form";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5/Step5";
import Step6 from "./Steps/Step6";
import { Toilet } from "../../../../../utilities/api/wcAppSchemas";
import StepperWithoutHeader from "../../../../StepperWithoutHeader/StepperWithoutHeader";

export type stepIndexHandlerType = ReturnType<typeof useCounter>[1];
type ReportFormEntries = {
    toiletObjectNumber: string;
    toiletSapEqNumber: string;
    reportOptionId: string;
    category: string;
    issue: string;
    description: string;
    email: string | null;
};
export type ReportForm = UseFormReturnType<ReportFormEntries>;

type GeneralReportProps = {
    toilet: Toilet;
    isIssueListOpen?: boolean;
    closeBottomSheet: () => void;
};

const NUMBER_OF_STEPS = 6;
const MAX_STEP_INDEX = NUMBER_OF_STEPS - 1;
const MIN_STEP_INDEX = 0;

function GeneralReport({
    toilet,
    isIssueListOpen = false,
    closeBottomSheet,
}: GeneralReportProps): ReactElement {
    if (!toilet.sapEqNumber) {
        throw new Error("no sap-eq-number");
    }

    const [stepIndex, stepIndexHandler] = useCounter(MIN_STEP_INDEX, {
        min: MIN_STEP_INDEX,
        max: MAX_STEP_INDEX,
    });
    const [
        isImageUploadNoticeAcknowledged,
        setIsImageUploadNoticeAcknowledged,
    ] = useState(false);
    const [issueId, setIssueId] = useState<string | null>(null);
    const form: ReportForm = useForm<ReportFormEntries>({
        initialValues: {
            toiletObjectNumber: toilet.objectNumber,
            toiletSapEqNumber: toilet.sapEqNumber,
            reportOptionId: "",
            category: "",
            issue: "",
            description: "",
            email: null,
        },
    });

    const abort = () => {
        // resets necessary, because animation delays component destruction
        form.reset();
        stepIndexHandler.reset();
        closeBottomSheet();
    };

    return (
        <StepperWithoutHeader active={stepIndex}>
            <Stepper.Step>
                <Step1
                    isIssueListOpen={isIssueListOpen}
                    form={form}
                    stepIndexHandler={stepIndexHandler}
                    abort={abort}
                />
            </Stepper.Step>
            <Stepper.Step>
                <Step2
                    form={form}
                    stepIndexHandler={stepIndexHandler}
                    abort={abort}
                />
            </Stepper.Step>
            <Stepper.Step>
                <Step3
                    form={form}
                    stepIndexHandler={stepIndexHandler}
                    abort={abort}
                />
            </Stepper.Step>
            <Stepper.Step>
                <Step4
                    form={form}
                    stepIndexHandler={stepIndexHandler}
                    abort={abort}
                />
            </Stepper.Step>
            <Stepper.Step>
                <Step5
                    form={form}
                    isImageUploadNoticeAcknowledged={
                        isImageUploadNoticeAcknowledged
                    }
                    onImageUploadAcknowledgement={() =>
                        setIsImageUploadNoticeAcknowledged(true)
                    }
                    setIssueId={setIssueId}
                    stepIndexHandler={stepIndexHandler}
                    abort={abort}
                />
            </Stepper.Step>
            <Stepper.Step>
                <Step6
                    form={form}
                    issueId={issueId}
                    stepIndexHandler={stepIndexHandler}
                    closeBottomSheet={closeBottomSheet}
                />
            </Stepper.Step>
        </StepperWithoutHeader>
    );
}

export default GeneralReport;
