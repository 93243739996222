import React, { ReactElement } from "react";
import { Stepper } from "@mantine/core";
import { useCounter } from "@mantine/hooks";
import { Toilet } from "../../../../../utilities/api/wcAppSchemas";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import StepperWithoutHeader from "../../../../StepperWithoutHeader/StepperWithoutHeader";

export type stepIndexHandlerType = ReturnType<typeof useCounter>[1];

const NUMBER_OF_STEPS = 2;
const MAX_STEP_INDEX = NUMBER_OF_STEPS - 1;
const MIN_STEP_INDEX = 0;

type WallToiletReportProps = {
    toilet: Toilet;
    closeBottomSheet: () => void;
};

function WallToiletReport({
    toilet,
    closeBottomSheet,
}: WallToiletReportProps): ReactElement {
    const [stepIndex, stepIndexHandlers] = useCounter(MIN_STEP_INDEX, {
        min: MIN_STEP_INDEX,
        max: MAX_STEP_INDEX,
    });

    return (
        <StepperWithoutHeader active={stepIndex}>
            <Stepper.Step>
                <Step1
                    toilet={toilet}
                    onSubmit={stepIndexHandlers.increment}
                    onAbort={closeBottomSheet}
                />
            </Stepper.Step>
            <Stepper.Step>
                <Step2
                    onNewIssue={stepIndexHandlers.reset}
                    onClose={closeBottomSheet}
                />
            </Stepper.Step>
        </StepperWithoutHeader>
    );
}

export default WallToiletReport;
