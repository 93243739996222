import React, { ReactElement } from "react";
import { Text, Textarea, Divider } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ReportForm } from "../../GeneralReport";
import { OTHER_ISSUE } from "../../../../../../../utilities/client/issue";

type Step5DescriptionInputProps = {
    form: ReportForm;
};

function Step5DescriptionInput({
    form,
}: Step5DescriptionInputProps): ReactElement {
    const { t } = useTranslation();

    return (
        <>
            <Text weight="bold" mt={32}>
                {form.values.issue === OTHER_ISSUE
                    ? t("issueReport.general.additionalNotes.label")
                    : t("issueReport.general.additionalOptionalNotes.label")}
            </Text>
            <Divider size="xs" />
            <Textarea
                mt="xs"
                mb="xl"
                placeholder={t(
                    "issueReport.general.additionalNotes.placeholder"
                )}
                aria-label={t("issueReport.general.additionalNotes.label")}
                minRows={3}
                value={form.values.description}
                onChange={(event) => {
                    form.setFieldValue(
                        "description",
                        event.currentTarget.value
                    );
                }}
                size="md"
            />
        </>
    );
}

export default Step5DescriptionInput;
