import { createStyles } from "@mantine/core";

const useStep5Styles = createStyles((theme) => {
    return {
        "image-preview-container": {
            position: "relative",
            maxHeight: "30%",
        },
        "image-preview": {
            maxWidth: "100%",
            maxHeight: "100%",
        },
        "image-delete-btn": {
            position: "absolute",
            top: "2px",
            right: "2px",
            background: theme.colors.red[9],
            borderRadius: "100%",
            padding: "3px",
            ":hover": {
                background: "white",
            },
        },
    };
});

export default useStep5Styles;
