import { createStyles } from "@mantine/core";

const useNewVersionStyles = createStyles(() => ({
    body: {
        justifyContent: "center",
        paddingBottom: "1rem",
    },
    header: {
        justifyContent: "center",
    },
    title: {
        alignContent: "center",
        fontWeight: "bold",
    },
}));

export default useNewVersionStyles;
