import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { createRoot } from "react-dom/client";
import "./config/i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App";
import initMsalInstance from "./config/msal";
import "./styles/global.css";
import { wcAppTheme } from "./styles/theme";
import GlobalErrorBoundary from "./GlobalErrorBoundary";

const msalInstance = initMsalInstance();
const queryClient = new QueryClient();

const container = document.getElementById("root");
if (container) {
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <MantineProvider theme={wcAppTheme}>
                    <Notifications position="top-center" zIndex={1050} />
                    <QueryClientProvider client={queryClient}>
                        <GlobalErrorBoundary>
                            <App />
                        </GlobalErrorBoundary>
                    </QueryClientProvider>
                </MantineProvider>
            </MsalProvider>
        </React.StrictMode>
    );
}
