import React, { ReactElement } from "react";
import L, { LatLngExpression } from "leaflet";
import HumanReadableDistance from "../HumanReadableDistance/HumanReadableDistance";

type BeelineProps = {
    from: LatLngExpression;
    to: LatLngExpression;
};

function Beeline({ from, to }: BeelineProps): ReactElement {
    const fromPoint = L.latLng(from);
    const toPoint = L.latLng(to);
    const distance = Math.trunc(fromPoint.distanceTo(toPoint));

    return <HumanReadableDistance distance={distance} />;
}

export default Beeline;
