import React, { ReactElement, useEffect, useState } from "react";
import { Text, Stack, Group, Divider, ActionIcon, Center } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { notifications } from "@mantine/notifications";
import { useViewportSize } from "@mantine/hooks";
import BottomSheetHeader from "../../../../../../BottomSheetHeader/BottomSheetHeader";
import {
    IconAddLine as IconAdd,
    IconCrossLine,
} from "../../../../../../Icons/Icons";
import { stepIndexHandlerType, ReportForm } from "../../GeneralReport";
import Step5FormInfo from "./Step5FormInfo";
import Step5Footer from "./Step5Footer";
import Step5DescriptionInput from "./Step5DescriptionInput";
import useStep5Styles from "./Step5.styles";
import useImageUploadNoticeStore from "../../../../../../../stores/imageUploadNotice.store";
import ImageUploadNotice from "../../../../../../ImageUploadNotice/ImageUploadNotice";
import ImageInput from "../../../../../../ImageInput/ImageInput";
import { TOO_MANY_IMAGES_SELECTED_NOTIFICATION } from "../../../../../../../utilities/client/notifications";
import { OTHER_CATEGORY } from "../../../../../../../utilities/client/issue";

type Step5Props = {
    form: ReportForm;
    isImageUploadNoticeAcknowledged: boolean;
    onImageUploadAcknowledgement: () => void;
    setIssueId: (issueId: string) => void;
    stepIndexHandler: stepIndexHandlerType;
    abort: () => void;
};

const FF_IMAGE_UPLOAD = process.env.REACT_APP_FF_IMAGE_UPLOAD === "true";

function Step5({
    form,
    isImageUploadNoticeAcknowledged,
    onImageUploadAcknowledgement,
    setIssueId,
    stepIndexHandler,
    abort,
}: Step5Props): ReactElement {
    const { classes } = useStep5Styles();
    const { t } = useTranslation();

    const [showImageNotice, setShowImageNotice] = useState(false);

    const doNotShowImageNoticeAgain = useImageUploadNoticeStore(
        (state) => state.doNotShowAgain
    );
    const isAllowedToUploadImage =
        isImageUploadNoticeAcknowledged || doNotShowImageNoticeAgain;

    const [images, setImages] = useState<File[]>([]);

    const [openImageInput, setOpenImageInput] = useState(false);

    const requestImages = async () => {
        // only select images if already acknowledged this stepper or with doNotShowAgain
        if (isAllowedToUploadImage) {
            setOpenImageInput(true);
        } else {
            setShowImageNotice(true);
        }
    };

    const addImages = async (newImages: File[]) => {
        if (newImages.length + images.length > 3) {
            notifications.show(TOO_MANY_IMAGES_SELECTED_NOTIFICATION);
        }

        const imagesToAdd = newImages.slice(0, 3 - images.length);
        setImages([...images, ...imagesToAdd]);
    };

    const removeImage = async (index: number) => {
        setImages(images.filter((_, i) => i !== index));
    };

    // workaround to avoid the width in percent from jumping between values
    const photoCollectionParentId = "photo-collection-parent";
    const [photoWidth, setPhotoWidth] = useState<number>(0);
    const { width: viewPortWidth } = useViewportSize();

    function getPhotoUpload() {
        if (!FF_IMAGE_UPLOAD) {
            return null;
        }
        return (
            <Stack spacing={8}>
                <Group position="apart">
                    <Text weight="bold" mr={32}>
                        {t("issueReport.general.addPhoto.title")}
                    </Text>
                    <ActionIcon
                        onClick={requestImages}
                        variant="filled"
                        color="bvg.6"
                        disabled={images.length >= 3}
                    >
                        <IconAdd size={28} color="black" />
                        <ImageInput
                            images={images}
                            triggerImageInput={openImageInput}
                            onImageInputDisplayed={() => {
                                setOpenImageInput(false);
                            }}
                            onImagesSelected={addImages}
                        />
                    </ActionIcon>
                </Group>
                <Divider size="xs" />
                <Group id={photoCollectionParentId}>
                    {images.map((image, i) => (
                        <div
                            key={`image-preview-container-${image.name}-${image.lastModified}`}
                            className={classes["image-preview-container"]}
                            style={{ maxWidth: `${photoWidth}px` }}
                        >
                            <img
                                alt={`${t(
                                    "issueReport.general.addPhoto.uploadedImage"
                                )} ${i + 1}`}
                                src={URL.createObjectURL(image)}
                                key={`image-${image.name}-${image.lastModified}`}
                                className={classes["image-preview"]}
                            />
                            <ActionIcon
                                size="md"
                                className={classes["image-delete-btn"]}
                                onClick={() => removeImage(i)}
                            >
                                <IconCrossLine size={18} color="black" />
                            </ActionIcon>
                        </div>
                    ))}
                </Group>
                <Center>
                    <Text fw={700}>
                        {`${images.length}${t(
                            "issueReport.general.addPhoto.amount"
                        )}`}
                    </Text>
                </Center>
            </Stack>
        );
    }

    useEffect(() => {
        const element = document.getElementById(photoCollectionParentId);
        if (element) {
            setPhotoWidth(element.clientWidth * 0.3);
        }
    }, [viewPortWidth]);

    return (
        <>
            {!showImageNotice && (
                <>
                    <BottomSheetHeader
                        title={t(
                            "issueReport.general.stepTitle.additionsTitle"
                        )}
                    />
                    <Step5FormInfo form={form} />
                    {getPhotoUpload()}
                    <Step5DescriptionInput form={form} />
                    <Step5Footer
                        form={form}
                        images={images}
                        setIssueId={setIssueId}
                        onSuccessfulSubmit={stepIndexHandler.increment}
                        goBack={() => {
                            if (form.values.category === OTHER_CATEGORY) {
                                stepIndexHandler.set(2);
                            } else {
                                stepIndexHandler.decrement();
                            }
                            // reset description in form
                            form.setFieldValue("description", "");
                        }}
                        abort={abort}
                    />
                </>
            )}
            {showImageNotice && (
                <ImageUploadNotice
                    onAcknowledge={() => {
                        onImageUploadAcknowledgement();
                        setShowImageNotice(false);
                        setOpenImageInput(true);
                    }}
                />
            )}
        </>
    );
}

export default Step5;
