import React, { ReactElement } from "react";
import { Center, SimpleGrid, Title, Text, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useAccount, useMsal } from "@azure/msal-react";
import { useCounter } from "@mantine/hooks";

function UserProfile(): ReactElement {
    const [countBeforeLogout, { increment: incrementLogoutCount }] = useCounter(
        0,
        {
            min: 0,
            max: 5,
        }
    );
    const { instance } = useMsal();
    const account = useAccount();

    const { t } = useTranslation();

    if (!account) {
        return <>Loading...</>;
    }

    if (countBeforeLogout === 5) {
        instance.logoutRedirect();
    }

    return (
        <Center style={{ height: "100%" }}>
            <SimpleGrid cols={1}>
                <Title order={3}>{t("profile.information")}</Title>
                <Group spacing={4}>
                    <Text weight="bold">{t("profile.name")}:</Text>
                    <Text>{account?.name}</Text>
                </Group>
                <Group spacing={4}>
                    <Text weight="bold">{t("profile.email")}:</Text>
                    <Text span onClick={incrementLogoutCount}>
                        {account?.username}
                    </Text>
                </Group>
            </SimpleGrid>
        </Center>
    );
}

export default UserProfile;
