export const LINE_BREAK = "\n";
export const NBSP = "\u00A0";
export const OPENSTREET_MAP_LINK = "https://www.openstreetmap.org/";
export const OPEN_DATA_COMMONS_LINK =
    "https://opendatacommons.org/licenses/odbl/";
export const EMAIL_FEEDBACK = "feedback-wc-app@bvg.de";
export const EMAIL_SUPPORT = "support@bvg.de";
export const EMAIL_ISSUE_REPORT = "tlo@bvg.de";
export const TEL_ISSUE_REPORT = "+49 30 256 22207";
export const EMAIL_ISSUE_WALL_REPORT = "techn.leitstelle@wall.de";
export const EMAIL_ISSUE_WALL_REPORT_CC = "Sozialinfrastruktur.Omnibus@bvg.de";
export const TEL_ISSUE_WALL_REPORT = "+49 800 092 5524";
export const EMAIL_WEBDEV_BVG = "webentwicklung@bvg.de";
export const TOOLTIP_DISPLAY_TIME = 1500;
