import React, { ReactElement } from "react";
import { Marker } from "react-leaflet";
import L from "leaflet";

import useCurrentPosition from "../../utilities/client/hooks/useCurrentPosition";
import useCurrentPositionMarkerStyles from "./CurrentPositionMarker.styles";
import { currentPositionToLeaflet } from "../../utilities/client/map";

function CurrentPositionMarker(): ReactElement | null {
    const currentPosition = useCurrentPosition({
        minUpdateInterval: 1000,
    });
    const location = currentPositionToLeaflet(currentPosition);
    const heading = currentPosition?.heading;

    // all values are relative to the side length _a_ of the surrounding square
    const a = 56;
    // do not change these values
    const cr = a / 2;
    const markerCr = a / 7;
    const tipOffset = 2.5 * markerCr;

    const { classes } = useCurrentPositionMarkerStyles({
        heading,
        cr,
    });

    const icon = new L.DivIcon({
        iconSize: new L.Point(a, a),
        className: "override-leaflet-styles",
        html: `
            <svg
                width="${a}px"
                height="${a}px"
                viewBox="0 0 ${a} ${a}"
                class="${classes.svg}"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <defs>
                    <mask id="extended-marker">
                        <rect
                            x="0"
                            y="0"
                            width="${a}"
                            height="${a}"
                            fill="white"
                        />
                        <circle
                            cx="${cr}"
                            cy="${cr}"
                            r="${markerCr * Math.SQRT2}"
                            fill="black"
                        />
                    </mask>
                </defs>
                <circle
                    cx="${cr}"
                    cy="${cr}"
                    r="${cr}"
                    class="${classes.bgCircle}"
                />
                <circle
                    cx="${cr}"
                    cy="${cr}"
                    r="${cr}"
                    class="${classes.pulseAnimation}"
                />
                <circle
                    cx="${cr}"
                    cy="${cr}"
                    r="${markerCr}"
                    class=${classes.marker}
                />
                ${
                    heading &&
                    `<path
                        d="
                            M ${cr - markerCr} ${cr - markerCr}
                            L ${cr} ${cr - tipOffset}
                            L ${cr + markerCr} ${cr - markerCr} Z
                        "
                        class=${classes.tip}
                        mask="url(#extended-marker)"
                    />`
                }
            </svg>
        `,
    });

    return location ? (
        <Marker position={location} icon={icon} alt="position-marker" />
    ) : null;
}

export default CurrentPositionMarker;
