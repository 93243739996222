// We use an equality function here, because we use a custom equality function
// to get the current position, see useCurrentPosition hook
// eslint-disable-next-line no-restricted-syntax
import { createWithEqualityFn } from "zustand/traditional";
import { latLng } from "leaflet";
import { COORDINATES_UNTER_DEN_LINDEN } from "../utilities/client/map";

export type GeolocationStore = GeolocationPosition & {
    watcher: number | null;
    validPosition: boolean;
    permission: boolean;
    permissionState: PermissionState | null;
    // TypeScript type for GeolocationPositionError seems to be wrong?
    error: {
        code: number;
        message: string;
    };
    showMissingLocationAccessDialog: boolean;
    setWatcher: (watcher: number) => void;
    setGeolocation: (position: GeolocationPosition) => void;
    setError: (positionError: GeolocationStore["error"]) => void;
    invalidatePosition: () => void;
    setPermission: (permission: boolean) => void;
    setPermissionState: (state: PermissionState) => void;
    openMissingLocationAccessDialog: () => void;
    closeMissingLocationAccessDialog: () => void;
};

const useGeolocationStore = createWithEqualityFn<GeolocationStore>(
    (set) => ({
        watcher: null,
        validPosition: false,
        coords: {
            accuracy: 0,
            altitude: null,
            altitudeAccuracy: null,
            heading: null,
            latitude: latLng(COORDINATES_UNTER_DEN_LINDEN).lat,
            longitude: latLng(COORDINATES_UNTER_DEN_LINDEN).lng,
            speed: null,
        },
        timestamp: 0,
        permission: false,
        permissionState: null,
        error: {
            code: 1,
            message: "User denied Geolocation",
        },
        showMissingLocationAccessDialog: false,

        setWatcher: (watcher: number) => set({ watcher }),

        setGeolocation: (position: GeolocationPosition) =>
            set({
                coords: position.coords,
                timestamp: position.timestamp,
                validPosition: true,
            }),

        setError: (error: { code: number; message: string }) => set({ error }),

        invalidatePosition: () => set({ validPosition: false }),

        setPermission: (permission: boolean) => set({ permission }),

        setPermissionState: (permissionState) => set({ permissionState }),

        openMissingLocationAccessDialog: () =>
            set({ showMissingLocationAccessDialog: true }),

        closeMissingLocationAccessDialog: () =>
            set({ showMissingLocationAccessDialog: false }),
    }),
    Object.is
);

export default useGeolocationStore;
