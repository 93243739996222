// ways to access mantine's theme: https://mantine.dev/theming/functions/
// theme object: https://mantine.dev/theming/mantine-provider/#theme-object

import {
    ButtonStylesParams,
    getStylesRef,
    MantineThemeOverride,
} from "@mantine/core";

export const wcAppTheme: MantineThemeOverride = {
    // font
    fontFamily: "Transit",

    // colors
    colors: {
        // generates the different gradients: https://colorkit.co/color-shades-generator/
        bvg: [
            "#FEFBE6",
            "#EFE8B6",
            "#EBE197",
            "#BEBA9A",
            "#EADC79",
            "#ECD73F",
            "#F0D722", // taken by color prop of buttons
            "#E3CA16",
            "#C9B41A",
            "#B2A01C",
        ],
        light: [
            "#ffffff",
            "#f8f8f8",
            "#eaeaea",
            "#dcdcdc",
            "#cdcdcd",
            "#bfbfbf",
            "#b1b1b1",
            "#a3a3a3",
            "#959595",
            "#878787",
        ],
        dark: [
            "#d5d7e0",
            "#acaebf",
            "#8c8fa3",
            "#666980",
            "#4d4f66",
            "#34354a",
            "#2b2c3d",
            "#1d1e30",
            "#0c0d21",
            "#01010a",
        ],
        blue: [
            "#e4ebfb",
            "#c9d7f6",
            "#afc3f1",
            "#96b0eb",
            "#7d9ce5",
            "#6487de",
            "#4d73d7",
            "#375dcf",
            "#2245c7",
            "#1227be",
        ],
        flamingo: [
            "#ffbcb3",
            "#ffaba0",
            "#ff998d",
            "#fe877b",
            "#fb7468",
            "#f75f54",
            "#F34740",
            "#d33d36",
            "#b4322d",
            "#972824",
        ],
    },
    primaryColor: "blue",

    // Controls focus ring styles
    focusRing: "never",

    // h1-h6 styles, used in Title and TypographyStylesProvider components
    headings: {
        sizes: {
            h1: { fontSize: "22px" },
            h2: { fontSize: "20px" },
            h3: { fontSize: "18px" },
            h4: { fontSize: "16px" },
        },
    },
    fontSizes: {
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
    },

    other: {
        appShellHeaderHeight: 60,
        zIndex: {
            leaflet: 1000,
            navbar: 1100,
        },
    },

    components: {
        Button: {
            styles: (theme, _params: ButtonStylesParams, { variant }) => ({
                root: {
                    height: "40px",
                    color: theme.colors.dark[9],
                    background:
                        variant === "outline"
                            ? theme.colors.light[0]
                            : theme.colors.bvg[6],
                    borderColor: theme.colors.bvg[6],
                    borderRadius: "40px",
                    padding: "0px 22px",
                    fontSize: "16px",
                    lineHeight: "18px",

                    "&:hover": {
                        backgroundColor: theme.colors.bvg[6],
                    },

                    "&:disabled": {
                        backgroundColor: theme.colors.dark[0],
                    },

                    [`&:disabled .${getStylesRef("label")}`]: {
                        color: theme.colors.dark[3],
                    },
                },
                label: {
                    ref: getStylesRef("label"),
                    color: theme.colors.dark[9],
                },
            }),
        },

        Accordion: {
            styles: (theme) => ({
                item: {
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[7]
                            : theme.colors.light[0],
                },
                chevron: {
                    color:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[0]
                            : theme.colors.dark[9],
                },
            }),
        },

        AppShell: {
            styles: (theme) => ({
                main: {
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[7]
                            : theme.colors.light[0],
                },
            }),
        },

        Modal: {
            styles: (theme) => ({
                root: {
                    zIndex: 1200,
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[7]
                            : theme.colors.light[0],
                },
                inner: {
                    padding: "unset",
                },
            }),
        },
        Checkbox: {
            styles: () => ({
                input: {
                    border: "1px solid black",
                },
            }),
        },

        Text: {
            styles: (theme) => ({
                root: {
                    color:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[0]
                            : theme.colors.dark[9],
                },
            }),
        },

        Anchor: {
            styles: (theme) => ({
                root: {
                    color:
                        theme.colorScheme === "dark"
                            ? theme.colors.blue[5]
                            : theme.colors.blue[9],
                    textDecoration: "underline",
                },
            }),
        },

        Avatar: {
            styles: () => ({
                placeholder: {
                    backgroundColor: "transparent",
                },
            }),
        },

        Notification: {
            styles: (theme) => ({
                root: {
                    backgroundColor: theme.colors.bvg[0],
                    border: `1px solid ${theme.colors.bvg[6]}`,
                },
                title: {
                    fontWeight: "bold",
                    color: theme.colors.dark[9],
                },
                description: {
                    color: theme.colors.dark[9],
                },
                icon: {
                    backgroundColor: theme.colors.bvg[0],
                },
            }),
        },
    },
};

export default wcAppTheme;
