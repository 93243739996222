import React, { ReactElement, useCallback } from "react";
import { Group, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import ActionButton from "./ActionButton/ActionButton";
import useApplicationStore from "../../stores/application.store";
import useMapStore from "../../stores/map.store";
import useGeolocationStore from "../../stores/geolocation.store";
import useFacilityStore from "../../stores/facility.store";
import { IconStopNavigation, IconNavigation, IconAlert } from "../Icons/Icons";

const ICON_SIZE = 34;

type FacilityListEntryActionsProps = {
    onNewIssueReport: () => void;
};

function FacilityListEntryActions({
    onNewIssueReport,
}: FacilityListEntryActionsProps): ReactElement {
    const { t } = useTranslation();
    const theme = useMantineTheme();

    const isInNavigationMode = useApplicationStore(
        (state) => state.isInNavigationMode
    );
    const toggleNavigationMode = useApplicationStore(
        (state) => state.toggleNavigationMode
    );
    const toggleIsFacilityListActive = useApplicationStore(
        (state) => state.toggleIsFacilityListActive
    );
    const setSelectedFacilityCollapsed = useFacilityStore(
        (state) => state.setSelectedFacilityCollapsed
    );
    const reCenterMap = useMapStore((state) => state.reCenterMap);
    const coords = useGeolocationStore((state) => state.coords);
    const permission = useGeolocationStore((state) => state.permission);
    const openMissingLocationAccessDialog = useGeolocationStore(
        (state) => state.openMissingLocationAccessDialog
    );

    const selectedFacility = useFacilityStore(
        (state) => state.selectedFacility
    );

    const switchNavigationMode = useCallback(() => {
        if (!isInNavigationMode) {
            toggleIsFacilityListActive(false);
            setSelectedFacilityCollapsed(true);
        } else {
            setSelectedFacilityCollapsed(false);
        }

        toggleNavigationMode();
        reCenterMap(selectedFacility, coords);
    }, [
        isInNavigationMode,
        toggleNavigationMode,
        reCenterMap,
        selectedFacility,
        coords,
        toggleIsFacilityListActive,
        setSelectedFacilityCollapsed,
    ]);

    return (
        <Group position="center" noWrap>
            <ActionButton
                icon={
                    isInNavigationMode ? (
                        <IconStopNavigation
                            color={theme.colors.red[9]}
                            size={ICON_SIZE}
                        />
                    ) : (
                        <IconNavigation
                            color={theme.colors.bvg[6]}
                            size={ICON_SIZE}
                        />
                    )
                }
                text={[isInNavigationMode ? t("stop") : t("navigate")]}
                onClick={
                    permission
                        ? switchNavigationMode
                        : openMissingLocationAccessDialog
                }
            />
            <ActionButton
                icon={
                    <IconAlert color={theme.colors.bvg[6]} size={ICON_SIZE} />
                }
                text={t("actionButton.report", {
                    returnObjects: true,
                })}
                onClick={() => {
                    onNewIssueReport();
                }}
            />
        </Group>
    );
}

export default React.memo(FacilityListEntryActions);
