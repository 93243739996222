import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useQuery } from "@tanstack/react-query";

const { REACT_APP_AZURE_AD_API_CLIENT_ID } = process.env;

const requestToken = async (msalInstance: IPublicClientApplication) => {
    const response = await msalInstance.acquireTokenSilent({
        scopes: [`api://${REACT_APP_AZURE_AD_API_CLIENT_ID}/api`],
    });
    return response.accessToken;
};

const useGetAccessToken = (): string | null => {
    const { instance } = useMsal();
    const { data, error } = useQuery({
        queryKey: ["ACCESS_TOKEN"],
        queryFn: () => requestToken(instance),
        cacheTime: 0,
    });
    if (error) {
        const er = new Error("[MSAL_ACCESS_TOKEN] Cannot request access token");
        er.stack = error.toString();
        throw er;
    }
    return data || null;
};

export default useGetAccessToken;
