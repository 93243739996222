import React, { ReactElement } from "react";
import { Text, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ReportForm } from "../../GeneralReport";

type Step5FormInfoProps = {
    form: ReportForm;
};

function Step5FormInfo({ form }: Step5FormInfoProps): ReactElement {
    const { t } = useTranslation();

    const anonymOrAdded = (
        <Text>
            {form.values.email
                ? form.values.email
                : t("issueReport.general.notAdded")}
        </Text>
    );

    return (
        <>
            <Group spacing={0} mt={16}>
                <Text weight="bold" mr={4}>
                    {t("issueReport.general.locationDetailLabel")}
                </Text>
                <Text>
                    {t("issueReport.general.locationDetailValue", {
                        value: form.values.toiletObjectNumber,
                    })}
                </Text>
            </Group>
            <Group spacing={0}>
                <Text weight="bold" mr={4}>
                    {t("issueReport.general.contactInformation")}
                </Text>
                <Text>{anonymOrAdded}</Text>
            </Group>
            <Group spacing={0}>
                <Text weight="bold" mr={4}>
                    {t("issueReport.general.categoryLabel")}
                </Text>
                <Text>{form.values.category}</Text>
            </Group>
            <Group spacing={0}>
                <Text weight="bold" mr={4}>
                    {t("issueReport.general.issueLabel")}
                </Text>
                <Text>{form.values.issue}</Text>
            </Group>
        </>
    );
}

export default Step5FormInfo;
