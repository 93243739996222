import React, { ReactElement } from "react";
import {
    UnstyledButton,
    Group,
    Avatar,
    Text,
    Box,
    useMantineTheme,
} from "@mantine/core";

import { useNavigate } from "react-router-dom";
import { useAccount } from "@azure/msal-react";
import { IconChevronRightLine as IconChevronRight } from "../Icons/Icons";
import { USER_ROUTE } from "../../config/navigation/routes";
import useApplicationStore from "../../stores/application.store";

function UserButton(): ReactElement {
    const theme = useMantineTheme();
    const account = useAccount();
    const toggleDrawerActive = useApplicationStore(
        (state) => state.toggleDrawerActive
    );
    const navigate = useNavigate();
    return (
        <Box>
            <UnstyledButton
                onClick={() => {
                    toggleDrawerActive(false);
                    navigate(USER_ROUTE);
                }}
                sx={{
                    display: "block",
                    width: "100%",
                    padding: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                    "&:hover": {
                        backgroundColor:
                            theme.colorScheme === "dark"
                                ? theme.colors.dark[6]
                                : theme.colors.gray[0],
                    },
                }}
            >
                <Group>
                    <Avatar
                        color={theme.colors.blue[9]}
                        bg={theme.colors.blue[0]}
                        radius="xl"
                        size="md"
                    />

                    <Box sx={{ flex: 1 }}>
                        <Text size={theme.fontSizes.md} weight={500}>
                            {account?.name}
                        </Text>
                    </Box>
                    <IconChevronRight
                        size={16}
                        color={
                            theme.colorScheme === "dark"
                                ? theme.colors.dark[0]
                                : theme.colors.dark[9]
                        }
                    />
                </Group>
            </UnstyledButton>
        </Box>
    );
}

export default UserButton;
