import { create } from "zustand";

export type ApplicationStore = {
    isInNavigationMode: boolean;
    newUpdateAvailable: boolean;
    isDrawerActive: boolean;
    isFacilityListActive: boolean;
    toggleIsFacilityListActive: (active?: boolean) => void;
    toggleNewUpdateAvailable: (active?: boolean) => void;
    toggleNavigationMode: (active?: boolean) => void;
    toggleDrawerActive: (active?: boolean) => void;
};
const useApplicationStore = create<ApplicationStore>((set) => ({
    //----------
    // states
    //----------

    isInNavigationMode: false,
    newUpdateAvailable: false,
    isDrawerActive: false,
    isFacilityListActive: false,

    //----------
    // actions
    //----------

    toggleNewUpdateAvailable: (active?: boolean) => {
        return set((state: ApplicationStore) => ({
            newUpdateAvailable: active ?? !state.newUpdateAvailable,
        }));
    },

    toggleNavigationMode: (active?: boolean) => {
        return set((state: ApplicationStore) => ({
            isInNavigationMode: active ?? !state.isInNavigationMode,
        }));
    },

    toggleDrawerActive: (active?: boolean) => {
        return set((state: ApplicationStore) => ({
            isDrawerActive: active ?? !state.isDrawerActive,
        }));
    },

    toggleIsFacilityListActive: (active?: boolean) => {
        return set((state: ApplicationStore) => ({
            isFacilityListActive: active ?? !state.isFacilityListActive,
        }));
    },
}));

export default useApplicationStore;
