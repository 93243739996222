import React, { ReactElement, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useColorScheme, useNetwork, useTimeout } from "@mantine/hooks";
import { ColorScheme, useMantineTheme } from "@mantine/core";
import { InteractionStatus, Logger, LogLevel } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { notifications } from "@mantine/notifications";
import ToiletAppLogo from "./logo.svg";
import "leaflet/dist/leaflet.css";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import WcAppShell from "./components/WcAppShell/WcAppShell";
import FacilityMapPage from "./pages/ToiletMap.page";
import useAppStyles from "./App.style";
import NewVersionDialog from "./components/Dialogs/NewVersionDialog/NewVersionDialog";
import useApplicationStore from "./stores/application.store";
import useServiceWorker from "./useServiceWorker";
import TermsAndConditionsPage from "./pages/TermsAndConditions.page";
import UserProfilePage from "./pages/UserProfile.page";

import {
    ACCESSIBILITY_ROUTE,
    FEEDBACK_ROUTE,
    IMPRINT_ROUTE,
    PRIVACY_POLICY_ROUTE,
    TERMS_AND_CONDITIONS_ROUTE,
    USER_ROUTE,
    FAQ_ROUTE,
} from "./config/navigation/routes";
import PrivacyPolicyPage from "./pages/PrivacyPolicy.page";
import ImprintPage from "./pages/Imprint.page";
import AccessibilityPage from "./pages/Accessibility.page";
import GeolocationPermission from "./components/BottomSheetPages/GeolocationPermission/GeolocationPermission";
import FeedbackPage from "./pages/Feedback.page";
import FrequentlyAskedQuestions from "./pages/FrequentlyAskedQuestions.page";
import useIntroductionStore from "./stores/introduction.store";
import { SETTINGS_ROUTE, USER_REPORTS_ROUTE } from "./config/routes";
import useLocationPermission from "./utilities/client/hooks/useLocationPermission";
import SettingsPage from "./pages/Settings.page";
import UserReportsPage from "./pages/UserReports.page";
import IntroDialog from "./components/Dialogs/IntroDialog/IntroDialog";
import { useDisplayModeStore } from "./stores/displayModeSettings.store";
import GlobalErrorBoundaryAfterAuthentication from "./GlobalErrorBoundaryAfterAuthentication";
import useGetAccessToken from "./utilities/client/hooks/useGetAccessToken";
import sendLog from "./utilities/client/logHandler";
import {
    LONG_NOTIFICATION_TIMEOUT,
    NO_NETWORK_NOTIFICATION,
} from "./utilities/client/notifications";

function App(): ReactElement {
    const { online } = useNetwork();

    const { instance: msalInstance, inProgress: msalInProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const accessToken = useGetAccessToken();

    const { classes } = useAppStyles();
    const toggleNewUpdateAvailable = useApplicationStore(
        (state) => state.toggleNewUpdateAvailable
    );
    const { waitingWorker, showReload } = useServiceWorker();
    const locationPermissionDone = useIntroductionStore(
        (state) => state.locationPermissionDone
    );
    const isApplyKey = useIntroductionStore((state) => state.isApplyKey);
    const displayMode = useDisplayModeStore((state) => state.displayMode);
    const setDisplayModeSystem = useDisplayModeStore(
        (state) => state.setDisplayModeSystem
    );
    const theme = useMantineTheme();
    const colorScheme = useColorScheme("light");
    const [mode, setMode] = useState<ColorScheme>("light");

    useEffect(() => {
        setDisplayModeSystem(colorScheme);
        if (displayMode !== mode) {
            theme.colorScheme = displayMode;
            setMode(displayMode);
        }
    }, [colorScheme, setDisplayModeSystem, displayMode, theme, mode]);

    useLocationPermission();

    // decides when to show the toast
    useEffect(() => {
        if (showReload && waitingWorker) {
            toggleNewUpdateAvailable();
        }
    }, [waitingWorker, showReload, toggleNewUpdateAvailable]);

    // call login redirect if user isn´t authenticated and auth provider is not already doing something
    useEffect(() => {
        if (msalInProgress === InteractionStatus.None && !isAuthenticated) {
            msalInstance.loginRedirect();
        }
    }, [isAuthenticated, msalInProgress, msalInstance]);

    useEffect(() => {
        msalInstance.setLogger(
            new Logger({
                loggerCallback: (
                    level: LogLevel,
                    message: string,
                    containsPii: boolean
                ) => {
                    if (containsPii) {
                        return;
                    }

                    if (accessToken) {
                        switch (level) {
                            case LogLevel.Error:
                                sendLog(
                                    accessToken,
                                    "ERROR",
                                    "AZURE_AD",
                                    message
                                );
                                break;
                            case LogLevel.Warning:
                                sendLog(
                                    accessToken,
                                    "WARNING",
                                    "AZURE_AD",
                                    message
                                );
                                break;
                            default:
                        }
                    }
                },
                piiLoggingEnabled: false,
            })
        );
    }, [accessToken, msalInstance]);

    const {
        start: startInternetNotificationTimeout,
        clear: clearInternetNotificationTimeout,
    } = useTimeout(() => {
        notifications.show(NO_NETWORK_NOTIFICATION);
    }, LONG_NOTIFICATION_TIMEOUT);
    useEffect(() => {
        if (!online) {
            startInternetNotificationTimeout();
        } else {
            clearInternetNotificationTimeout();
            notifications.hide(NO_NETWORK_NOTIFICATION.id);
        }
    }, [
        clearInternetNotificationTimeout,
        online,
        startInternetNotificationTimeout,
    ]);

    if (!isAuthenticated) {
        return (
            <div className={classes.loadingWrapper}>
                <img
                    className={classes.loadingIcon}
                    src={ToiletAppLogo}
                    alt="WC-App-Logo"
                />
            </div>
        );
    }

    if (!locationPermissionDone || !isApplyKey) {
        return <IntroDialog />;
    }

    return (
        <GlobalErrorBoundaryAfterAuthentication accessToken={accessToken}>
            <NewVersionDialog />
            <GeolocationPermission />

            <BrowserRouter>
                <WcAppShell>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute>
                                    <FacilityMapPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={USER_REPORTS_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <UserReportsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/wc-app/*"
                            element={
                                <ProtectedRoute>
                                    <FacilityMapPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={SETTINGS_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <SettingsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={TERMS_AND_CONDITIONS_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <TermsAndConditionsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={PRIVACY_POLICY_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <PrivacyPolicyPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={IMPRINT_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <ImprintPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ACCESSIBILITY_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <AccessibilityPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={USER_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <UserProfilePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={FEEDBACK_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <FeedbackPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={FAQ_ROUTE}
                            element={
                                <ProtectedRoute>
                                    <FrequentlyAskedQuestions />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="*" element={<Navigate replace to="/" />} />
                    </Routes>
                </WcAppShell>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
        </GlobalErrorBoundaryAfterAuthentication>
    );
}

export default App;
