import { Container, Accordion } from "@mantine/core";
import { TFunction } from "i18next";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import FloorToiletsList from "./FloorToiletsList/FloorToiletsList";
import { PropertyToilet } from "../../../utilities/api/wcAppSchemas";

const REGEX_FLOOR_NUMBER = /\d+/;
const REGEX_FLOOR_TYPE = /^([a-zA-Z]+)/;

function createReadableFloorName(floor: string, t: TFunction) {
    const floorMap: Record<string, string> = {
        KG: t("propertyToiletsListDialog.floors.kg"),
        UG: t("propertyToiletsListDialog.floors.ug"),
        EG: t("propertyToiletsListDialog.floors.eg"),
        OG: t("propertyToiletsListDialog.floors.og"),
    };

    const floorType = REGEX_FLOOR_TYPE.exec(floor)?.[0];

    if (floorType && floorMap[floorType]) {
        return floor.replace(REGEX_FLOOR_TYPE, `${floorMap[floorType]} `);
    }

    return floor;
}

export function sortFloors(propertyToilets: PropertyToilet[]): string[] {
    const floors = Array.from(
        new Set(propertyToilets.map(({ floor }) => floor))
    );

    /*
     * sort floors with KG and UG to the beginning
     *
     * As there is never more than one "KG" or "UG", no further distinction is
     * necessary than is present in the below.
     * At the time of writing this piece of code, we have no API an therefore
     * no knowledge of the final presentation of the data in the future. So,
     * bear this in mind when looking at this in the future.
     *
     * Refer to the following MR for more context:
     * https://cindbad-git.bvg.de/entwicklerteam/wc-app/-/merge_requests/405
     */
    return floors.sort((a, b) => {
        const aNumber = Number(REGEX_FLOOR_NUMBER.exec(a)?.[0]);
        const bNumber = Number(REGEX_FLOOR_NUMBER.exec(b)?.[0]);

        if (a.startsWith("KG") || a.startsWith("UG")) {
            return -1;
        }
        if (b.startsWith("KG") || b.startsWith("UG")) {
            return 1;
        }

        return aNumber - bNumber;
    });
}

function PropertyToiletsList({
    propertyToilets,
}: {
    propertyToilets: PropertyToilet[];
}): ReactElement {
    const { t } = useTranslation();
    const sortedFloors = sortFloors(propertyToilets);

    const shownDefaultFloor =
        sortedFloors.find((floor) => floor === "EG") || sortedFloors[0];

    const items = sortedFloors.map((floor) => {
        const toiletsOnFloor = propertyToilets.filter(
            (toilet) => toilet.floor === floor
        );

        const readableFloorName = createReadableFloorName(floor, t);

        return (
            <Accordion.Item value={floor} key={floor}>
                <Accordion.Control>{`${readableFloorName} (${toiletsOnFloor.length})`}</Accordion.Control>
                <Accordion.Panel>
                    <FloorToiletsList propertyToilets={toiletsOnFloor} />
                </Accordion.Panel>
            </Accordion.Item>
        );
    });

    return (
        <Container fluid={false} size="sm">
            <Accordion chevronPosition="left" defaultValue={shownDefaultFloor}>
                {items}
            </Accordion>
        </Container>
    );
}

export default PropertyToiletsList;
