import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export type ImageUploadNoticeStore = {
    doNotShowAgain: boolean;

    setDoNotShowAgain: (doNotShowAgain: boolean) => void;
};

const useImageUploadNoticeStore = create<ImageUploadNoticeStore>()(
    devtools(
        persist(
            (set) => ({
                doNotShowAgain: false,
                setDoNotShowAgain: (doNotShowAgain: boolean) =>
                    set({ doNotShowAgain }),
            }),
            { name: "image-upload-notice" }
        )
    )
);

export default useImageUploadNoticeStore;
