import { Modal, Button, Stack, Container, Text, Group } from "@mantine/core";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { isMobile } from "react-device-detect";
import useApplicationStore from "../../../stores/application.store";
import updateAnimation from "../../../assets/animations/update.json";
import useNewVersionStyles from "./NewVersionDialog.styles";

function NewVersionDialog(): ReactElement {
    const newUpdateAvailable = useApplicationStore(
        (state) => state.newUpdateAvailable
    );
    const toggleNewUpdateAvailable = useApplicationStore(
        (state) => state.toggleNewUpdateAvailable
    );
    const { t } = useTranslation();
    const { classes } = useNewVersionStyles();

    function restart() {
        window.location.reload();
    }
    return (
        <Modal
            classNames={{
                title: classes.title,
                header: classes.header,
                body: classes.body,
            }}
            closeOnClickOutside={false}
            fullScreen={isMobile}
            opened={newUpdateAvailable}
            onClose={() => toggleNewUpdateAvailable(false)}
            centered
            title={t("updateDialog.title")}
            withCloseButton={false}
            overlayProps={{ opacity: 0.55, blur: 3 }}
            transitionProps={{
                transition: "fade",
                duration: 600,
                timingFunction: "pop",
            }}
        >
            <Container fluid={false}>
                <Stack justify="flex-end">
                    <Group position="center">
                        <Lottie
                            style={{ maxWidth: 250 }}
                            animationData={updateAnimation}
                            loop
                        />
                    </Group>
                    <Text size="md" weight={700}>
                        {t("updateDialog.salutation")}
                    </Text>
                    <Text size="md">{t("updateDialog.description")}</Text>
                    <Button onClick={() => restart()}>
                        {t("updateDialog.restart")}
                    </Button>
                </Stack>
            </Container>
        </Modal>
    );
}

export default NewVersionDialog;
