import React, { ReactElement } from "react";
import { Button, Stack, Tooltip, Box } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { notifications } from "@mantine/notifications";
import reportIssueLimit from "../../../../../../../stores/reportIssueLimit.store";
import useUserIssueReportsStore from "../../../../../../../stores/userIssueReports.store";
import { useCreateIssue } from "../../../../../../../utilities/api/wcAppComponents";
import { useWcAppContext } from "../../../../../../../utilities/api/wcAppContext";
import {
    IssueRequestBody,
    Issue,
} from "../../../../../../../utilities/api/wcAppSchemas";
import {
    OTHER_ISSUE,
    convertFilesToIssueImages,
    getIssueDescription,
} from "../../../../../../../utilities/client/issue";
import BottomSheetFooter from "../../../../../../BottomSheetFooter/BottomSheetFooter";
import { ReportForm } from "../../GeneralReport";
import { SEND_ISSUE_ERROR_NOTIFICATION } from "../../../../../../../utilities/client/notifications";

type Step5FooterProps = {
    form: ReportForm;
    images: File[];
    setIssueId: (issueId: string) => void;
    onSuccessfulSubmit: () => void;
    goBack: () => void;
    abort: () => void;
};

function Step5Footer({
    form,
    images,
    setIssueId,
    onSuccessfulSubmit: runOnSuccessfulSubmit,
    goBack,
    abort,
}: Step5FooterProps): ReactElement {
    const { t } = useTranslation();

    const isDescriptionIssueTextAreaInputValid =
        form.values.description.length > 0 || form.values.issue !== OTHER_ISSUE;

    const { mutateAsync: sendIssue, isLoading: isIssueSending } =
        useCreateIssue();

    const queryClient = useQueryClient();
    const { queryKeyFn } = useWcAppContext();
    const issuesQueryKey = queryKeyFn({
        path: "/issues",
        operationId: "getIssues",
        variables: {},
    });

    const addUserIssue = useUserIssueReportsStore(
        (state) => state.addUserIssue
    );

    const reduceRemainingRequests = reportIssueLimit(
        (state) => state.reduceRemainingRequests
    );

    const submit = async () => {
        // convert raw image files
        const issueImages = await convertFilesToIssueImages(images);

        const issue: IssueRequestBody = {
            toiletObjectNumber: form.values.toiletObjectNumber,
            sapEqNumber: form.values.toiletSapEqNumber,
            reportOptionId: form.values.reportOptionId,
            description: getIssueDescription(
                form.values.description,
                form.values.email
            ),
            images: issueImages,
        };
        sendIssue({ body: issue })
            .then((createdIssue: Issue) => {
                if (!form.values.email) {
                    reduceRemainingRequests();
                }

                addUserIssue(createdIssue);

                const currentCacheState =
                    queryClient.getQueryState(issuesQueryKey);

                // add new issue to cached data, for instant display to user
                queryClient.setQueryData(
                    issuesQueryKey,
                    (cachedIssues) => {
                        const hasCache = Array.isArray(cachedIssues);

                        if (hasCache) {
                            return [createdIssue, ...cachedIssues];
                        }

                        return [createdIssue];
                    },
                    {
                        // GET /issues might not immediately include new issue -> cache remains valid, cache time (based on previous dataUpdatedAt) unchanged
                        updatedAt: currentCacheState?.dataUpdatedAt,
                    }
                );

                setIssueId(createdIssue.id);
                runOnSuccessfulSubmit();
            })
            .catch(() => notifications.show(SEND_ISSUE_ERROR_NOTIFICATION));
    };

    return (
        <BottomSheetFooter>
            <Stack spacing={8}>
                <Tooltip
                    label={t(
                        "issueReport.general.additionalNotes.requiredText"
                    )}
                    color="black"
                    withArrow
                    offset={-12}
                    position="bottom"
                    events={{
                        hover: true,
                        focus: false,
                        touch: true,
                    }}
                    disabled={isDescriptionIssueTextAreaInputValid}
                >
                    <Box component="div">
                        <Button
                            w="100%"
                            loading={isIssueSending}
                            disabled={!isDescriptionIssueTextAreaInputValid}
                            onClick={submit}
                        >
                            {t("issueReport.general.btn.submitBtn")}
                        </Button>
                    </Box>
                </Tooltip>

                <Button
                    onClick={goBack}
                    variant="outline"
                    disabled={isIssueSending}
                >
                    {t("issueReport.general.btn.back")}
                </Button>

                <Button
                    onClick={abort}
                    variant="outline"
                    disabled={isIssueSending}
                >
                    {t("issueReport.general.btn.abort")}
                </Button>
            </Stack>
        </BottomSheetFooter>
    );
}

export default Step5Footer;
