import React from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../components/Pages/DefaultPage";
import UserReports from "../components/UserReports/UserReports";

function UserReportsPage() {
    const { t } = useTranslation();
    return (
        <DefaultPage title={t("navbar.bookmarkedIssues")}>
            <UserReports />
        </DefaultPage>
    );
}

export default UserReportsPage;
