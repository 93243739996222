import { Stack, Text } from "@mantine/core";
import React, { ReactElement } from "react";
import {
    generateHumanReadableDuration,
    generateHumanReadableDistance,
} from "../../utilities/client/distance";
import useHumanReadableDistanceStyles from "./HumanReadableDistance.styles";

type HumanReadableDistanceProps = {
    distance: number;
    duration?: number | null;
};

function HumanReadableDistance({
    distance,
    duration = null,
}: HumanReadableDistanceProps): ReactElement {
    const { classes } = useHumanReadableDistanceStyles();
    return (
        <Stack className={classes.container} spacing={0} align="center">
            <Text className={classes.duration}>
                {generateHumanReadableDuration(duration)}
            </Text>
            <Text className={classes.distance}>
                {generateHumanReadableDistance(distance)}
            </Text>
        </Stack>
    );
}

export default HumanReadableDistance;
