import { create } from "zustand";
import { Coordinates, PropertyToilet } from "../utilities/api/wcAppSchemas";
import { Facility } from "../utilities/client/facility";

export type FacilityStore = {
    selectedFacility: Facility | null;
    selectedFacilityCollapsed: boolean;
    selectedFacilityPathCoordinates: Coordinates;
    propertyToilets: PropertyToilet[];

    setSelectedFacilityCollapsed: (isCollapsed: boolean) => void;
    setSelectedFacility: (facility: Facility | null) => void;
    setPropertyToilets: (propertyToilets: PropertyToilet[] | []) => void;
    setSelectedFacilityPathCoordinates: (
        facilityPathCoordinates: Coordinates
    ) => void;
};
const useFacilityStore = create<FacilityStore>((set) => ({
    //----------
    // states
    //----------
    selectedFacility: null,
    selectedFacilityPathCoordinates: [],
    selectedFacilityCollapsed: true,
    propertyToilets: [],

    //----------
    // actions
    //----------

    setSelectedFacilityCollapsed: (isCollapsed: boolean) => {
        return set(() => ({
            selectedFacilityCollapsed: isCollapsed,
        }));
    },

    setSelectedFacility: (facility: Facility | null) => {
        return set(() => ({
            selectedFacility: facility,
            selectedFacilityCollapsed: !facility,
        }));
    },

    setSelectedFacilityPathCoordinates: (
        facilityPathCoordinates: Coordinates
    ) => {
        return set(() => ({
            selectedFacilityPathCoordinates: facilityPathCoordinates,
        }));
    },

    setPropertyToilets: (propertyToilets: PropertyToilet[] | []) => {
        return set(() => ({
            propertyToilets,
        }));
    },
}));

export default useFacilityStore;
