/**
 * This approach is necessary because Leaflet renders static HTML.
 * For this we use the renderTostring method with the side effect that all hooks there do not take effect.
 */

import React, { ReactElement } from "react";

export default function StaticClusterMarker({
    selectedFacility,
    childCount,
    color,
    transparency,
}: {
    selectedFacility: boolean;
    childCount: number;
    color: string;
    transparency: number;
}): ReactElement {
    const style: React.CSSProperties = {
        alignItems: "center",
        background: `radial-gradient(
                circle,
                ${color} 64%,
                rgba(0,0,0,1) 100%
            )`,
        borderRadius: "50%",
        color: "white",
        boxShadow: "1px 1px 1px grey",
        display: "flex",
        fontWeight: 800,
        height: "35px",
        justifyContent: "center",
        width: "35px",
        opacity: selectedFacility ? transparency : 1,
    };

    return (
        <div style={style}>
            <span>{childCount}</span>
        </div>
    );
}
