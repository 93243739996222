import React, { ReactElement } from "react";
import {
    Collapse,
    Group,
    Divider,
    Stack,
    ScrollArea,
    Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import useIssueListStyles from "./IssueList.styles";
import {
    IconChevronDownLine as IconChevronDown,
    IconChevronUpLine as IconChevronUp,
} from "../Icons/Icons";
import IssueListEntry from "./IssueListEntry/IssueListEntry";
import { Issue } from "../../utilities/api/wcAppSchemas";

type IssueListProps = {
    issues: Issue[];
    isOpenInitially?: boolean;
    isCollapsible?: boolean;
    showDetails?: boolean;
    highlightIds?: string[];
    maxHeight?: string | number;
};

const CHEVRON_ICON_SIZE = 28;

function IssueList({
    issues,
    isOpenInitially = true,
    isCollapsible = true,
    showDetails = false,
    highlightIds = [],
    maxHeight = "300px",
}: IssueListProps): ReactElement {
    const { t } = useTranslation();
    const [isOpen, { toggle }] = useDisclosure(isOpenInitially);

    const collapseIcon = isOpen ? (
        <IconChevronUp size={CHEVRON_ICON_SIZE} />
    ) : (
        <IconChevronDown size={CHEVRON_ICON_SIZE} />
    );

    const { classes } = useIssueListStyles();

    return (
        <Stack className={classes.rootSpacing} spacing="xs">
            <Group
                onClick={isCollapsible ? toggle : () => null}
                position="apart"
            >
                <Title order={4}>
                    {t("issueList.reportedIssues", {
                        count: issues.length,
                    })}
                </Title>
                {isCollapsible && collapseIcon}
            </Group>

            <Divider size="xs" />

            <Collapse in={isOpen}>
                <ScrollArea.Autosize mah={maxHeight} type="auto" pb="16px">
                    <Stack className={classes.entrySpacing} spacing="xs">
                        {issues.map((issue) => (
                            <IssueListEntry
                                key={issue.id}
                                issueEntry={issue}
                                showState={showDetails}
                                showNewsIndicator={highlightIds.includes(
                                    issue.id
                                )}
                            />
                        ))}
                    </Stack>
                </ScrollArea.Autosize>
            </Collapse>
        </Stack>
    );
}

export default IssueList;
