import { useColorScheme } from "@mantine/hooks";
import { create } from "zustand";
import { persist } from "zustand/middleware";

enum DisplayModeSettings {
    dark = "dark",
    light = "light",
    system = "system",
}

export type DisplayModeStore = {
    displayModeSystem: ReturnType<typeof useColorScheme>;
    displayModeSetting: DisplayModeSettings;
    displayMode: ReturnType<typeof useColorScheme>;
    setDisplayModeSystem: (
        displayModeSystem: ReturnType<typeof useColorScheme>
    ) => void;
    setDisplayModeSettings: (displayModeSetting: DisplayModeSettings) => void;
    setDisplayMode: () => void;
};

const useDisplayModeStore = create<DisplayModeStore>()(
    persist(
        (set, get) => ({
            displayModeSystem: DisplayModeSettings.light,
            displayModeSetting: DisplayModeSettings.light,
            displayMode: DisplayModeSettings.light,
            setDisplayModeSystem: (displayModeSystem) => {
                set({ displayModeSystem });
                get().setDisplayMode();
            },
            setDisplayModeSettings: (displayModeSetting) => {
                set({ displayModeSetting });
                get().setDisplayMode();
            },

            setDisplayMode: () => {
                const { displayModeSetting, displayModeSystem } = get();
                if (displayModeSetting === DisplayModeSettings.system) {
                    set({ displayMode: displayModeSystem });
                } else {
                    set({ displayMode: displayModeSetting });
                }
            },
        }),
        { name: "displayMode" }
    )
);

export { useDisplayModeStore, DisplayModeSettings };
