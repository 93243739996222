import React, { ReactElement } from "react";
import { Stack, Text } from "@mantine/core";

import useActionButtonStyles from "./ActionButton.styles";

const ICON_CONTAINER_SIZE = 48;

function ActionButton({
    icon,
    text,
    onClick,
}: {
    text: string[];
    icon: ReactElement;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}): ReactElement {
    const { classes } = useActionButtonStyles();
    return (
        <Stack
            justify="center"
            align="center"
            spacing={0}
            className={classes.actionButtonWrapper}
            onClick={onClick}
        >
            {icon}

            <Stack
                justify="center"
                align="center"
                spacing={0}
                h={ICON_CONTAINER_SIZE}
            >
                {text.map((textLine) => (
                    <Text
                        className={classes.actionButtonText}
                        key={textLine}
                        weight="bold"
                        align="center"
                        transform="uppercase"
                    >
                        {textLine}
                    </Text>
                ))}
            </Stack>
        </Stack>
    );
}

export default ActionButton;
