import React from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../components/Pages/DefaultPage";
import UserProfile from "../components/UserProfile/UserProfile";

function UserProfilePage() {
    const { t } = useTranslation();
    return (
        <DefaultPage title={t("pages.userProfile.header")}>
            <UserProfile />
        </DefaultPage>
    );
}

export default UserProfilePage;
