import { createStyles } from "@mantine/core";

const useHumanReadableDistanceStyles = createStyles((theme) => {
    return {
        container: {
            minWidth: "90px",
        },
        duration: {
            fontSize: theme.fontSizes.xl,
            lineHeight: "110%",
            fontWeight: "bolder",
            minHeight: `calc(${theme.fontSizes.xl}*1.1)`,

            [`@media (min-width: ${theme.breakpoints.xs})`]: {
                fontSize: `calc(${theme.fontSizes.xl} + 2px)`,
                minHeight: `calc((${theme.fontSizes.xl} + 2px)*1.1)`,
            },
        },
        distance: {
            fontSize: theme.fontSizes.md,
            lineHeight: "110%",

            [`@media (min-width: ${theme.breakpoints.xs})`]: {
                fontSize: theme.fontSizes.xl,
            },
        },
    };
});

export default useHumanReadableDistanceStyles;
