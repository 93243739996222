import { createStyles } from "@mantine/core";

const useActionButtonStyles = createStyles((theme) => {
    return {
        actionButtonWrapper: {
            "&:hover": {
                color: "#1227be",
            },
        },
        actionButtonImage: {
            margin: 4,
        },
        actionButtonText: {
            fontSize: theme.fontSizes.md,
            lineHeight: "120%",
        },
    };
});

export default useActionButtonStyles;
