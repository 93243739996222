import React, { ReactElement } from "react";
import { Text, Button, Stack, Group } from "@mantine/core";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import BottomSheetHeader from "../../../../../BottomSheetHeader/BottomSheetHeader";
import BottomSheetFooter from "../../../../../BottomSheetFooter/BottomSheetFooter";
import reportFinished from "../../../../../../assets/animations/finished.json";

type Step2Props = {
    onNewIssue: () => void;
    onClose: () => void;
};

function Step2({ onNewIssue, onClose }: Step2Props): ReactElement {
    const { t } = useTranslation();

    return (
        <>
            <BottomSheetHeader
                title={t("issueReport.general.stepTitle.acknowledgementTitle")}
            />

            <Group spacing={0} position="center" mt="md">
                <Lottie
                    style={{ maxWidth: 100, margin: "-12px" }}
                    animationData={reportFinished}
                    loop={false}
                />
                <Text weight="bold">{t("issueReport.acknowledgement")}</Text>
            </Group>

            <BottomSheetFooter>
                <Stack spacing={8}>
                    <Button onClick={onClose}>
                        {t("issueReport.general.btn.done")}
                    </Button>
                    <Button onClick={onNewIssue} variant="outline">
                        {t("issueReport.general.btn.newIssueBtn")}
                    </Button>
                </Stack>
            </BottomSheetFooter>
        </>
    );
}

export default Step2;
