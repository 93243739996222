import { createStyles } from "@mantine/core";

const useIntroDialogStyles = createStyles((theme) => ({
    root: { maxWidth: "260px", margin: "0 auto", marginBottom: "16px" },
    body: {
        justifyContent: "center",
    },
    header: {
        justifyContent: "center",
    },
    title: {
        alignContent: "center",
        fontWeight: "bold",
        fontSize: theme.fontSizes.lg,
    },
    separatorActive: {
        border: `solid 2px ${theme.colors.bvg[6]}`,
        backgroundColor: theme.colors.bvg[6],
        borderRadius: "6px",
    },

    stepIcon: {
        margin: "auto",
        borderColor: "transparent",
        borderWidth: "3px",

        "&[data-completed]": {
            backgroundColor: "white",
            borderColor: theme.colors.bvg[6],
            backgroundImage: theme.fn.linearGradient(
                45,
                theme.colors.bvg[6],
                theme.colors.bvg[6]
            ),
        },

        "&[data-progress]": {
            borderColor: theme.colors.bvg[6],
        },
    },
    step: {
        display: "block",
    },
    stepBody: {
        marginLeft: "0px",
        marginTop: "8px",
    },
}));

export default useIntroDialogStyles;
