import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { de } from "../locales/de";
import { en } from "../locales/en";

export const resources = {
    de: { translation: de },
    en: { translation: en },
};

export const { t } = i18n;

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "de",
        interpolation: {
            escapeValue: false,
        },
        resources,
    });

export default i18n;
