import React, { ReactElement } from "react";
import { Text, Button, Stack, Group } from "@mantine/core";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { ReportForm, stepIndexHandlerType } from "../GeneralReport";
import BottomSheetHeader from "../../../../../BottomSheetHeader/BottomSheetHeader";
import BottomSheetFooter from "../../../../../BottomSheetFooter/BottomSheetFooter";
import reportFinished from "../../../../../../assets/animations/finished.json";
import useGetToiletIssues from "../../../../../../utilities/client/hooks/useGetToiletIssues";
import IssueList from "../../../../../IssueList/IssueList";

type Step6Props = {
    form: ReportForm;
    issueId: string | null;
    stepIndexHandler: stepIndexHandlerType;
    closeBottomSheet: () => void;
};

function Step6({
    form,
    issueId,
    stepIndexHandler,
    closeBottomSheet,
}: Step6Props): ReactElement {
    const { t } = useTranslation();
    const close = () => {
        stepIndexHandler.reset();
        form.reset();

        closeBottomSheet();
    };

    const { data: openIssues } = useGetToiletIssues(
        form.values.toiletSapEqNumber
    );

    return (
        <>
            <BottomSheetHeader
                title={t("issueReport.general.stepTitle.acknowledgementTitle")}
            />

            <Group spacing={0} position="center" mt="md">
                <Lottie
                    style={{ maxWidth: 100, margin: "-12px" }}
                    animationData={reportFinished}
                    loop={false}
                />
                <Text weight="bold">{t("issueReport.acknowledgement")}</Text>
            </Group>

            <IssueList
                issues={openIssues}
                isOpenInitially
                highlightIds={issueId === null ? [] : [issueId]}
            />

            <BottomSheetFooter>
                <Stack spacing={8}>
                    <Button onClick={close}>
                        {t("issueReport.general.btn.done")}
                    </Button>
                    <Button
                        onClick={() => {
                            form.reset();
                            stepIndexHandler.set(1);
                        }}
                        variant="outline"
                    >
                        {t("issueReport.general.btn.newIssueBtn")}
                    </Button>
                </Stack>
            </BottomSheetFooter>
        </>
    );
}

export default Step6;
