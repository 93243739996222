import {
    Button,
    Stack,
    Container,
    Text,
    Image,
    useMantineTheme,
} from "@mantine/core";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "@mantine/carousel";
import Lottie from "lottie-react";
import wcMarkerAnimation from "../../../../assets/animations/wc-marker.json";
import useLocationPermissionIntroDialogStyles from "./LocationPermissionIntroDialog.styles";
import useIntroductionStore from "../../../../stores/introduction.store";
import useGeolocationWatcher from "../../../../utilities/client/hooks/useGeolocationWatcher";
import useGeolocationStore from "../../../../stores/geolocation.store";

function ShowPermissionDialog(): null {
    useGeolocationWatcher();
    return null;
}

function LocationPermissionIntroDialog(): ReactElement | null {
    const { classes } = useLocationPermissionIntroDialogStyles();
    const theme = useMantineTheme();
    const { t } = useTranslation();

    const [showLocationPermission, setShowLocationPermission] = useState(false);

    const toggleLocationPermissionDone = useIntroductionStore(
        (state) => state.toggleLocationPermissionDone
    );
    const setLocationGranted = useIntroductionStore(
        (state) => state.setLocationGranted
    );
    const locationGranted = useIntroductionStore(
        (state) => state.locationGranted
    );

    const permissionState = useGeolocationStore(
        (state) => state.permissionState
    );

    return (
        <>
            <Container fluid={false} className={classes.container}>
                <Stack style={{ height: "100%" }}>
                    <Text
                        size="md"
                        className={classes.descriptionText}
                        span
                        inline
                    >
                        {t("permissionInfoDialog.description")}
                    </Text>
                    <Carousel
                        className={classes.carousel}
                        align="center"
                        controlSize={30}
                        styles={{
                            viewport: {
                                height: "100%",
                            },
                            container: {
                                height: "100%",
                            },
                            control: {
                                backgroundColor: theme.colors.gray[1],
                                "&[data-inactive]": {
                                    opacity: 0,
                                    cursor: "default",
                                },
                            },
                        }}
                    >
                        <Carousel.Slide>
                            <Stack
                                className={classes.carouselElement}
                                justify="center"
                            >
                                <Lottie
                                    style={{ maxWidth: 250 }}
                                    animationData={wcMarkerAnimation}
                                    loop={false}
                                />
                                <Text
                                    size="md"
                                    weight={700}
                                    align="left"
                                    className={classes.imageText}
                                >
                                    {t("permissionInfoDialog.explanation1")}
                                </Text>
                            </Stack>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Stack
                                className={classes.carouselElement}
                                justify="center"
                            >
                                <Image
                                    radius="md"
                                    height={240}
                                    src="/images/toilet-list.png"
                                    fit="contain"
                                />
                                <Text
                                    size="md"
                                    weight={700}
                                    align="left"
                                    className={classes.imageText}
                                >
                                    {t("permissionInfoDialog.explanation2")}
                                </Text>
                            </Stack>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Stack
                                className={classes.carouselElement}
                                justify="center"
                            >
                                <Image
                                    height={260}
                                    radius="md"
                                    src="/images/navigation.png"
                                    fit="contain"
                                />
                                <Text
                                    size="md"
                                    weight={700}
                                    align="left"
                                    className={classes.imageText}
                                >
                                    {t("permissionInfoDialog.explanation3")}
                                </Text>
                            </Stack>
                        </Carousel.Slide>
                    </Carousel>
                    {permissionState === "prompt" && !locationGranted && (
                        <>
                            <Button
                                onClick={() => {
                                    setShowLocationPermission(true);
                                    setLocationGranted(true);
                                }}
                            >
                                {t("geolocationPermissionDialog.grantGps")}
                            </Button>
                            <Button
                                onClick={() => {
                                    toggleLocationPermissionDone();
                                    setLocationGranted(false);
                                }}
                                variant="outline"
                            >
                                {t("geolocationPermissionDialog.ignore")}
                            </Button>
                        </>
                    )}
                    {(permissionState === "granted" ||
                        permissionState === "denied" ||
                        locationGranted) && (
                        <Button
                            onClick={() => {
                                toggleLocationPermissionDone();
                            }}
                        >
                            {t("geolocationPermissionDialog.confirm")}
                        </Button>
                    )}
                </Stack>
            </Container>
            {showLocationPermission && <ShowPermissionDialog />}
        </>
    );
}

export default LocationPermissionIntroDialog;
