import { createStyles } from "@mantine/core";

const useNavbarLinkListStyles = createStyles(() => ({
    stack: {
        boxSizing: "border-box",
        overflow: "auto",
        padding: "1rem",
        transition: "opacity 0.3s ease-in-out",
        width: "100%",
        height: "100%",
    },
}));

export default useNavbarLinkListStyles;
