import { createStyles } from "@mantine/core";

type useSvgIconStylesParams = {
    size:
        | number
        | {
              height: number;
              width: number;
          };
};

const useSvgIconStyles = createStyles((_, { size }: useSvgIconStylesParams) => {
    return {
        icon: {
            height: typeof size === "number" ? size : size.height,
            width: typeof size === "number" ? size : size.width,
        },
    };
});

export default useSvgIconStyles;
