import React, { ReactElement, useState, useEffect } from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import VectorTileLayer from "react-leaflet-vector-tile-layer";

import { BrowserView, isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useMantineTheme } from "@mantine/core";
import CurrentPositionMarker from "../CurrentPositionMarker/CurrentPositionMarker";
import ClusterMarker from "../ClusterMarker/ClusterMarker";
import { COORDINATES_UNTER_DEN_LINDEN } from "../../utilities/client/map";
import MapEventHandler from "./MapEventHandler/MapEventHandler";
import MapNavigator from "./MapNavigator/MapNavigator";
import MapRoutePath from "../MapRoutePath/MapRoutePath";
import useApplicationStore from "../../stores/application.store";
import MapControls from "../MapControls/MapControls";
import useMapStore from "../../stores/map.store";
import useFacilityStore from "../../stores/facility.store";
import { useDisplayModeStore } from "../../stores/displayModeSettings.store";
import useGetAllFacilities from "../../utilities/client/hooks/useGetAllFacilities";
import useMapTilesHealthCheck from "../../utilities/client/hooks/useMapTilesHealthCheck";

function FacilityMap(): ReactElement {
    useMapTilesHealthCheck();

    const isInNavigationMode = useApplicationStore(
        (state) => state.isInNavigationMode
    );
    const setSelectedFacility = useFacilityStore(
        (state) => state.setSelectedFacility
    );
    const { data: allFacilities } = useGetAllFacilities();

    const DEFAULT_ZOOM = isMobile ? 11 : 11.9;

    const MIN_ZOOM_LEVEL = (isTablet && 10) || (isMobile && 9) || 10;
    const maxBoundsMargin = (isTablet && 0.05) || (isMobile && 0.1) || 0.05;

    // Let the map respond to resizing
    const mapContainerRef = useMapStore((state) => state.mapContainerRef);
    const setMapContainerRef = useMapStore((state) => state.setMapContainerRef);
    const [resizeObserverActive, setResizeObserverActive] = useState(false);
    const { t } = useTranslation();
    const displayMode = useDisplayModeStore((state) => state.displayMode);
    const [theme, setTheme] = useState(displayMode);
    const mantineTheme = useMantineTheme();

    const { PUBLIC_URL } = process.env;

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (mapContainerRef) {
                mapContainerRef.invalidateSize();
            }
        });
        if (mapContainerRef) {
            mapContainerRef.invalidateSize();
            if (!resizeObserverActive && mapContainerRef) {
                resizeObserver.observe(mapContainerRef.getContainer());
                setResizeObserverActive(true);
            }
        }
    }, [mapContainerRef, resizeObserverActive]);

    // set map bounds dynamically
    useEffect(() => {
        if (allFacilities?.length) {
            const latitudes = allFacilities.map(
                ({ geolocation }) => geolocation.coordinates[1]
            );
            const longitudes = allFacilities.map(
                ({ geolocation }) => geolocation.coordinates[0]
            );

            const minCoordinates = {
                lat: Math.min.apply(null, latitudes),
                lng: Math.min.apply(null, longitudes),
            };
            const maxCoordinates = {
                lat: Math.max.apply(null, latitudes),
                lng: Math.max.apply(null, longitudes),
            };

            mapContainerRef?.setMaxBounds([
                [
                    minCoordinates.lat - maxBoundsMargin,
                    minCoordinates.lng - maxBoundsMargin * 1.5,
                ],
                [
                    maxCoordinates.lat + maxBoundsMargin,
                    maxCoordinates.lng + maxBoundsMargin * 1.5,
                ],
            ]);
        } else {
            mapContainerRef?.setMaxBounds([
                [52.275225292581155, 12.970765686035154],
                [52.755176920882604, 13.830910170078278],
            ]);
        }
    }, [allFacilities, mapContainerRef, maxBoundsMargin]);

    /**
     * destroy hook
     */
    useEffect(() => {
        return () => {
            setMapContainerRef(null);
            setSelectedFacility?.(null);
        };
    }, [setMapContainerRef, setSelectedFacility]);

    useEffect(() => {
        if (displayMode !== theme) {
            setTheme(displayMode);
        }
    }, [displayMode, theme]);

    let navigationPathLineColor = null;
    if (isInNavigationMode) {
        navigationPathLineColor =
            displayMode === "dark" ? mantineTheme.colors.blue[3] : "#1D3DB1";
    } else {
        navigationPathLineColor =
            displayMode === "dark"
                ? mantineTheme.colors.bvg[1]
                : mantineTheme.colors.bvg[7];
    }
    return (
        <div style={{ height: "100%" }}>
            <MapContainer
                dragging
                center={COORDINATES_UNTER_DEN_LINDEN}
                zoom={DEFAULT_ZOOM}
                zoomControl={false}
                zoomSnap={0.1}
                minZoom={MIN_ZOOM_LEVEL}
                bounceAtZoomLimits={false}
                style={{
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                }}
                ref={setMapContainerRef}
            >
                <MapEventHandler />
                <MapNavigator />
                <MapControls />

                <BrowserView>
                    <ZoomControl position="topright" />
                </BrowserView>

                <TileLayer
                    attribution={t("map.attribution")}
                    url="https://{s}-tiles.bvg.de/data/positron/{z}/{x}/{y}.pbf"
                    minZoom={10}
                    maxZoom={20}
                />
                <VectorTileLayer
                    styleUrl={
                        displayMode === "light"
                            ? `${PUBLIC_URL}/mapStyles/bright-style.json`
                            : `${PUBLIC_URL}/mapStyles/dark-style.json`
                    }
                    key={displayMode}
                />
                <ClusterMarker />
                <CurrentPositionMarker />
                <MapRoutePath color={navigationPathLineColor} dashed />
            </MapContainer>
        </div>
    );
}

export default FacilityMap;
