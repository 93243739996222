import { createStyles } from "@mantine/core";

type FacilityListStyleParams = {
    locationPermission: boolean;
    isViewWidthXS: boolean;
};

const useFacilityListStyles = createStyles(
    (theme, { locationPermission, isViewWidthXS }: FacilityListStyleParams) => {
        return {
            locationDescription: {
                fontSize: theme.fontSizes.xs,
                lineHeight: "110%",
                width: "100%",
                fontWeight: "bold",
            },
            title: {
                fontSize: isViewWidthXS
                    ? theme.fontSizes.md
                    : theme.fontSizes.xl,
                lineHeight: "110%",
                fontWeight: "bold",
                textAlign: "left",
                width: "100%",
            },
            description: {
                fontSize: isViewWidthXS
                    ? theme.fontSizes.sm
                    : theme.fontSizes.md,
                lineHeight: "110%",
                color:
                    theme.colorScheme === "dark"
                        ? theme.colors.dark[2]
                        : theme.colors.gray[7],
                paddingTop: "2px",
            },
            detailsLink: {
                color:
                    theme.colorScheme === "dark"
                        ? theme.colors.bvg[7]
                        : theme.colors.blue[9],
                fontWeight: "bold",
                textDecoration: "underline",
            },
            objectNumber: {
                fontSize: theme.fontSizes.xs,
                fontWeight: 700,
                alignItems: "flex-end",
                whiteSpace: "nowrap",
            },
            distanceContainer: {
                gap: locationPermission ? "4px" : 0,
                margin: locationPermission ? "12px" : "0px",
                maxWidth: "265px",
            },
            distanceContainerCollapsed: {
                gap: locationPermission ? "4px" : 0,
                margin: 0,
                maxWidth: "265px",
            },
            informationContainer: {
                width: "100%",
            },
        };
    }
);

export default useFacilityListStyles;
