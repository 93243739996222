/**
 * This approach is necessary because Leaflet renders static HTML.
 * For this we use the renderTostring method with the side effect that all hooks there do not take effect.
 */

import React from "react";
import { ReactComponent as SvgBvgPropertySolid } from "../../../assets/ui-icons/bvg-property-solid.svg";
import { ReactComponent as SvgWallWcLine } from "../../../assets/ui-icons/wall-wc-line.svg";
import { ReactComponent as SvgWallWcSolid } from "../../../assets/ui-icons/wall-wc-solid.svg";
import { ReactComponent as SvgMapPinSolid } from "../../../assets/ui-icons/map-pin-solid.svg";

import {
    Facility,
    getFacilityMarkerIconSize,
    isBvgProperty,
    isToilet,
} from "../../../utilities/client/facility";

export default function StaticMapIcon({
    facility,
    color = "black",
    displayMode,
}: {
    facility: Facility;
    color?: string;
    displayMode: "dark" | "light";
}) {
    const iconSize = getFacilityMarkerIconSize(facility);
    const iconProps = {
        fill: color,
        strokeWidth: 1,
        height: iconSize,
        width: iconSize,
    };

    if (isBvgProperty(facility)) {
        return (
            <SvgBvgPropertySolid
                fill={iconProps.fill}
                strokeWidth={iconProps.strokeWidth}
                height={iconProps.height}
                width={iconProps.width}
            />
        );
    }
    if (isToilet(facility) && facility.toiletType === "WALL") {
        return displayMode === "dark" ? (
            <SvgWallWcLine
                fill={iconProps.fill}
                strokeWidth={iconProps.strokeWidth}
                height={iconProps.height}
                width={iconProps.width}
            />
        ) : (
            <SvgWallWcSolid
                fill="none"
                strokeWidth={iconProps.strokeWidth}
                height={iconProps.height}
                width={iconProps.width}
            />
        );
    }
    return (
        <SvgMapPinSolid
            fill={iconProps.fill}
            strokeWidth={iconProps.strokeWidth}
            height={iconProps.height}
            width={iconProps.width}
        />
    );
}
