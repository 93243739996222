import { createStyles } from "@mantine/core";

const useRemoveUserIssueStyles = createStyles((theme) => {
    return {
        text: {
            fontSize: theme.fontSizes.md,
            lineHeight: "120%",
        },
        info: {
            fontSize: theme.fontSizes.sm,
            lineHeight: "110%",
        },
        iconWrapper: {
            outline: "solid 2px",
            outlineOffset: "-6px",
            outlineColor: theme.colors.bvg[6],
            borderRadius: "12px",
        },
    };
});

export default useRemoveUserIssueStyles;
