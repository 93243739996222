/**
 * Generated by @openapi-codegen
 *
 * @version 1.0.0
 */
import * as reactQuery from "@tanstack/react-query";
import { useWcAppContext, WcAppContext } from "./wcAppContext";
import type * as Fetcher from "./wcAppFetcher";
import { wcAppFetch } from "./wcAppFetcher";
import type * as Schemas from "./wcAppSchemas";

export type GetHealthError = Fetcher.ErrorWrapper<{
    status: 401;
    payload: Schemas.AccessTokenError | Schemas.Error;
}>;

export type GetHealthVariables = WcAppContext["fetcherOptions"];

export const fetchGetHealth = (
    variables: GetHealthVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<Schemas.HealthCheck, GetHealthError, undefined, {}, {}, {}>({
        url: "/health",
        method: "get",
        ...variables,
        signal,
    });

export const useGetHealth = <TData = Schemas.HealthCheck>(
    variables: GetHealthVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.HealthCheck, GetHealthError, TData>,
        "queryKey" | "queryFn"
    >
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } =
        useWcAppContext(options);
    return reactQuery.useQuery<Schemas.HealthCheck, GetHealthError, TData>({
        queryKey: queryKeyFn({
            path: "/health",
            operationId: "getHealth",
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchGetHealth({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GetToiletsQueryParams = {
    /**
     * Filter by specific physical key id
     */
    keyIds?: ("N1/XN1" | "8hk8258")[];
};

export type GetToiletsError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: Schemas.ValidationError;
      }
    | {
          status: 401;
          payload: Schemas.AccessTokenError | Schemas.Error;
      }
>;

export type GetToiletsResponse = Schemas.Toilet[];

export type GetToiletsVariables = {
    queryParams?: GetToiletsQueryParams;
} & WcAppContext["fetcherOptions"];

/**
 * Fetch toilet data, optionally ordered based on the provided position.
 */
export const fetchGetToilets = (
    variables: GetToiletsVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<
        GetToiletsResponse,
        GetToiletsError,
        undefined,
        {},
        GetToiletsQueryParams,
        {}
    >({ url: "/toilets", method: "get", ...variables, signal });

/**
 * Fetch toilet data, optionally ordered based on the provided position.
 */
export const useGetToilets = <TData = GetToiletsResponse>(
    variables: GetToiletsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GetToiletsResponse, GetToiletsError, TData>,
        "queryKey" | "queryFn"
    >
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } =
        useWcAppContext(options);
    return reactQuery.useQuery<GetToiletsResponse, GetToiletsError, TData>({
        queryKey: queryKeyFn({
            path: "/toilets",
            operationId: "getToilets",
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchGetToilets({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GetPropertiesError = Fetcher.ErrorWrapper<{
    status: 401;
    payload: Schemas.AccessTokenError | Schemas.Error;
}>;

export type GetPropertiesResponse = Schemas.BvgProperty[];

export type GetPropertiesVariables = WcAppContext["fetcherOptions"];

export const fetchGetProperties = (
    variables: GetPropertiesVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<
        GetPropertiesResponse,
        GetPropertiesError,
        undefined,
        {},
        {},
        {}
    >({ url: "/properties", method: "get", ...variables, signal });

export const useGetProperties = <TData = GetPropertiesResponse>(
    variables: GetPropertiesVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            GetPropertiesResponse,
            GetPropertiesError,
            TData
        >,
        "queryKey" | "queryFn"
    >
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } =
        useWcAppContext(options);
    return reactQuery.useQuery<
        GetPropertiesResponse,
        GetPropertiesError,
        TData
    >({
        queryKey: queryKeyFn({
            path: "/properties",
            operationId: "getProperties",
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchGetProperties({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GetDirectionsError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: Schemas.Error;
      }
    | {
          status: 401;
          payload: Schemas.AccessTokenError | Schemas.Error;
      }
>;

export type GetDirectionsVariables = {
    body: Schemas.DirectionRequestBody;
} & WcAppContext["fetcherOptions"];

/**
 * Get directions to and from a location
 */
export const fetchGetDirections = (
    variables: GetDirectionsVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<
        Schemas.Direction,
        GetDirectionsError,
        Schemas.DirectionRequestBody,
        {},
        {},
        {}
    >({ url: "/directions", method: "post", ...variables, signal });

/**
 * Get directions to and from a location
 */
export const useGetDirections = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.Direction,
            GetDirectionsError,
            GetDirectionsVariables
        >,
        "mutationFn"
    >
) => {
    const { fetcherOptions } = useWcAppContext();
    return reactQuery.useMutation<
        Schemas.Direction,
        GetDirectionsError,
        GetDirectionsVariables
    >({
        mutationFn: (variables: GetDirectionsVariables) =>
            fetchGetDirections({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type CreateLogError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: Schemas.Error;
      }
    | {
          status: 401;
          payload: Schemas.AccessTokenError | Schemas.Error;
      }
    | {
          status: 404;
          payload: Schemas.Error;
      }
>;

export type CreateLogVariables = {
    body: Schemas.LogRequestBody;
} & WcAppContext["fetcherOptions"];

/**
 * Create a new log
 */
export const fetchCreateLog = (
    variables: CreateLogVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<undefined, CreateLogError, Schemas.LogRequestBody, {}, {}, {}>({
        url: "/logs",
        method: "post",
        ...variables,
        signal,
    });

/**
 * Create a new log
 */
export const useCreateLog = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            undefined,
            CreateLogError,
            CreateLogVariables
        >,
        "mutationFn"
    >
) => {
    const { fetcherOptions } = useWcAppContext();
    return reactQuery.useMutation<
        undefined,
        CreateLogError,
        CreateLogVariables
    >({
        mutationFn: (variables: CreateLogVariables) =>
            fetchCreateLog({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type GetIssuePathParams = {
    /**
     * @maxLength 12
     * @example 14342129
     */
    issueId: string;
};

export type GetIssueError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: Schemas.ValidationError;
      }
    | {
          status: 401;
          payload: Schemas.AccessTokenError | Schemas.Error;
      }
    | {
          status: 500;
          payload: Schemas.Error;
      }
    | {
          status: 501;
          payload: Schemas.Error;
      }
>;

export type GetIssueVariables = {
    pathParams: GetIssuePathParams;
} & WcAppContext["fetcherOptions"];

/**
 * Get issue by id
 */
export const fetchGetIssue = (
    variables: GetIssueVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<
        Schemas.Issue,
        GetIssueError,
        undefined,
        {},
        {},
        GetIssuePathParams
    >({ url: "/issues/{issueId}", method: "get", ...variables, signal });

/**
 * Get issue by id
 */
export const useGetIssue = <TData = Schemas.Issue>(
    variables: GetIssueVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Issue, GetIssueError, TData>,
        "queryKey" | "queryFn"
    >
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } =
        useWcAppContext(options);
    return reactQuery.useQuery<Schemas.Issue, GetIssueError, TData>({
        queryKey: queryKeyFn({
            path: "/issues/{issueId}",
            operationId: "getIssue",
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchGetIssue({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GetIssuesError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: Schemas.ValidationError;
      }
    | {
          status: 401;
          payload: Schemas.AccessTokenError | Schemas.Error;
      }
    | {
          status: 500;
          payload: Schemas.Error;
      }
    | {
          status: 501;
          payload: Schemas.Error;
      }
>;

export type GetIssuesResponse = Schemas.Issue[];

export type GetIssuesVariables = WcAppContext["fetcherOptions"];

/**
 * Get all open issues
 */
export const fetchGetIssues = (
    variables: GetIssuesVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<GetIssuesResponse, GetIssuesError, undefined, {}, {}, {}>({
        url: "/issues",
        method: "get",
        ...variables,
        signal,
    });

/**
 * Get all open issues
 */
export const useGetIssues = <TData = GetIssuesResponse>(
    variables: GetIssuesVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GetIssuesResponse, GetIssuesError, TData>,
        "queryKey" | "queryFn"
    >
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } =
        useWcAppContext(options);
    return reactQuery.useQuery<GetIssuesResponse, GetIssuesError, TData>({
        queryKey: queryKeyFn({
            path: "/issues",
            operationId: "getIssues",
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchGetIssues({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type CreateIssueError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: Schemas.ValidationError;
      }
    | {
          status: 401;
          payload: Schemas.AccessTokenError | Schemas.Error;
      }
    | {
          status: 422;
          payload: Schemas.Error;
      }
    | {
          status: 500;
          payload: Schemas.Error;
      }
    | {
          status: 501;
          payload: Schemas.Error;
      }
>;

export type CreateIssueVariables = {
    body: Schemas.IssueRequestBody;
} & WcAppContext["fetcherOptions"];

/**
 * Create a new issue
 */
export const fetchCreateIssue = (
    variables: CreateIssueVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<
        Schemas.Issue,
        CreateIssueError,
        Schemas.IssueRequestBody,
        {},
        {},
        {}
    >({ url: "/issues", method: "post", ...variables, signal });

/**
 * Create a new issue
 */
export const useCreateIssue = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.Issue,
            CreateIssueError,
            CreateIssueVariables
        >,
        "mutationFn"
    >
) => {
    const { fetcherOptions } = useWcAppContext();
    return reactQuery.useMutation<
        Schemas.Issue,
        CreateIssueError,
        CreateIssueVariables
    >({
        mutationFn: (variables: CreateIssueVariables) =>
            fetchCreateIssue({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type SendWallIssueError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: Schemas.ValidationError;
      }
    | {
          status: 401;
          payload: Schemas.AccessTokenError | Schemas.Error;
      }
    | {
          status: 500;
          payload: Schemas.Error;
      }
>;

export type SendWallIssueVariables = {
    body: Schemas.WallIssue;
} & WcAppContext["fetcherOptions"];

/**
 * Send a new wall issue
 */
export const fetchSendWallIssue = (
    variables: SendWallIssueVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<undefined, SendWallIssueError, Schemas.WallIssue, {}, {}, {}>({
        url: "/wall-issues",
        method: "post",
        ...variables,
        signal,
    });

/**
 * Send a new wall issue
 */
export const useSendWallIssue = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            undefined,
            SendWallIssueError,
            SendWallIssueVariables
        >,
        "mutationFn"
    >
) => {
    const { fetcherOptions } = useWcAppContext();
    return reactQuery.useMutation<
        undefined,
        SendWallIssueError,
        SendWallIssueVariables
    >({
        mutationFn: (variables: SendWallIssueVariables) =>
            fetchSendWallIssue({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type GetIssueReportOptionsError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: Schemas.ValidationError;
      }
    | {
          status: 401;
          payload: Schemas.AccessTokenError | Schemas.Error;
      }
    | {
          status: 500;
          payload: Schemas.Error;
      }
    | {
          status: 501;
          payload: Schemas.Error;
      }
>;

export type GetIssueReportOptionsResponse = Schemas.IssueReportOption[];

export type GetIssueReportOptionsVariables = WcAppContext["fetcherOptions"];

/**
 * Get allowed issue report categories and descriptions
 */
export const fetchGetIssueReportOptions = (
    variables: GetIssueReportOptionsVariables,
    signal?: AbortSignal
) =>
    wcAppFetch<
        GetIssueReportOptionsResponse,
        GetIssueReportOptionsError,
        undefined,
        {},
        {},
        {}
    >({ url: "/issue-report-options", method: "get", ...variables, signal });

/**
 * Get allowed issue report categories and descriptions
 */
export const useGetIssueReportOptions = <TData = GetIssueReportOptionsResponse>(
    variables: GetIssueReportOptionsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            GetIssueReportOptionsResponse,
            GetIssueReportOptionsError,
            TData
        >,
        "queryKey" | "queryFn"
    >
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } =
        useWcAppContext(options);
    return reactQuery.useQuery<
        GetIssueReportOptionsResponse,
        GetIssueReportOptionsError,
        TData
    >({
        queryKey: queryKeyFn({
            path: "/issue-report-options",
            operationId: "getIssueReportOptions",
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchGetIssueReportOptions(
                { ...fetcherOptions, ...variables },
                signal
            ),
        ...options,
        ...queryOptions,
    });
};

export type QueryOperation =
    | {
          path: "/health";
          operationId: "getHealth";
          variables: GetHealthVariables;
      }
    | {
          path: "/toilets";
          operationId: "getToilets";
          variables: GetToiletsVariables;
      }
    | {
          path: "/properties";
          operationId: "getProperties";
          variables: GetPropertiesVariables;
      }
    | {
          path: "/issues/{issueId}";
          operationId: "getIssue";
          variables: GetIssueVariables;
      }
    | {
          path: "/issues";
          operationId: "getIssues";
          variables: GetIssuesVariables;
      }
    | {
          path: "/issue-report-options";
          operationId: "getIssueReportOptions";
          variables: GetIssueReportOptionsVariables;
      };
