import { useMapEvents } from "react-leaflet";
import useApplicationStore from "../../../stores/application.store";
import useMapStore from "../../../stores/map.store";
import useFacilityStore from "../../../stores/facility.store";

function MapEventHandler(): null {
    const setSelectedFacility = useFacilityStore(
        (state) => state.setSelectedFacility
    );
    const setSelectedFacilityPathCoordinates = useFacilityStore(
        (state) => state.setSelectedFacilityPathCoordinates
    );
    const setHasMoved = useMapStore((state) => state.setHasMoved);
    const isInNavigationMode = useApplicationStore(
        (state) => state.isInNavigationMode
    );

    function mapHasMoved() {
        setHasMoved(true);
    }
    useMapEvents({
        click: () => {
            if (!isInNavigationMode) {
                setSelectedFacility?.(null);
                setSelectedFacilityPathCoordinates?.([]);
            }
        },
        zoomend() {
            mapHasMoved();
        },
        moveend() {
            mapHasMoved();
        },
    });
    return null;
}

export default MapEventHandler;
