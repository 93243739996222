import React from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../components/Pages/DefaultPage";

function TermsAndConditionsPage() {
    const { t } = useTranslation();
    const title = t("pages.termsAndConditions.header");

    return (
        <DefaultPage title={title}>
            <iframe
                title={title}
                src={t("links.termsAndConditions")}
                width="100%"
                height="100%"
                frameBorder="0"
            >
                Browser not compatible.
            </iframe>

            {/* <object
                title="termsAndConditions"
                data={t("links.termsAndConditions")}
                width="100%"
                height="100%"
                type="text/html"
            /> */}
        </DefaultPage>
    );
}

export default TermsAndConditionsPage;
