import React, { ReactElement } from "react";
import {
    Button,
    Container,
    Grid,
    Stack,
    Text,
    useMantineTheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import BottomSheetFooter from "../../BottomSheetFooter/BottomSheetFooter";
import BottomSheetHeader from "../../BottomSheetHeader/BottomSheetHeader";
import BottomSheet from "../../BottomSheet/BottomSheet";
import { IconAlertCircleLine } from "../../Icons/Icons";
import useUserIssueReportsStore from "../../../stores/userIssueReports.store";
import useRemoveUserIssueStyles from "./RemoveUserIssue.styles";

type RemoveUserIssueProps = {
    isOpen: boolean;
    userIssueId: string;
    onClose: () => void;
};

function RemoveUserIssue({
    isOpen,
    userIssueId,
    onClose,
}: RemoveUserIssueProps): ReactElement {
    const { t } = useTranslation();
    const { classes } = useRemoveUserIssueStyles();
    const theme = useMantineTheme();

    const removeUserIssue = useUserIssueReportsStore(
        (state) => state.removeUserIssue
    );

    return (
        <BottomSheet isOpen={isOpen} onClose={onClose} closeOnDrag={false}>
            <>
                <BottomSheetHeader title={t("issueList.removeIssue.title")} />

                <Container py="xl" fluid={false}>
                    <Text className={classes.text}>
                        {t("issueList.removeIssue.prompt")}
                    </Text>
                    <Stack px={8} mt={24}>
                        <Grid justify="center" align="center">
                            <Grid.Col
                                className={classes.iconWrapper}
                                span="content"
                            >
                                <IconAlertCircleLine
                                    color={theme.colors.bvg[8]}
                                    size={28}
                                />
                            </Grid.Col>
                            <Grid.Col span="auto">
                                <Text className={classes.info}>
                                    {t("issueList.removeIssue.info")}
                                </Text>
                            </Grid.Col>
                        </Grid>

                        <Grid justify="center" align="center">
                            <Grid.Col
                                className={classes.iconWrapper}
                                span="content"
                            >
                                <IconAlertCircleLine
                                    color={theme.colors.bvg[8]}
                                    size={28}
                                />
                            </Grid.Col>
                            <Grid.Col span="auto">
                                <Text className={classes.info}>
                                    {t("issueList.removeIssue.irreversibel")}
                                </Text>
                            </Grid.Col>
                        </Grid>
                    </Stack>
                </Container>

                <BottomSheetFooter>
                    <>
                        <Button onClick={() => removeUserIssue(userIssueId)}>
                            {t("issueList.removeIssue.confirm")}
                        </Button>
                        <Button variant="outline" onClick={onClose}>
                            {t("issueList.removeIssue.cancel")}
                        </Button>
                    </>
                </BottomSheetFooter>
            </>
        </BottomSheet>
    );
}

export default RemoveUserIssue;
