import {
    Container,
    Stack,
    Button,
    Image,
    Text,
    useMantineTheme,
    Center,
} from "@mantine/core";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import AccordionComponent from "./AccordionComponent/AccordionComponent";
import useGeolocationStore from "../../../stores/geolocation.store";
import useIntroductionStore from "../../../stores/introduction.store";
import useGeolocationPermissionStyles from "./GeolocationPermission.styles";
import useGeolocationWatcher from "../../../utilities/client/hooks/useGeolocationWatcher";
import BottomSheet from "../../BottomSheet/BottomSheet";
import BottomSheetHeader from "../../BottomSheetHeader/BottomSheetHeader";

function ShowPermissionDialog(): null {
    useGeolocationWatcher();
    return null;
}

function GeolocationPermission(): ReactElement {
    const { t } = useTranslation();
    const { classes } = useGeolocationPermissionStyles();
    const showMissingLocationAccessDialog = useGeolocationStore(
        (state) => state.showMissingLocationAccessDialog
    );
    const closeMissingLocationAccessDialog = useGeolocationStore(
        (state) => state.closeMissingLocationAccessDialog
    );
    const permissionState = useGeolocationStore(
        (state) => state.permissionState
    );

    const setLocationGranted = useIntroductionStore(
        (state) => state.setLocationGranted
    );
    const locationGranted = useIntroductionStore(
        (state) => state.locationGranted
    );

    const [showLocationPermission, setShowLocationPermission] = useState(false);
    const [isAccordionVisible, setIsAccordionVisible] = useState(false);
    const theme = useMantineTheme();
    const closeDialog = () => {
        setIsAccordionVisible(false);
        closeMissingLocationAccessDialog();
    };

    return (
        <>
            <BottomSheet
                isOpen={showMissingLocationAccessDialog}
                onClose={closeMissingLocationAccessDialog}
                closeOnBackdropClick={false}
                closeOnDrag={false}
                bringToFront
            >
                <>
                    <BottomSheetHeader
                        title={t("geolocationPermissionDialog.title")}
                    />
                    <Container py="xl" fluid={false}>
                        <Stack justify="flex-end">
                            <Center>
                                <Image
                                    src={
                                        theme.colorScheme === "dark"
                                            ? "/images/missing-location-access-dark.svg"
                                            : "/images/missing-location-access.svg"
                                    }
                                    alt={t(
                                        "geolocationPermissionDialog.imageAlt"
                                    )}
                                    maw={200}
                                />
                            </Center>
                            <Text size="md">
                                {t("geolocationPermissionDialog.description")}
                            </Text>
                            <Text
                                size="md"
                                underline
                                color={theme.colors.bvg[7]}
                                className={classes.link}
                                onClick={() =>
                                    setIsAccordionVisible(
                                        (previsAccordionVisibleValue) =>
                                            !previsAccordionVisibleValue
                                    )
                                }
                            >
                                {t("geolocationPermissionDialog.howTo")}
                            </Text>

                            {isAccordionVisible ? (
                                <div>
                                    <AccordionComponent />
                                </div>
                            ) : null}
                            {permissionState === "prompt" &&
                                !locationGranted && (
                                    <>
                                        <Button
                                            onClick={() => {
                                                setShowLocationPermission(true);
                                                setLocationGranted(true);
                                                closeDialog();
                                            }}
                                        >
                                            {t(
                                                "geolocationPermissionDialog.grantGps"
                                            )}
                                        </Button>
                                        <Button
                                            onClick={closeDialog}
                                            variant="outline"
                                        >
                                            {t(
                                                "geolocationPermissionDialog.ignore"
                                            )}
                                        </Button>
                                    </>
                                )}
                            {(permissionState === "granted" ||
                                permissionState === "denied" ||
                                locationGranted) && (
                                <Button onClick={closeDialog}>
                                    {t("geolocationPermissionDialog.confirm")}
                                </Button>
                            )}
                        </Stack>
                    </Container>
                </>
            </BottomSheet>
            {showLocationPermission && <ShowPermissionDialog />}
        </>
    );
}

export default GeolocationPermission;
