import { createStyles, keyframes } from "@mantine/core";

// Export animation to reuse it in other components
export const pulse = keyframes({
    "10%": { transform: "scale(1.1)" },
});

const useAppStyles = createStyles((theme) => {
    return {
        loadingWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor: theme.colors.bvg[6],
        },
        loadingIcon: {
            maxWidth: "350px",
            width: "80%",
            outline: "4px solid black",
            borderRadius: 24,
        },
    };
});

export default useAppStyles;
