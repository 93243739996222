import React, { ReactElement } from "react";
import { Stack, Text, useMantineTheme } from "@mantine/core";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import weAreSorryAnimation from "../../../assets/animations/we-are-sorry.json";

function NoFacilities(): ReactElement {
    const { t } = useTranslation();
    const theme = useMantineTheme();

    return (
        <Stack
            spacing="lg"
            justify="center"
            align="center"
            style={{
                height: "100%",
                padding: "24px",
                backgroundColor:
                    theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.colors.light[0],
            }}
        >
            <Text size="lg" weight="bold">
                {t("pages.noToiletsToShow.header")}
            </Text>
            <Lottie
                style={{ minWidth: 100, maxWidth: 600 }}
                animationData={weAreSorryAnimation}
            />
            <Text
                size="md"
                weight="normal"
                align="left"
                style={{ maxWidth: "340px" }}
            >
                {t("pages.noToiletsToShow.noDataAvailable")}
                <p />
                {t("pages.noToiletsToShow.reportIssue")}
                <p />
                {t("pages.noToiletsToShow.workInProgress")}
                <p />
                {t("pages.noToiletsToShow.revisitApp")}
            </Text>
        </Stack>
    );
}

export default NoFacilities;
