import React, {
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useVirtualizer, VirtualItem } from "@tanstack/react-virtual";
import { useTranslation } from "react-i18next";
import useFacilityListStyles from "./FacilityList.styles";
import FacilityListEntry from "../FacilityListEntry/FacilityListEntry";
import { useFacilitiesWithDistance } from "../../utilities/client/hooks/useFacilitiesWithDistance";
import useFacilityStore from "../../stores/facility.store";
import PropertyToiletsList from "../Pages/PropertyToiletsList/PropertyToiletsList";
import BottomSheet from "../BottomSheet/BottomSheet";
import {
    Facility,
    isBvgProperty,
    isToilet,
} from "../../utilities/client/facility";
import useGetToiletIssues from "../../utilities/client/hooks/useGetToiletIssues";
import { Issue } from "../../utilities/api/wcAppSchemas";

function FacilityList(): ReactElement {
    const { data: issues } = useGetToiletIssues();
    const { isLoading, facilitiesWithDistance } = useFacilitiesWithDistance();
    const [showPropertyToilets, setShowPropertyToilets] = useState(false);
    const { classes } = useFacilityListStyles();
    const { t } = useTranslation();

    const selectedFacility = useFacilityStore(
        (state) => state.selectedFacility
    );
    const propertyToilets = useFacilityStore((state) => state.propertyToilets);
    const setPropertyToilets = useFacilityStore(
        (state) => state.setPropertyToilets
    );

    const ref = useRef<HTMLDivElement>(null);
    const virtualizer = useVirtualizer({
        count: facilitiesWithDistance.length,
        getScrollElement: () => ref.current,
        estimateSize: () => 100,
        overscan: 5,
    });
    const onShowPropertyToiletsChanged = (facility: Facility) => {
        setPropertyToilets(isBvgProperty(facility) ? facility.toilets : []);
        setShowPropertyToilets(true);
    };

    const selectedFacilityId = selectedFacility?.id;
    const scrollToFacility = useCallback(() => {
        if (selectedFacilityId) {
            const scrollIndex = facilitiesWithDistance.findIndex(
                (facility) => facility.id === selectedFacilityId
            );
            if (scrollIndex > -1) {
                virtualizer.scrollToIndex(scrollIndex, { align: "start" });
            }
        }
    }, [selectedFacilityId, facilitiesWithDistance, virtualizer]);

    useEffect(() => {
        scrollToFacility();
    }, [scrollToFacility, selectedFacilityId]);

    if (isLoading) {
        return <div>Loading…</div>;
    }

    return (
        <div
            ref={ref}
            style={{ overflow: "auto", height: "100%", width: "100%" }}
        >
            <div
                style={{
                    height: `${virtualizer.getTotalSize()}px`,
                    position: "relative",
                    width: "100%",
                }}
            >
                {virtualizer.getVirtualItems().map((item: VirtualItem) => {
                    const facility = facilitiesWithDistance[item.index];
                    let toiletIssues: Issue[] = [];
                    if (isToilet(facility)) {
                        toiletIssues = issues.filter(
                            (issue) =>
                                issue.sapEqNumber === facility.sapEqNumber
                        );
                    }
                    return (
                        <div
                            className={classes.main}
                            key={item.key}
                            data-index={item.index}
                            ref={virtualizer.measureElement}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                transform: `translateY(${item.start}px)`,
                            }}
                        >
                            <FacilityListEntry
                                facility={facility}
                                hasIssues={toiletIssues.length > 0}
                                key={`${facility.id}`}
                                onSelect={scrollToFacility}
                                onShowPropertyToiletsChanged={() =>
                                    onShowPropertyToiletsChanged(facility)
                                }
                            />
                        </div>
                    );
                })}
                {!!propertyToilets.length && (
                    <BottomSheet
                        headerTitle={t("propertyToiletsListDialog.title")}
                        onClose={() =>
                            setShowPropertyToilets((prevState) => !prevState)
                        }
                        isOpen={showPropertyToilets}
                    >
                        <PropertyToiletsList
                            propertyToilets={propertyToilets}
                        />
                    </BottomSheet>
                )}
            </div>
        </div>
    );
}

export default FacilityList;
