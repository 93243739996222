import { useId } from "@mantine/hooks";
import React, { ReactElement } from "react";
import Draggable, {
    DraggableData,
    DraggableEventHandler,
} from "react-draggable";
import useSplitViewHandleStyles from "./SplitViewHandle.styles";

type SplitViewProps = {
    containerHeight: number;
    onMove: (data: DraggableData) => void;
    onMoveEnd: () => void;
    top: number;
};

function SplitViewHandle({
    containerHeight,
    onMove,
    onMoveEnd,
    top,
}: SplitViewProps): ReactElement {
    const nodeRef = React.useRef<HTMLDivElement>(null);
    const handleHeight = 30;
    const { classes } = useSplitViewHandleStyles({ handleHeight, top });
    const id = useId();

    const onDrag: DraggableEventHandler = (_e, data) => {
        onMove(data);
    };

    const onDragStop: DraggableEventHandler = () => {
        onMoveEnd();
    };

    return (
        <div className={classes.main} id={`wc-app-split-view-handle-${id}`}>
            <Draggable
                axis="y"
                bounds={{
                    top: -containerHeight / 2,
                    bottom: containerHeight / 2 - handleHeight,
                }}
                onDrag={onDrag}
                onStop={onDragStop}
                nodeRef={nodeRef}
            >
                <div className={classes.splitHandle} ref={nodeRef}>
                    <hr className={classes.splitHandleIndicator} />
                </div>
            </Draggable>
        </div>
    );
}

export default SplitViewHandle;
