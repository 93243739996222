import { createStyles } from "@mantine/core";

const useDefaultPageStyles = createStyles((theme) => ({
    main: {
        backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : "white",
    },
    header: {
        maxHeight: "inherit !important",
        height: "auto !important",
        paddingTop: "calc(10px + env(safe-area-inset-top))!important",
        fontWeight: "bold",
        fontSize: theme.fontSizes.lg,
    },
    grid: {
        height: "100%",
    },
    text: {
        color:
            theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors.dark[9],
    },
    icon: {
        fill:
            theme.colorScheme === "dark"
                ? theme.colors.dark[0]
                : theme.colors.dark[9],
    },
}));

export default useDefaultPageStyles;
