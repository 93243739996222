import { Text } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../components/Pages/DefaultPage";

function ImprintPage() {
    const { t } = useTranslation();
    return (
        <DefaultPage title={t("pages.imPrint.header")}>
            <Text>imprint</Text>
        </DefaultPage>
    );
}

export default ImprintPage;
